/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment, type ReactNode } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';

import { hideOnMobileNavigationStyles, Logo } from '@af/design-system-docs-ui';
import {
	AtlassianNavigation,
	PrimaryButton,
	ProductHome,
	useOverflowStatus,
} from '@atlaskit/atlassian-navigation';
import { AtlassianIcon } from '@atlaskit/logo';
import { LinkItem } from '@atlaskit/menu';

import { TOP_NAV_ITEMS } from '../utils/constants';

import MobileHeader from './mobile-header';
import SearchButton from './search/search-button';
import Settings from './settings';
import type { SideNavigationItem } from './side-navigation';

const productHomePadding = 4;
const logoSvgWidth = 269;
const logoMaxWidth = productHomePadding * 2 + logoSvgWidth;

interface NavItemProps {
	href: string;
	pathname?: string;
	children: ReactNode;
}

const handleClickHome = (e: React.MouseEvent<HTMLElement>) => {
	e.preventDefault();
	navigate('/');
};

const NavItem = ({ href, pathname = '', children }: NavItemProps) => {
	const { isVisible } = useOverflowStatus();
	return isVisible ? (
		<PrimaryButton
			isHighlighted={pathname.startsWith(href)}
			component={Link}
			// @ts-expect-error
			to={href}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={hideOnMobileNavigationStyles}>{children}</div>
		</PrimaryButton>
	) : (
		<LinkItem href={href} isSelected={pathname.startsWith(href)}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={hideOnMobileNavigationStyles}>{children}</div>
		</LinkItem>
	);
};

interface TopNavProps {
	navigation: SideNavigationItem[];
	onSearchOpen(): void;
}

/**
 * __Top nav__
 */
const TopNav = ({ navigation, onSearchOpen }: TopNavProps) => {
	const { pathname } = useLocation();

	return (
		<Fragment>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={hideOnMobileNavigationStyles}>
				<AtlassianNavigation
					label="site navigation"
					renderProductHome={() => (
						<ProductHome
							icon={() => (
								// This never actually gets used because of mobile-header
								<AtlassianIcon
									label="Atlassian Design System Home"
									appearance="brand"
									size="small"
								/>
							)}
							href="/"
							onClick={handleClickHome}
							logo={() => <Logo />}
							logoMaxWidth={logoMaxWidth}
						/>
					)}
					renderSearch={() => <SearchButton onClick={onSearchOpen} />}
					renderSettings={() => <Settings />}
					primaryItems={TOP_NAV_ITEMS.map(({ title, slug }) => (
						<NavItem pathname={pathname} href={`/${slug}`}>
							{title}
						</NavItem>
					))}
				/>
			</div>
			<MobileHeader navigation={navigation} onSearchOpen={onSearchOpen} />
		</Fragment>
	);
};

export default TopNav;
