import anchor1Do from "../../../../../../packages/design-system/primitives/constellation/anchor/images/anchor-01a-do.png";
import anchor1Dont from "../../../../../../packages/design-system/primitives/constellation/anchor/images/anchor-01b-dont.png";
import anchor2Do from "../../../../../../packages/design-system/primitives/constellation/anchor/images/anchor-02a-do.png";
import anchor2Dont from "../../../../../../packages/design-system/primitives/constellation/anchor/images/anchor-02b-dont.png";
import * as React from 'react';
export default {
  anchor1Do,
  anchor1Dont,
  anchor2Do,
  anchor2Dont,
  React
};