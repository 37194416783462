import IconDefaultNew from "../../../../../../packages/design-system/icon/examples/constellation/icon-default-new.tsx";
import IconUtility from "../../../../../../packages/design-system/icon/examples/constellation/icon-utility.tsx";
import IconColor from "../../../../../../packages/design-system/icon/examples/constellation/icon-color.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_default_new_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-default-new.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_utility_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-utility.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_color_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-color.tsx";
import * as React from 'react';
export default {
  IconDefaultNew,
  IconUtility,
  IconColor,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_default_new_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_utility_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_color_tsx,
  React
};