import React from 'react';

import Toggle from '../../src';

export default function Example() {
	return (
		<>
			<Toggle id="toggle-default" label="Allow pull request" />
		</>
	);
}
