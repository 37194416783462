import { StatusProps } from '@af/design-system-docs-ui';

export const statusMapper = (
	contentNode: Pick<Queries.ContentfulGuideline, 'status' | 'readyForPreview'>,
) => {
	const isDraft = Boolean(contentNode.readyForPreview);
	const status = contentNode.status
		? ({ type: contentNode.status } as StatusProps)
		: isDraft
			? ({ type: 'alpha' } as const)
			: undefined;

	return status;
};
