import {
	dark as darkTheme,
	light as lightTheme,
	shape as shapeTheme,
	spacing as spacingTheme,
	typographyAdg3 as typographyAdg3Theme,
	typography as typographyModernizedTheme,
} from '@atlaskit/tokens/tokens-raw';

import isProd from '../../utils/is-prod';

import type { TransformedTokenMerged, TransformedTokenWithAttributes } from './types';

const lightDarkTokens = lightTheme.map((lightToken) => {
	const darkToken = darkTheme.find((token) => token.name === lightToken.name);
	const mergedToken = {
		...lightToken,
		darkToken: (darkToken as TransformedTokenWithAttributes)!,
	} as TransformedTokenMerged;

	return mergedToken;
});

const typographyGroups = ['typography', 'fontWeight', 'fontFamily'];

const typographyAdg3ThemeFiltered = typographyAdg3Theme
	.filter((token) => typographyGroups.includes(token.attributes.group))
	.filter((token) => token.cleanName !== 'font.body.UNSAFE_small');

// Temporary while we dislpay both legacy and modernized values. Remove once legacy theme is no longer relevant.
const typographyLegacyModernizedTokens = typographyAdg3ThemeFiltered.map((legacyToken) => {
	const modernizedToken = typographyModernizedTheme.find(
		(token) => token.name === legacyToken.name,
	);
	const mergedToken = {
		...legacyToken,
		modernizedToken: modernizedToken as TransformedTokenWithAttributes,
	} as TransformedTokenMerged;

	return mergedToken;
});

const mergedTokens: TransformedTokenMerged[] = lightDarkTokens.concat(
	// @ts-expect-error this is annoying and related to the tokens defintions
	...spacingTheme,
	...typographyLegacyModernizedTokens,
	...(!isProd() ? shapeTheme : []),
);

export default mergedTokens;
