import FlagGroup from "../../../../../../packages/design-system/flag/examples/constellation/flag-group";
import FlagGroupInDrawer from "../../../../../../packages/design-system/flag/examples/constellation/flag-group-in-drawer";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_group from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flag-group";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_group_in_drawer from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flag-group-in-drawer";
import * as React from 'react';
export default {
  FlagGroup,
  FlagGroupInDrawer,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_group,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_group_in_drawer,
  React
};