import illustrationDoImage from "../../../../../../packages/design-system/empty-state/constellation/index/images/illustration-do.png";
import illustrationDontImage from "../../../../../../packages/design-system/empty-state/constellation/index/images/illustration-dont.png";
import ctaDoImage from "../../../../../../packages/design-system/empty-state/constellation/index/images/cta-do.png";
import ctaDontImage from "../../../../../../packages/design-system/empty-state/constellation/index/images/cta-dont.png";
import * as React from 'react';
export default {
  illustrationDoImage,
  illustrationDontImage,
  ctaDoImage,
  ctaDontImage,
  React
};