import React, { memo } from 'react';

import { type LinkGetProps, useLocation } from '@reach/router';
import kebabCase from 'lodash/kebabCase';

import { type StatusPanelProps } from '@af/design-system-docs-ui';
import { Box, Stack, xcss } from '@atlaskit/primitives';

import SideNavigationContext from './context';
import NavigationItem from './navigation-item';
import ScrollContainer from './scroll-container';

export interface SideNavigationItemData {
	title: string;
	slug?: string | null;
	type?: 'heading';
	to?: string;
	isSelected?: boolean;
	status?: Pick<StatusPanelProps, 'actions' | 'description' | 'type'>;
	isPartiallyActive?: (props: LinkGetProps) => any;
	parent?: string | undefined | null;
	nestingLevel?: number;
	hasChildren?: boolean;
	isOpen?: boolean;
	packagePageTabs?: string[];
}

export type SideNavigationItem = {
	item: SideNavigationItemData;
	subitems: SideNavigationItem[];
};

export type SideNavigationProps = {
	id?: string;
	testId?: string;
	navigation: SideNavigationItem[];
	device: 'desktop' | 'mobile';
};

const listStyles = xcss({
	margin: 'space.0',
	padding: 'space.0',
	listStyle: 'none',
});

/**
 * __Side navigation__
 *
 * A side navigation for use on both desktop and mobile.
 */
const SideNavigation = memo(({ id, navigation, device, testId }: SideNavigationProps) => {
	const { pathname } = useLocation();

	// Only display current section on desktop navigation
	// Top level navigation is provided by the top header navigation instead
	const filteredNavigation =
		device === 'desktop'
			? navigation.filter(({ item: { to } }) => to && pathname.startsWith(to))
			: navigation;

	return (
		<SideNavigationContext.Provider value={{ device }}>
			<ScrollContainer device={device}>
				<Box as="nav" id={id} aria-label="sidebar navigation" testId={testId}>
					<Stack as="ul" xcss={listStyles}>
						{filteredNavigation.map(({ item, subitems }) => {
							const itemTestId = testId ? `${testId}-${kebabCase(item.title)}` : undefined;
							return (
								<NavigationItem
									key={item.to || item.title}
									item={item}
									subitems={subitems}
									nestingLevel={0}
									testId={itemTestId}
									isParentOpen
								/>
							);
						})}
					</Stack>
				</Box>
			</ScrollContainer>
		</SideNavigationContext.Provider>
	);
});

export default SideNavigation;
