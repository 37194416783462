/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment, useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { type UIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button, { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import DecisionIcon from '@atlaskit/icon/glyph/decision';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { fireTrackAnalytics } from '@atlassian/analytics-bridge';

import { paramsToObject } from '../utils';

import TokenWizardModalBody from './components/modal-body';

const ModalHeaderStyles = css({
	display: 'flex',
	justifyContent: 'space-between',
});

/**
 * __Token wizard modal__
 *
 * A token wizard modal on the tokens reference list page.
 *
 */
const TokenWizardModal = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [params, setParams] = useState({});
	const openModal = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			setIsOpen(true);
			fireTrackAnalytics(analyticsEvent, 'button clicked', 'openTokenPicker', {
				source: 'token picker button',
			});
		},
		[],
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		try {
			const queryString = window.location.hash
				? window?.location?.hash?.split('?')[1]
				: window?.location?.search;
			const urlParams = new URLSearchParams(queryString);
			const entries = urlParams.entries();
			const paramInURL = paramsToObject(entries);
			setParams(paramInURL);
			if (!!paramInURL.isTokenPickerOpen) {
				setIsOpen(true);
				const analyticsEvent = createAnalyticsEvent({});
				fireTrackAnalytics(analyticsEvent, 'button clicked', 'openTokenPicker', {
					source: 'url',
				});
			}
		} catch {
			// eslint-disable-next-line no-console
			console.error('Invalid query parameters in URL');
		}
	}, [createAnalyticsEvent]);

	const removeParamsInUrl = useCallback(() => {
		window?.history?.pushState(
			{},
			document?.title,
			`${window?.location.pathname}${
				window?.location.hash ? window?.location.hash.split('?')[0] : ''
			}`,
		);
	}, []);

	const closeModal = () => {
		if (Object.keys(params).length) {
			setParams({});
			removeParamsInUrl();
		}
		setIsOpen(false);
	};

	return (
		<Fragment>
			<Button onClick={openModal} iconBefore={DecisionIcon}>
				Token picker
			</Button>

			<ModalTransition>
				{isOpen && (
					<Modal autoFocus={false} onClose={closeModal} width="x-large" height={640}>
						{/* @ts-ignore */}
						<ModalHeader css={ModalHeaderStyles}>
							<ModalTitle>Token picker</ModalTitle>
							<IconButton
								appearance="subtle"
								icon={CrossIcon}
								onClick={closeModal}
								label="Close modal"
							/>
						</ModalHeader>
						<ModalBody>
							<TokenWizardModalBody params={params} removeParamsInUrl={removeParamsInUrl} />
						</ModalBody>
					</Modal>
				)}
			</ModalTransition>
		</Fragment>
	);
};

export default TokenWizardModal;
