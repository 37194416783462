import React from 'react';

import type { WrapPageElementBrowserArgs } from 'gatsby';

import Layout from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/layout';
import { PageContext } from '../components/page-context';

/**
 * By default Gatsby will remount the entire page,
 * which resets the scroll position of the side navigation.
 *
 * The only way to have persistent elements
 * is to render them in `wrapPageElement` (or `wrapRootElement`).
 *
 * By rendering `<Layout />` in `wrapPageElement` (instead of in the page template)
 * our side nav does not remount on route change.
 *
 * Because it doesn’t remount, the side nav will saves its scroll position.
 */
export const wrapPageElement = ({ props, element }: WrapPageElementBrowserArgs) => {
	// @ts-ignore - Gatsby object type is unknown

	return (
		<PageContext.Provider value={props}>
			<Layout
				// @ts-ignore - Gatsby object type is unknown
				hasNoSidebar={props.pageContext?.frontmatter?.hasNoSidebar}
			>
				{element}
			</Layout>
		</PageContext.Provider>
	);
};
