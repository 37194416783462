import React from 'react';

import { token } from '@atlaskit/tokens';

const AvatarComponents = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<rect fill={token('color.icon.accent.green')} height="24" rx="3" width="24" />
		<path
			d="m14.684 14.26c-.588.3-1.236.492-2.04.492-1.752 0-2.784-1.068-2.784-2.676s.996-2.712 2.736-2.712c.876 0 1.5.204 2.088.564v-1.368c-.576-.396-1.368-.564-2.184-.564-2.592 0-4.092 1.656-4.092 4.08 0 2.484 1.5 4.044 4.104 4.044.864 0 1.668-.156 2.172-.492z"
			fill={token('color.icon.inverse')}
		/>
	</svg>
);

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default AvatarComponents;
