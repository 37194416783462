import pageHeaderContainerDo from "../../../../../../packages/design-system/page-header/constellation/index/images/page-header-do-1.png";
import pageHeaderContainerDont from "../../../../../../packages/design-system/page-header/constellation/index/images/page-header-dont-1.png";
import pageHeaderCountDo from "../../../../../../packages/design-system/page-header/constellation/index/images/page-header-do-2.png";
import pageHeaderCountDont from "../../../../../../packages/design-system/page-header/constellation/index/images/page-header-dont-2.png";
import * as React from 'react';
export default {
  pageHeaderContainerDo,
  pageHeaderContainerDont,
  pageHeaderCountDo,
  pageHeaderCountDont,
  React
};