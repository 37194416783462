import TransparentProgressBarExample from "../../../../../../packages/design-system/progress-bar/examples/constellation/transparent-progress-bar";
import TransparentProgressBarIndeterminateExample from "../../../../../../packages/design-system/progress-bar/examples/constellation/transparent-progress-bar-indeterminate";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtransparent_progress_bar from "!!raw-loader!../../../../../../packages/design-system/progress-bar/examples/constellation/transparent-progress-bar";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtransparent_progress_bar_indeterminate from "!!raw-loader!../../../../../../packages/design-system/progress-bar/examples/constellation/transparent-progress-bar-indeterminate";
import * as React from 'react';
export default {
  TransparentProgressBarExample,
  TransparentProgressBarIndeterminateExample,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtransparent_progress_bar,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtransparent_progress_bar_indeterminate,
  React
};