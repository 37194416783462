import buttonPrimaryDo from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-primary-do.png";
import buttonPrimaryDont from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-primary-dont.png";
import buttonDisableDo from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-disable-do.png";
import buttonDisableDont from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-disable-dont.png";
import buttonConciseDo from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-concise-do.png";
import buttonConciseDont from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-concise-dont.png";
import buttonCapitalizationDo from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-capitalization-do.png";
import buttonCapitalizationDont from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-capitalization-dont.png";
import buttonActionDo from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-action-do.png";
import buttonActionDont from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-action-dont.png";
import buttonLabelDo from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-label-do.png";
import buttonLabelDont from "../../../../../../packages/design-system/button/constellation/button-legacy/images/button-label-dont.png";
import * as React from 'react';
export default {
  buttonPrimaryDo,
  buttonPrimaryDont,
  buttonDisableDo,
  buttonDisableDont,
  buttonConciseDo,
  buttonConciseDont,
  buttonCapitalizationDo,
  buttonCapitalizationDont,
  buttonActionDo,
  buttonActionDont,
  buttonLabelDo,
  buttonLabelDont,
  React
};