/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { type UIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fireTrackAnalytics, fireUIAnalytics } from '@atlassian/analytics-bridge';

import questions from '../data/questions';
import results from '../data/results';
import { type Answer, type Path, type questionID, type resultID } from '../data/types';

import AnswerCard from './answer';
import CurrentQuestion from './current-question';
import ResultPanel from './result-panel';
import Summary from './summary';

const BREAKPOINT = 912;

const gridStyles = css({
	display: 'grid',
	columnGap: token('space.200', '16px'),
	gridTemplateColumns: '1fr',
	paddingBlockEnd: token('space.400', '32px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`@media (min-width: ${BREAKPOINT}px)`]: {
		height: 'calc(100% - 32px)',
		gridTemplateColumns: '1fr 1fr',
	},
});

const leftContainerStyles = css({
	display: 'flex',
	padding: token('space.200', '16px'),
	gap: token('space.100'),
	flexDirection: 'column',
	border: `1px solid ${token('color.border')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`@media (min-width: ${BREAKPOINT}px)`]: {
		height: 492,
		overflow: 'scroll',
	},
});

/**
 * __Token wizard modal body__
 *
 * Modal body of the token wizard modal dialog.
 *
 */
const TokenWizardModalBody = ({
	params,
	removeParamsInUrl,
}: {
	params: Record<string, resultID | boolean | string>;
	removeParamsInUrl: () => void;
}) => {
	const [currentQuestionId, setCurrentQuestionId] = useState<questionID>('root');
	const [path, setPath] = useState<[Path] | []>([]);
	const [resultId, setResultId] = useState<resultID | ''>('');
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (Object.keys(params).length) {
			try {
				const questionIdList = Object.keys(params).filter(
					(param) => param !== 'resultId' && param !== 'isTokenPickerOpen',
				);
				if (
					questionIdList.some(
						(questionId: string) =>
							!questions.hasOwnProperty(questionId) ||
							//@ts-ignore
							!questions[questionId]?.answers
								.map((answer: Answer) => answer.summary)
								.includes(params[questionId]),
					) ||
					!Object.keys(params).includes('resultId')
				) {
					// eslint-disable-next-line no-console
					console.error('Invalid query parameters in URL');
					return;
				}

				const pathInSearchParam = questionIdList.map((questionId) => ({
					questionId,
					//@ts-ignore
					question: questions[questionId].summary,
					answer: params[questionId],
				}));
				setResultId(params.resultId as resultID);
				setPath(pathInSearchParam as [Path]);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error('Invalid query parameters in URL');
			}
		}
	}, [params]);

	const resetQueryParams = useCallback(() => {
		if (Object.keys(params).length) {
			removeParamsInUrl();
		}
	}, [params, removeParamsInUrl]);

	const onClickSummary = useCallback((questionId: questionID) => {
		setCurrentQuestionId(questionId);
		setResultId('');
	}, []);

	const onClickStartAgain = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			resetQueryParams();
			setCurrentQuestionId('root');
			setPath([]);
			setResultId('');
			fireTrackAnalytics(analyticsEvent, 'button clicked', 'openTokenPicker', {
				source: 'start again button',
			});
		},
		[resetQueryParams],
	);

	const onClickAnswerCard = useCallback(
		(questionId: questionID, answer: Answer) => {
			const selectedIndex = path.findIndex((qs) => qs.questionId === questionId);
			if (selectedIndex >= 0) {
				const newPath = path
					//@ts-ignore
					.map((qs, index) => {
						if (index <= selectedIndex) {
							return qs.questionId === questionId
								? {
										questionId: questionId,
										question: questions[questionId].summary,
										answer: answer.summary,
									}
								: qs;
						}
					})
					.filter((item: any) => item);
				setPath(newPath as [Path]);
			} else {
				setPath([
					...path,
					{
						questionId,
						question: questions[questionId].summary,
						answer: answer.summary,
					},
				] as [Path]);
			}

			if (answer.result) {
				resetQueryParams();
				setResultId(answer.result);
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'button',
					action: 'clicked',
				});

				const resultString = results[answer.result].map((token) => token.name).join(',');
				fireUIAnalytics(analyticsEvent, 'button clicked', 'clicksTokenPickerAnswerButton', {
					path: answer.result.split('_')[0],
					result: resultString,
				});
			} else {
				setCurrentQuestionId(answer.next);
			}
		},
		[createAnalyticsEvent, path, resetQueryParams],
	);

	const renderPathList = (path: [Path] | []) => {
		const currentIndex = path.findIndex((qs) => qs.questionId === currentQuestionId);
		//@ts-ignore
		return path.map((questionAndAnswer, index) => {
			return questionAndAnswer.questionId === currentQuestionId && !resultId ? (
				<CurrentQuestion key={currentQuestionId} questionId={currentQuestionId} />
			) : (
				<Summary
					key={questionAndAnswer.questionId}
					isPending={currentIndex >= 0 && index > currentIndex}
					onClick={() => onClickSummary(questionAndAnswer.questionId)}
					questionAndAnswerSummary={questionAndAnswer}
				/>
			);
		});
	};

	const renderAnswers = (questionId: questionID) => {
		return (
			<div css={leftContainerStyles}>
				<Text>Select an answer</Text>
				{questions[questionId].answers.map((answer: Answer) => (
					<AnswerCard
						key={answer.summary}
						path={path}
						answer={answer}
						onClick={() => onClickAnswerCard(questionId, answer)}
					/>
				))}
			</div>
		);
	};

	return (
		<div css={gridStyles}>
			<Box>
				{renderPathList(path)}
				{(path.length === 0 || !path.find((qs) => qs.questionId === currentQuestionId)) && (
					<CurrentQuestion questionId={currentQuestionId} />
				)}
			</Box>
			{resultId ? (
				<ResultPanel resultId={resultId} onClickStartAgain={onClickStartAgain} path={path} />
			) : (
				renderAnswers(currentQuestionId)
			)}
		</div>
	);
};

export default TokenWizardModalBody;
