import React from 'react';

import Heading from '@atlaskit/heading';
import { Pressable, Stack, Text, xcss } from '@atlaskit/primitives';

import { type Answer, type Path } from '../data/types';

const baseAnswerStyles = xcss({
	width: '100%',
	paddingBlock: 'space.100',
	paddingInline: 'space.150',
	backgroundColor: 'color.background.neutral.subtle',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.200',
	color: 'color.text',
	textAlign: 'start',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const selectedStyles = xcss({
	backgroundColor: 'color.background.selected',
	borderColor: 'color.border.selected',
	color: 'color.text.selected',
	':hover': {
		backgroundColor: 'color.background.selected.hovered',
	},
	':active': {
		backgroundColor: 'color.background.selected.pressed',
	},
});

/**
 * __Answer card__
 *
 * An answer card on the left hand side of the token wizard modal
 *
 */
const AnswerCard = ({
	answer,
	onClick,
	path,
}: {
	answer: Answer;
	onClick: (...args: any) => void;
	path: [Path] | [];
}) => {
	const selected = !!path.find((qs) => qs.answer === answer.summary);
	return (
		<Pressable
			xcss={[baseAnswerStyles, selected && selectedStyles]}
			key={answer.summary}
			onClick={onClick}
		>
			<Stack space="space.050">
				<Heading size="small" as="h5">
					{answer.summary}
				</Heading>
				{answer.description && <Text as="p">{answer.description}</Text>}
			</Stack>
		</Pressable>
	);
};

export default AnswerCard;
