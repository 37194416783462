import relatedDo from "../../../../../../packages/design-system/button/constellation/split-button/images/related-do.png";
import relatedDont from "../../../../../../packages/design-system/button/constellation/split-button/images/related-dont.png";
import repeatDo from "../../../../../../packages/design-system/button/constellation/split-button/images/repeat-do.png";
import repeatDont from "../../../../../../packages/design-system/button/constellation/split-button/images/repeat-dont.png";
import lengthDo from "../../../../../../packages/design-system/button/constellation/split-button/images/length-do.png";
import lengthDont from "../../../../../../packages/design-system/button/constellation/split-button/images/length-dont.png";
import labelDo from "../../../../../../packages/design-system/button/constellation/split-button/images/label-do.png";
import disabledDont from "../../../../../../packages/design-system/button/constellation/split-button/images/disabled-dont.png";
import * as React from 'react';
export default {
  relatedDo,
  relatedDont,
  repeatDo,
  repeatDont,
  lengthDo,
  lengthDont,
  labelDo,
  disabledDont,
  React
};