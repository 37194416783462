import React, { type SyntheticEvent, useCallback, useEffect, useState } from 'react';

import SearchIcon from '@atlaskit/icon/core/search';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { Show, UNSAFE_useMediaQuery } from '@atlaskit/primitives/responsive';
import Textfield from '@atlaskit/textfield';

import {
	filterIcons,
	getCoreIcons,
	getIconLabCoreIcons,
	getUtilityIcons,
	globalCoreIconImportPrefix,
	globalUtilityIconImportPrefix,
	iconLabCoreIconImportPrefix,
} from '../helpers';
import { type NewIconData, type NewIconsList } from '../types';

import GridSection from './grid-section';
import IconDetails from './icon-details';

const IconDetailsBoxStyles = xcss({
	position: 'sticky',
	insetBlockStart: '64px',
});

const modalIconDetailsStyles = xcss({
	width: '100%',
	borderRadius: '4px',
	overflow: 'auto',
});

type IconExplorerProps = {
	testId?: string;
};

const SearchIconBefore = () => (
	<Box paddingInlineStart="space.100">
		<SearchIcon label="Search" color="currentColor" />
	</Box>
);

export function IconExplorer({ testId }: IconExplorerProps) {
	const [selectedIcon, setSelectedIcon] = useState<NewIconData>();
	const [allIcons, setAllIcons] = useState<{
		core: void | NewIconsList;
		utility: void | NewIconsList;
		iconLab: void | NewIconsList;
	}>();
	const [icons, setIcons] = useState<{
		core: NewIconData[];
		utility: NewIconData[];
		iconLab: NewIconData[];
	}>();
	const [searchQuery, setSearchQuery] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);

	const isBelowSm = UNSAFE_useMediaQuery('below.sm');

	useEffect(() => {
		const coreIconsPromise = getCoreIcons();
		const utilityIconsPromise = getUtilityIcons();
		const iconLabCoreIconsPromise = getIconLabCoreIcons();

		Promise.all([coreIconsPromise, utilityIconsPromise, iconLabCoreIconsPromise]).then(
			([coreIcons, utilityIcons, iconLabIcons]) => {
				setAllIcons({ core: coreIcons, utility: utilityIcons, iconLab: iconLabIcons });
			},
		);
	}, []);

	useEffect(() => {
		if (allIcons?.core && allIcons?.utility && allIcons?.iconLab) {
			const coreIcons = filterIcons(allIcons.core, searchQuery);
			const utilityIcons = filterIcons(allIcons.utility, searchQuery);
			const iconLabIcons = filterIcons(allIcons.iconLab, searchQuery);

			setIcons({
				core: coreIcons as NewIconData[],
				utility: utilityIcons as NewIconData[],
				iconLab: iconLabIcons as NewIconData[],
			});
		}
	}, [searchQuery, allIcons]);

	const updateSearchQuery = useCallback(
		(query: string) => {
			setSearchQuery(query);
		},
		[setSearchQuery],
	);

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedIcon(undefined);
	};

	const updateSelectedIcon = useCallback(
		(icon: NewIconData) => {
			setSelectedIcon((oldIcon) => (oldIcon?.packageName !== icon.packageName ? icon : undefined));
			isBelowSm?.matches && setIsModalOpen(true);
		},
		[setSelectedIcon, setIsModalOpen, isBelowSm?.matches],
	);

	if (!icons) {
		return null;
	}

	return (
		<Inline space="space.200" testId={testId}>
			<Stack space="space.400" grow="fill">
				<Textfield
					value={searchQuery}
					placeholder="Search"
					elemBeforeInput={<SearchIconBefore />}
					onChange={(evt: SyntheticEvent<HTMLInputElement>) =>
						updateSearchQuery(evt.currentTarget.value)
					}
				/>
				<>
					{icons.core.length > 0 && (
						<GridSection
							isLegacy={false}
							importPath={globalCoreIconImportPrefix}
							heading="Core"
							icons={icons?.core}
							onIconClick={updateSelectedIcon}
							selectedIcon={isModalOpen ? selectedIcon?.packageName : undefined}
						/>
					)}

					{icons.utility.length > 0 && (
						<GridSection
							isLegacy={false}
							importPath={globalUtilityIconImportPrefix}
							heading="Utility"
							icons={icons?.utility}
							onIconClick={updateSelectedIcon}
							selectedIcon={isModalOpen ? selectedIcon?.packageName : undefined}
						/>
					)}
					{icons.iconLab.length > 0 && (
						<GridSection
							isLegacy={false}
							importPath={iconLabCoreIconImportPrefix}
							heading="Icon Lab"
							icons={icons?.iconLab}
							onIconClick={updateSelectedIcon}
							selectedIcon={selectedIcon?.packageName}
						/>
					)}
				</>
			</Stack>
			<ModalTransition>
				{isModalOpen && selectedIcon && (
					<Modal onClose={closeModal}>
						<Box xcss={modalIconDetailsStyles}>
							<IconDetails icon={selectedIcon} isModal onClose={closeModal} />
						</Box>
					</Modal>
				)}
			</ModalTransition>
			<Show above="sm">
				<Box xcss={IconDetailsBoxStyles}>
					<IconDetails icon={selectedIcon} />
				</Box>
			</Show>
		</Inline>
	);
}
