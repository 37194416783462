import { useLocation } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';
import { CATEGORY_TITLES } from '../../../utils/constants';
import { useSiteMetadata } from '../utilities/use-site-metadata';

export type MetaTagsProps = {
	title?: string;
	description?: string;
	tabName?: string;
	categorySlug?: string;
	guidelineCategory?: string;
	parentPageTitle?: string;
	subpageTabName?: string;
	/**
	 * Use for the base MetaTags implementation. All
	 * metatags will be added using fallback values where
	 * values are not provided. Without this option enabled,
	 * MetaTags will act as standard override behavior using
	 * 'react-helmet'.
	 */
	isGlobal?: boolean;
};

const MetaTags = ({
	title,
	parentPageTitle,
	description,
	tabName,
	categorySlug,
	guidelineCategory,
	subpageTabName,
	isGlobal,
}: MetaTagsProps) => {
	const { pathname } = useLocation();
	const { siteName, siteUrl, description: siteDefaultDescription, metaImage } = useSiteMetadata();

	let pageTitle;

	// guideline page for COMPONENT
	// e.g. Button - Examples - Components - Atlassian Design System
	if (guidelineCategory === CATEGORY_TITLES.COMPONENTS && !categorySlug && !subpageTabName) {
		pageTitle = `${title} - ${tabName} - ${CATEGORY_TITLES.COMPONENTS} - ${siteName}`;
	} else if (!categorySlug && subpageTabName) {
		// guideline page for SUB-COMPONENT
		// e.g. Button Group - Examples - Components - Atlassian Design System
		pageTitle = `${title} - ${subpageTabName} - ${CATEGORY_TITLES.COMPONENTS} - ${siteName}`;
	} else if (guidelineCategory && parentPageTitle) {
		// guideline page for |Brand|Foundations|Content|Patterns|Resources| that IS a subpage
		// e.g. Messaging guidelines - Error messages - Content - Atlassian Design System
		pageTitle = `${parentPageTitle} - ${title} - ${guidelineCategory} - ${siteName}`;
	} else if (categorySlug) {
		// overview page
		pageTitle = `Overview - ${categorySlug} - ${siteName}`;
	} else if (guidelineCategory && !subpageTabName) {
		// guideline page for |Brand|Foundations|Content|Patterns|Resources| that is NOT a subpage
		// e.g. Color palettes - Resources - Atlassian Design System
		pageTitle = `${title} - ${guidelineCategory} - ${siteName}`;
	} else if (title) {
		// pages with no category
		// e.g. License - Atlassian Design System
		pageTitle = `${title} - ${siteName}`;
	} else {
		// home page
		pageTitle = `${siteName}`;
	}

	const pageDescription = description ? `${description}` : `${siteDefaultDescription}`;
	const metaimageSrc = metaImage && metaImage.publicURL ? siteUrl + metaImage.publicURL : '';

	return (
		<>
			{isGlobal && (
				<Helmet>
					<html lang="en" />
					<meta property="og:type" content="website" />
					<meta property="twitter:card" content="summary_large_image" />
					<meta
						name="google-site-verification"
						content="FU9ZkoqotLLvSayuWsZITiRvaGcc53ZZM-GVy43LMY0"
					/>
					<meta property="og:site_name" content={siteName} />
					<meta property="og:url" content={`${siteUrl}${pathname.replace(/\/$/g, '')}`} />
					<meta property="og:image" content={metaimageSrc} />
				</Helmet>
			)}
			{(isGlobal || title) && (
				<Helmet>
					<title>{pageTitle}</title>
					<meta property="og:title" content={pageTitle} />
				</Helmet>
			)}
			{(isGlobal || description) && (
				<Helmet>
					<meta property="og:description" content={pageDescription} />
				</Helmet>
			)}
			{isGlobal && process.env.GATSBY_CONSTELLATION_ENVIRONMENT !== 'PROD' && (
				<Helmet>
					<meta name="robots" content="noindex" />
				</Helmet>
			)}
		</>
	);
};

export default MetaTags;
