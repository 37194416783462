/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import kebabCase from 'lodash/kebabCase';

import { ResizingHeight } from '@atlaskit/motion';
import { token } from '@atlaskit/tokens';

import NavigationItem from './navigation-item';

import type { SideNavigationItem } from './index';

const listStyles = css({
	// FIXME: Remove !important usage to override default list styles
	// in global-styles.tsx
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	margin: `${token('space.0', '0px')} !important`,
	padding: token('space.0', '0px'),
	listStyle: 'none',
});

const hiddenListStyles = css({
	height: 0,
});

const animationContainerStyles = css({
	overflow: 'hidden',
});

type SubNavigationProps = {
	// eslint-disable-next-line @repo/internal/react/consistent-props-definitions
	subitems: SideNavigationItem[];
	nestingLevel: number;
	isOpen: boolean;
	testId?: string;
};

/**
 * __Sub navigation__
 *
 * A sub navigation for the sidebar navigation.
 */
const SubNavigation = ({ subitems, nestingLevel, isOpen, testId }: SubNavigationProps) => {
	return subitems.length > 0 ? (
		<ResizingHeight>
			{({ ref }) => (
				<div css={animationContainerStyles} ref={ref} data-testid={testId}>
					<ul css={[listStyles, !isOpen && hiddenListStyles]}>
						{subitems.map(({ item, subitems: subitemsDepth2 }) => {
							const itemTestId = testId ? `${testId}_${kebabCase(item.title)}` : undefined;
							return (
								<NavigationItem
									key={item.slug || item.title}
									item={item}
									subitems={subitemsDepth2}
									nestingLevel={nestingLevel}
									testId={itemTestId}
									isParentOpen={isOpen}
								/>
							);
						})}
					</ul>
				</div>
			)}
		</ResizingHeight>
	) : null;
};

export default SubNavigation;
