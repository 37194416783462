/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { createContext, Fragment, type PropsWithChildren } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import { Box, Stack } from '@atlaskit/primitives';
import { useThemeObserver } from '@atlaskit/tokens';

import { getBoxShadow, getBoxShadowAsList } from '../../utils';
import themeColorModeStyles from '../theme-color-mode-styles';
import type { ActiveTheme, TransformedTokenGrouped } from '../types';

import TokenButton from './token-button';
import {
	BorderWidth,
	Color,
	Elevation,
	Label,
	Opacity,
	Radius,
	Space,
} from './token-button-variants';
import { type TokenValue } from './token-definition';

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export const ThemeBoundaryContext = createContext<ActiveTheme>({});

const ThemeBoundary = (props: PropsWithChildren<{ applyTheme?: ActiveTheme }>) => {
	const currentTheme = useThemeObserver();
	const { applyTheme = {}, children } = props;

	const { colorMode: currentColorMode = 'light' } = currentTheme;
	const normalisedTheme: ActiveTheme = { ...currentTheme, ...applyTheme };
	const { colorMode: applyColorMode = currentColorMode } = normalisedTheme;

	// Only apply CSS when it's needed to reset
	const themeCss =
		applyColorMode !== currentColorMode ? themeColorModeStyles[applyColorMode] : undefined;

	return (
		<ThemeBoundaryContext.Provider value={normalisedTheme}>
			{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={themeCss}>{children}</div>
		</ThemeBoundaryContext.Provider>
	);
};
interface CopyButtonValueProps {
	variantLabel?: string;
	isFixedWidth?: boolean;
	tokenValue: TokenValue;
	tokenOriginal: TransformedTokenGrouped;
}

const TokenButtonValue = ({ tokenValue, variantLabel }: CopyButtonValueProps) => {
	const { value, attributes, original, testId, forceTheme: applyTheme } = tokenValue;
	const { colorMode } = useThemeObserver();
	const { colorMode: applyColorMode = colorMode } = applyTheme ?? {};
	const group = attributes.group;
	const labelValue = () => {
		switch (group) {
			case 'shadow':
				return <BoxShadowValue boxShadowValue={value} />;
			case 'shape':
			case 'spacing':
			case 'typography':
				return value;
			default:
				return original.value;
		}
	};

	const copyValue = () => {
		switch (group) {
			case 'shadow':
				return getBoxShadow(value);
			case 'shape':
			case 'spacing':
			case 'typography':
				return value;
			default:
				return original.value;
		}
	};

	const hasTheme = !!applyTheme;
	const hasBorder = hasTheme && colorMode === applyColorMode;

	return (
		<ThemeBoundary applyTheme={applyTheme}>
			<TokenButton
				copyValue={copyValue()}
				variantLabel={variantLabel}
				testId={testId}
				hasTheme={hasTheme}
				hasBorder={hasBorder}
			>
				{({ isHovered }) => (
					<Fragment>
						{group === 'opacity' && <Opacity value={value} />}
						{group === 'shadow' && <Elevation value={value} />}
						{(group === 'paint' || group === 'raw') && <Color value={value} />}
						{group === 'spacing' && <Space value={value} />}
						{group === 'shape' &&
							(original.value.startsWith('Radius') ? (
								<Radius value={value} />
							) : (
								<BorderWidth value={value} />
							))}
						<Label isHoverDisabled={hasTheme} isHovered={isHovered}>
							{labelValue()}
						</Label>
					</Fragment>
				)}
			</TokenButton>
		</ThemeBoundary>
	);
};

const BoxShadowValue = (props: { boxShadowValue: any }) => {
	const { boxShadowValue } = props;
	const boxShadowsAsList = getBoxShadowAsList(boxShadowValue);

	return (
		<Stack space="space.100">
			{boxShadowsAsList.map((value, index) => (
				<Box key={index}>{value}</Box>
			))}
		</Stack>
	);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default TokenButtonValue;
