import React, { useContext, useMemo } from 'react';

import Lozenge from '@atlaskit/lozenge';
import { Inline } from '@atlaskit/primitives';
import { getCSSCustomProperty, getTokenId } from '@atlaskit/tokens/token-ids';

import type { TransformedTokenMerged } from '../types';

import TokenButton from './token-button';
import { Label } from './token-button-variants';
import { TokenNameSyntaxContext } from './token-name-syntax-context';

enum LozengeAppearance {
	deprecated = 'moved',
	deleted = 'removed',
	experimental = 'new',
}

type TokenNameProps = Pick<TransformedTokenMerged, 'name' | 'attributes'>;

const TokenName = ({ name, attributes }: TokenNameProps) => {
	const { syntax } = useContext(TokenNameSyntaxContext);

	const formattedName = useMemo(() => {
		if (syntax === 'css-var') {
			return getCSSCustomProperty(name);
		}
		return getTokenId(name);
	}, [name, syntax]);

	return (
		<Inline space="space.100" alignBlock="center">
			<TokenButton copyValue={formattedName}>
				<Label appearance="subtle">{formattedName}</Label>
			</TokenButton>
			{attributes.state !== 'active' && (
				<Lozenge appearance={LozengeAppearance[attributes.state]}>{attributes.state}</Lozenge>
			)}
		</Inline>
	);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default TokenName;
