import { FlagsProvider } from '@atlaskit/flag';
import FlagsProviderExample from "../../../../../../packages/design-system/flag/examples/constellation/flags-provider";
import FlagsProviderShowFlag from "../../../../../../packages/design-system/flag/examples/constellation/flags-provider-show-flag";
import RAW_UNSAFE_atlaskitSLASHflag from "!!raw-loader!@atlaskit/flag";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflags_provider from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flags-provider";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflags_provider_show_flag from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flags-provider-show-flag";
import * as React from 'react';
export default {
  FlagsProvider,
  FlagsProviderExample,
  FlagsProviderShowFlag,
  RAW_UNSAFE_atlaskitSLASHflag,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflags_provider,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflags_provider_show_flag,
  React
};