import SectionMessage from '@atlaskit/section-message';
import PageBasic from "../../../../../../packages/design-system/page/examples/constellation/page-basic";
import RAW_UNSAFE_atlaskitSLASHsection_message from "!!raw-loader!@atlaskit/section-message";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_basic from "!!raw-loader!../../../../../../packages/design-system/page/examples/constellation/page-basic";
import * as React from 'react';
export default {
  SectionMessage,
  PageBasic,
  RAW_UNSAFE_atlaskitSLASHsection_message,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_basic,
  React
};