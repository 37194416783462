import InlineDialogDefaultExample from "../../../../../../packages/design-system/inline-dialog/examples/constellation/inline-dialog-default";
import InlineDialogPositioningExample from "../../../../../../packages/design-system/inline-dialog/examples/constellation/inline-dialog-positioning";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHinline_dialog_default from "!!raw-loader!../../../../../../packages/design-system/inline-dialog/examples/constellation/inline-dialog-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHinline_dialog_positioning from "!!raw-loader!../../../../../../packages/design-system/inline-dialog/examples/constellation/inline-dialog-positioning";
import * as React from 'react';
export default {
  InlineDialogDefaultExample,
  InlineDialogPositioningExample,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHinline_dialog_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHinline_dialog_positioning,
  React
};