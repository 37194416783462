import SectionMessage from '@atlaskit/section-message';
import NestedDraggablesExample from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/nested-draggables";
import RAW_UNSAFE_atlaskitSLASHsection_message from "!!raw-loader!@atlaskit/section-message";
import RAW_UNSAFEUPUPSLASHUPUPSLASHUPUPSLASHUPUPSLASHexamplesSLASHnested_draggables from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/nested-draggables";
import * as React from 'react';
export default {
  SectionMessage,
  NestedDraggablesExample,
  RAW_UNSAFE_atlaskitSLASHsection_message,
  RAW_UNSAFEUPUPSLASHUPUPSLASHUPUPSLASHUPUPSLASHexamplesSLASHnested_draggables,
  React
};