import React, { useEffect, useState } from 'react';

import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import ImageIcon from '@atlaskit/icon/glyph/image';
import migrationMap from '@atlaskit/icon/UNSAFE_migration-map';
import Link from '@atlaskit/link';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { SimpleTag as Tag } from '@atlaskit/tag';

import { getIcon, getMigrationEndpoint } from '../helpers';
import { type IconData, type NewIconData } from '../types';

import CodeCopyToClipboard from './code-copy-to-clipboard';
import CodeIconCopyToClipboard from './code-icon-copy-to-clipboard';

export type LegacyIconDetailsProps = Omit<BaseLegacyIconDetailsProps, 'migrationIcon'>;

export type BaseLegacyIconDetailsProps = {
	icon?: IconData;
	testId?: string;
	isModal?: boolean;
	onClose?: () => void;
	migrationIcon?: NewIconData;
};

const boxPanelStyles = xcss({
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderRadius: '4px',
	borderWidth: 'border.width',
	width: '336px',
});

const closeButtonStyles = xcss({
	position: 'absolute',
	insetInlineEnd: 'space.200',
	insetBlockStart: 'space.200',
});

const sharedBoxBorderStyles = xcss({
	borderBlockEndColor: 'color.border',
	borderBlockEndStyle: 'solid',
	borderWidth: 'border.width',
});

/**
 * __Legacy icon details__
 *
 * A panel which is populated when a legacy icon is selected.
 * Contains information about the legacy icon. *
 */
const LegacyIconDetails = ({ icon, isModal, onClose, testId }: LegacyIconDetailsProps) => {
	const [migrationIcon, setMigrationIcon] = useState<NewIconData>();

	useEffect(() => {
		if (icon) {
			const newIcon = migrationMap[icon.packageName.substring(21)]?.newIcon;

			if (newIcon) {
				const iconPromise = getIcon(newIcon.name, newIcon.type);

				if (iconPromise) {
					iconPromise.then((icon) => {
						setMigrationIcon(icon);
					});
				}
			} else {
				setMigrationIcon(undefined);
			}
		}
	}, [icon]);

	return (
		<BaseLegacyIconDetails
			icon={icon}
			testId={testId}
			migrationIcon={migrationIcon}
			isModal={isModal}
			onClose={onClose}
		/>
	);
};

export function BaseLegacyIconDetails({
	icon,
	testId,
	isModal,
	migrationIcon,
	onClose,
}: BaseLegacyIconDetailsProps) {
	return icon ? (
		<Box xcss={!isModal && boxPanelStyles}>
			<Stack testId={testId} grow="fill">
				<Box
					xcss={sharedBoxBorderStyles}
					backgroundColor="elevation.surface.sunken"
					paddingInline="space.600"
					paddingBlock="space.300"
				>
					<Inline alignInline="center">
						<icon.component label="" size="xlarge" />
					</Inline>
					{isModal && (
						<Box xcss={closeButtonStyles}>
							<IconButton
								appearance="subtle"
								icon={CrossIcon}
								label="Close Modal"
								onClick={onClose}
							/>
						</Box>
					)}
				</Box>
				<Box xcss={sharedBoxBorderStyles} padding="space.300">
					<Stack space="space.150">
						<Inline alignBlock="center" space="space.050">
							<Heading size="medium">{icon.iconName}</Heading>
							<Box>
								<Tag text="Legacy" />
							</Box>
						</Inline>
						<Stack space="space.100">
							<Heading size="small">Maintained by</Heading>
							<Text>Design System Team</Text>
						</Stack>
					</Stack>
				</Box>
				<Box xcss={sharedBoxBorderStyles} padding="space.300">
					<Stack space="space.150">
						<Heading size="small">React</Heading>
						<CodeCopyToClipboard
							messages={{
								prompt: 'Copy import',
								success: 'Copied!',
								fail: 'Copy failed',
							}}
							value={`import ${icon.componentName} from '${icon.packageName}';`}
						>
							import {icon.componentName} from '{icon.packageName}';
						</CodeCopyToClipboard>
						<Link href="/components/icon/icon-legacy/examples" target="_blank">
							Icon code examples
						</Link>
					</Stack>
				</Box>
				{migrationIcon && (
					<Box xcss={sharedBoxBorderStyles} padding="space.300">
						<Stack space="space.150">
							<Heading size="small">New icon (recommended migration)</Heading>
							<CodeIconCopyToClipboard
								value={`import ${migrationIcon.componentName} from '${getMigrationEndpoint(migrationIcon, icon.packageName.substring(21))}';`}
								name={`${migrationIcon.type}/${migrationIcon.key}`}
								icon={migrationIcon.component}
							/>
							<Text as="p">
								Before starting migration, review the{' '}
								<Link href="/components/icon/migration-guide">migration guide</Link>.
							</Text>
						</Stack>
					</Box>
				)}
				<Box padding="space.300">
					<Stack space="space.150">
						<Heading size="small">Figma</Heading>
						<Link
							href="https://www.figma.com/design/dpvtcht3y5zRH3edWgCZxt/%F0%9F%93%9A-ADS-Components?m=auto&node-id=10573-19668&t=td57HMYgZgZsGyxI-1"
							target="_blank"
						>
							Legacy icon library (Atlassians only)
						</Link>
					</Stack>
				</Box>
			</Stack>
		</Box>
	) : (
		<Box
			xcss={boxPanelStyles}
			backgroundColor="elevation.surface.sunken"
			paddingInline="space.600"
			paddingBlock="space.300"
		>
			<Stack space="space.150" testId={testId} alignInline="center" grow="fill">
				<ImageIcon label="" />
				<Text color="color.text.subtle" align="center">
					Select an icon for details
				</Text>
			</Stack>
		</Box>
	);
}

export default LegacyIconDetails;
