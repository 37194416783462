/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import upperFirst from 'lodash/upperFirst';

import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import SubGroup from './components/subgroup';
import groupedTokens from './grouped-tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const listStyleReset = css({
	margin: 0,
	padding: 0,
	listStyle: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const listItemStyleReset = css({
	marginBlockEnd: 0,
	marginBlockStart: 0,
});

const displayHeadingContainerStyles = xcss({
	marginBlockEnd: 'space.200',
});

const rampStyles = css({
	marginBlockEnd: token('space.500', '40px'),
});

/**
 * __Color palette ramps__.
 *
 * Color palette ramps visually display color tokens.
 */
const ColorPaletteRamps = () =>
	groupedTokens.length > 0 ? (
		<ul css={listStyleReset}>
			{groupedTokens.map((group) => (
				<li key={group.name} css={rampStyles}>
					{group.name && group.name !== 'colors' && (
						<Box xcss={displayHeadingContainerStyles}>
							<Heading size="medium">{upperFirst(group.name)}</Heading>
						</Box>
					)}
					{group.subgroups.length > 0 && <SubGroup {...group} />}
				</li>
			))}
		</ul>
	) : (
		<Fragment></Fragment>
	);

export default ColorPaletteRamps;
