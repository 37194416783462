// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { NAVIGATION_BREAKPOINTS } from './constants';

/**
 * Hides content when mobile navigation is active.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const hideOnMobileNavigationStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[NAVIGATION_BREAKPOINTS.MOBILE]: {
		display: 'none',
	},
});

/**
 * Hides content when desktop navigation is active.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const hideOnDesktopNavigationStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[NAVIGATION_BREAKPOINTS.DESKTOP]: {
		display: 'none',
	},
});
