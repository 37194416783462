/**
 * Simple check to be used in a filter of changelog version headings.
 * We use this so that the contextual 'contents' nav isn't overwhelmed by many
 * minors and patches that may only say 'Updated dependencies'. This allows a
 * user to jump quickly to important versions.
 */
export const isVersionAMajor = (version: string): boolean => {
	return (
		// standard major, eg. 13.0.0
		version.includes('.0.0') ||
		// Pre v1 major, so 0.*.0
		(version.startsWith('0.') && /0\.\b([[0-9]+)\b\.0/g.test(version))
	);
};
