/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { token } from '@atlaskit/tokens';

const styles = css({
	margin: `${token('space.150', '12px')} 0 0 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-child': {
		marginBlockStart: 0,
	},
});

/**
 * __Collapsing top margin__.
 *
 * A wrapper that adds a collapsing top margin to the first element of a list.
 *
 */
const CollapsingTopMargin = ({ children }: { children: React.ReactNode }) => {
	return <div css={styles}>{children}</div>;
};

export default CollapsingTopMargin;
