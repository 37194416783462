exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brand-tsx": () => import("./../../../src/pages/brand.tsx" /* webpackChunkName: "component---src-pages-brand-tsx" */),
  "component---src-pages-components-tsx": () => import("./../../../src/pages/components.tsx" /* webpackChunkName: "component---src-pages-components-tsx" */),
  "component---src-pages-content-tsx": () => import("./../../../src/pages/content.tsx" /* webpackChunkName: "component---src-pages-content-tsx" */),
  "component---src-pages-foundations-tsx": () => import("./../../../src/pages/foundations.tsx" /* webpackChunkName: "component---src-pages-foundations-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-license-mdx": () => import("./../../../src/pages/license.mdx" /* webpackChunkName: "component---src-pages-license-mdx" */),
  "component---src-pages-patterns-tsx": () => import("./../../../src/pages/patterns.tsx" /* webpackChunkName: "component---src-pages-patterns-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-tokens-tsx": () => import("./../../../src/pages/tokens.tsx" /* webpackChunkName: "component---src-pages-tokens-tsx" */),
  "component---src-templates-guideline-page-tsx": () => import("./../../../src/templates/guideline-page.tsx" /* webpackChunkName: "component---src-templates-guideline-page-tsx" */),
  "component---src-templates-package-page-index-tsx": () => import("./../../../src/templates/package-page/index.tsx" /* webpackChunkName: "component---src-templates-package-page-index-tsx" */),
  "component---src-templates-package-page-sub-page-tsx": () => import("./../../../src/templates/package-page/sub-page.tsx" /* webpackChunkName: "component---src-templates-package-page-sub-page-tsx" */)
}

