/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { createContext } from 'react';

export type TokenNameSyntax = 'default' | 'css-var';

interface ContextValue {
	syntax: TokenNameSyntax;
}

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export const TokenNameSyntaxContext = createContext<ContextValue>({
	syntax: 'default',
});
