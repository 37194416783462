import TagGroupDefault from "../../../../../../packages/design-system/tag-group/examples/constellation/tag-group-default";
import TagGroupAlignmentStart from "../../../../../../packages/design-system/tag-group/examples/constellation/tag-group-alignment-start";
import TagGroupAlignmentEnd from "../../../../../../packages/design-system/tag-group/examples/constellation/tag-group-alignment-end";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_group_default from "!!raw-loader!../../../../../../packages/design-system/tag-group/examples/constellation/tag-group-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_group_alignment_start from "!!raw-loader!../../../../../../packages/design-system/tag-group/examples/constellation/tag-group-alignment-start";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_group_alignment_end from "!!raw-loader!../../../../../../packages/design-system/tag-group/examples/constellation/tag-group-alignment-end";
import * as React from 'react';
export default {
  TagGroupDefault,
  TagGroupAlignmentStart,
  TagGroupAlignmentEnd,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_group_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_group_alignment_start,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_group_alignment_end,
  React
};