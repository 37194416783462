/* eslint-disable @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

const TRANSITION_DURATION = '200ms';

export type CollapseButtonProps = {
	// TODO: Fix this
	// eslint-disable-next-line @repo/internal/react/boolean-prop-naming-convention
	collapsed: boolean;
};

/**
 * __Collapse button__
 *
 * A collapse button toggles between minimizing and expanding an example.
 *
 */
const CollapseButton = styled.button<CollapseButtonProps>({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: '2rem',
	position: 'relative',
	backgroundColor: token('color.background.neutral'),
	borderRadius: `0 0 ${token('border.radius', '4px')} ${token('border.radius', '4px')}`,
	borderWidth: '0px',
	transition: `background ${TRANSITION_DURATION}, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)`,
	outline: 'none',
	cursor: 'pointer',
	fontFamily:
		"'Charlie Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
	fontWeight: token('font.weight.semibold', '600'),
	letterSpacing: '1px',
	textTransform: 'uppercase',
	color: token('color.text.subtlest'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		backgroundColor: token('color.background.neutral.hovered'),
		color: token('color.text.subtlest'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active': {
		backgroundColor: token('color.background.neutral.pressed'),
		color: token('color.text'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus': {
		boxShadow: `0 0 0 2px ${token('color.border.focused')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export default CollapseButton;
