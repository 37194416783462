import SectionMessage from '@atlaskit/section-message';
import OverElementExample from "../../../../../../packages/pragmatic-drag-and-drop/auto-scroll/examples/over-element";
import LazyLoadedExample from "../../../../../../packages/pragmatic-drag-and-drop/auto-scroll/examples/lazy-loaded";
import AxisLocking from "../../../../../../packages/pragmatic-drag-and-drop/auto-scroll/examples/axis-locking";
import RAW_UNSAFE_atlaskitSLASHsection_message from "!!raw-loader!@atlaskit/section-message";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHover_element from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/auto-scroll/examples/over-element";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHlazy_loaded from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/auto-scroll/examples/lazy-loaded";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHaxis_locking from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/auto-scroll/examples/axis-locking";
import * as React from 'react';
export default {
  SectionMessage,
  OverElementExample,
  LazyLoadedExample,
  AxisLocking,
  RAW_UNSAFE_atlaskitSLASHsection_message,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHover_element,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHlazy_loaded,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHaxis_locking,
  React
};