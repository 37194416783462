import CustomIconDefault from "../../../../../../packages/design-system/icon/examples/constellation/custom-icon-default.tsx";
import CustomIconSmall from "../../../../../../packages/design-system/icon/examples/constellation/custom-icon-small.tsx";
import CustomIconMedium from "../../../../../../packages/design-system/icon/examples/constellation/custom-icon-medium.tsx";
import CustomIconLarge from "../../../../../../packages/design-system/icon/examples/constellation/custom-icon-large.tsx";
import CustomIconPrimaryColor from "../../../../../../packages/design-system/icon/examples/constellation/custom-icon-primary-color.tsx";
import CustomIconSecondaryColor from "../../../../../../packages/design-system/icon/examples/constellation/custom-icon-secondary-color.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_default_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/custom-icon-default.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_small_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/custom-icon-small.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_medium_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/custom-icon-medium.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_large_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/custom-icon-large.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_primary_color_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/custom-icon-primary-color.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_secondary_color_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/custom-icon-secondary-color.tsx";
import * as React from 'react';
export default {
  CustomIconDefault,
  CustomIconSmall,
  CustomIconMedium,
  CustomIconLarge,
  CustomIconPrimaryColor,
  CustomIconSecondaryColor,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_default_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_small_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_medium_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_large_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_primary_color_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_icon_secondary_color_tsx,
  React
};