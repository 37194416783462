import TabsDefault from "../../../../../../packages/design-system/tabs/examples/constellation/tabs-default";
import TabsControlled from "../../../../../../packages/design-system/tabs/examples/constellation/tabs-controlled";
import TabTooltip from "../../../../../../packages/design-system/tabs/examples/constellation/tab-tooltip";
import TabCustom from "../../../../../../packages/design-system/tabs/examples/constellation/tab-custom";
import TabPabelCustom from "../../../../../../packages/design-system/tabs/examples/constellation/tab-panel-custom";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtabs_default from "!!raw-loader!../../../../../../packages/design-system/tabs/examples/constellation/tabs-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtabs_controlled from "!!raw-loader!../../../../../../packages/design-system/tabs/examples/constellation/tabs-controlled";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtab_tooltip from "!!raw-loader!../../../../../../packages/design-system/tabs/examples/constellation/tab-tooltip";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtab_custom from "!!raw-loader!../../../../../../packages/design-system/tabs/examples/constellation/tab-custom";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtab_panel_custom from "!!raw-loader!../../../../../../packages/design-system/tabs/examples/constellation/tab-panel-custom";
import * as React from 'react';
export default {
  TabsDefault,
  TabsControlled,
  TabTooltip,
  TabCustom,
  TabPabelCustom,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtabs_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtabs_controlled,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtab_tooltip,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtab_custom,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtab_panel_custom,
  React
};