import React from 'react';

import { Text } from '@atlaskit/primitives';
import { getTokenId } from '@atlaskit/tokens/token-ids';

import { type TransformedTokenGrouped } from '../types';

type TokenLifecycleProps = {
	isLoading?: boolean;
	transformedToken?: TransformedTokenGrouped;
};

const TokenLifecycle = (props: TokenLifecycleProps) => {
	const { isLoading, transformedToken } = props;

	if (isLoading || !transformedToken) {
		return null;
	}

	return (
		<Text size="UNSAFE_small" color="color.text.subtlest">
			Introduced v{transformedToken.attributes.introduced}
			{'deprecated' in transformedToken.attributes &&
				` → Deprecated v${transformedToken.attributes.deprecated}`}
			{'deleted' in transformedToken.attributes &&
				` → Deleted v${transformedToken.attributes.deleted}`}
			{'replacement' in transformedToken.attributes &&
				`. Replace with ${
					Array.isArray(transformedToken.attributes.replacement)
						? transformedToken.attributes.replacement.map(
								(replacement, i) => `${getTokenId(replacement)}${i > 0 ? ' / ' : ' '}`,
							)
						: getTokenId(transformedToken.attributes.replacement)
				}`}
		</Text>
	);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default TokenLifecycle;
