import React from 'react';

import Image from '@atlaskit/image';
import { Inline, Stack, Text } from '@atlaskit/primitives';

import principle1Img from './images/principle1.png';
import principle2Img from './images/principle2.png';
import principle3Img from './images/principle3.png';

const principles = [
	{
		image: principle1Img,
		alt: '',
		title: 'Optimize for readability',
		description:
			'Help readers understand communications easily and enhance their experience, regardless of their abilities.',
	},
	{
		image: principle2Img,
		alt: '',
		title: 'Create visual harmony',
		description:
			'Typography should be consistent and cohesive. Use visual hierarchy and space to simplify complex information.',
	},
	{
		image: principle3Img,
		alt: '',
		title: 'Contextualize for different users',
		description:
			'Tailor for different preferences, operating systems and applications, while keeping in mind how people consume and process information.',
	},
];

/**
 * __Typography principles__
 */
export const TypographyPrinciples = () => {
	return (
		<Inline space="space.200">
			{principles.map((principle, index) => {
				return (
					<Stack key={index} space="space.100" grow="fill">
						<Image src={principle.image} alt={principle.alt} />
						<Text weight="bold">{principle.title}</Text>
						<Text>{principle.description}</Text>
					</Stack>
				);
			})}
		</Inline>
	);
};
