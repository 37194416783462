import { type Placement } from '@atlaskit/popper';

export const Placements: Array<Placement> = [
	'auto-end',
	'auto',
	'auto-start',
	'top-end',
	'top',
	'top-start',
	'right-end',
	'right',
	'right-start',
	'bottom-start',
	'bottom',
	'bottom-end',
	'left-start',
	'left',
	'left-end',
];
