import React from 'react';

import { token } from '@atlaskit/tokens';

const AvatarPatterns = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<rect fill={token('color.icon.accent.orange')} height="24" rx="3" width="24" />
		<path
			d="m14.912 10.852c0-1.692-.984-2.736-2.976-2.736h-2.904v7.884h1.44v-2.412h1.464c1.992 0 2.976-.984 2.976-2.736zm-3.12 1.476h-1.32v-2.904h1.32c1.236 0 1.704.588 1.704 1.452 0 .9-.468 1.5-1.704 1.452z"
			fill={token('color.icon.inverse')}
		/>
	</svg>
);

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default AvatarPatterns;
