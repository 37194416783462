import React from 'react';

import { Code } from '@atlaskit/code';
import { Text } from '@atlaskit/primitives';
import { Table, TBody, TD, TH, THead, TR } from '@atlaskit/table/primitives';

import HorizontalScrollContainer from '../horizontal-scroll-container';
import CollapsingTopMargin from '../utils/collapsing-top-margin';

interface State {
	name: 'collapsed' | 'default' | 'defaultSmall' | 'defaultLarge';
	width: string;
	suitableFor: string;
}

const sideNavigationStates: State[] = [
	{
		name: 'collapsed',
		width: '56px',
		suitableFor: '768+px',
	},
	{
		name: 'default',
		width: '320px',
		suitableFor: '768+px',
	},
];

const asideStates: State[] = [
	{
		name: 'collapsed',
		width: '56px',
		suitableFor: '1024+px',
	},
	{
		name: 'defaultSmall',
		width: '320px',
		suitableFor: '1024-1439px',
	},
	{
		name: 'default',
		width: '400px',
		suitableFor: '1440-1767px',
	},
	{
		name: 'defaultLarge',
		width: '504px',
		suitableFor: '1768+px',
	},
];

/**
 * __Side nav/aside table__
 */
export const SideNavigationAsideTable = () => {
	return (
		<CollapsingTopMargin>
			<HorizontalScrollContainer>
				<Table>
					<THead>
						<TR isBodyRow={false}>
							<TH>State</TH>
							<TH>Width</TH>
							<TH>Suitable for</TH>
						</TR>
					</THead>
					<TBody>
						<TableSubHeaderRow>Side navigation</TableSubHeaderRow>
						{sideNavigationStates.map((state) => {
							return (
								<TR key={state.name}>
									<TD>
										<Code>{state.name}</Code>
									</TD>
									<TD>
										<Text>{state.width}</Text>
									</TD>
									<TD>
										<Text>{state.suitableFor}</Text>
									</TD>
								</TR>
							);
						})}
						<TableSubHeaderRow>Aside</TableSubHeaderRow>
						{asideStates.map((state) => {
							return (
								<TR key={state.name}>
									<TD>
										<Code>{state.name}</Code>
									</TD>
									<TD>
										<Text>{state.width}</Text>
									</TD>
									<TD>
										<Text>{state.suitableFor}</Text>
									</TD>
								</TR>
							);
						})}
					</TBody>
				</Table>
			</HorizontalScrollContainer>
		</CollapsingTopMargin>
	);
};

const TableSubHeaderRow = ({ children }: any) => {
	return (
		<TR>
			<TH colSpan={3}>
				<Text weight="medium" color="color.text.subtlest">
					{children}
				</Text>
			</TH>
		</TR>
	);
};
