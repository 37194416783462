import { createContext } from 'react';

type PageContextProps = {
	/**
	 * The URI of the current _base_ page.
	 *
	 * This differs from location.pathname in that this does not include tab names.
	 * @example uri === '/components/button' // NOT '/components/button/examples'
	 */
	uri: string;
};

export const PageContext = createContext<PageContextProps>({ uri: '/' });
