import link1Do from "../../../../../../packages/design-system/link/constellation/index/images/link-01a-do.png";
import link1Dont from "../../../../../../packages/design-system/link/constellation/index/images/link-01b-dont.png";
import link2Do from "../../../../../../packages/design-system/link/constellation/index/images/link-02a-do.png";
import link2Dont from "../../../../../../packages/design-system/link/constellation/index/images/link-02b-dont.png";
import * as React from 'react';
export default {
  link1Do,
  link1Dont,
  link2Do,
  link2Dont,
  React
};