import React from 'react';

import { Box } from '@atlaskit/primitives';

import LikeIcon from '../../glyph/like';

const IconLargeExample = () => {
	return (
		<Box>
			<LikeIcon size="large" label="" />
		</Box>
	);
};

export default IconLargeExample;
