import React from 'react';

import Heading from '@atlaskit/heading';
import { Box, media, xcss } from '@atlaskit/primitives';

const headingItemStyles = xcss({
	paddingBlockEnd: 'space.150',
	paddingInlineStart: 'space.400',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':first-of-type': {
		paddingBlockEnd: 'space.150',
		paddingBlockStart: 'space.100',
	},
	[media.above.sm]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		':first-of-type': {
			paddingBlockStart: 'space.400',
		},
	},
});

type HeadingItemProps = { children: React.ReactNode; testId?: string };

/**
 * __Heading item__
 *
 * Displays a heading inside the side navigation.
 */
const HeadingItem = ({ children, testId }: HeadingItemProps) => (
	<Box xcss={headingItemStyles} testId={testId}>
		<Heading size="small" as="h2">
			{children}
		</Heading>
	</Box>
);

export default HeadingItem;
