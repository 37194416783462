import TextareaDefault from "../../../../../../packages/design-system/textarea/examples/constellation/text-area-default";
import TextareaForm from "../../../../../../packages/design-system/textarea/examples/constellation/text-area-form";
import TextareaValidation from "../../../../../../packages/design-system/textarea/examples/constellation/text-area-validation";
import TextareaResize from "../../../../../../packages/design-system/textarea/examples/constellation/text-area-resize";
import TextAreaAppearanceStandard from "../../../../../../packages/design-system/textarea/examples/constellation/text-area-appearance-standard";
import TextAreaAppearanceSubtle from "../../../../../../packages/design-system/textarea/examples/constellation/text-area-appearance-subtle";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_default from "!!raw-loader!../../../../../../packages/design-system/textarea/examples/constellation/text-area-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_form from "!!raw-loader!../../../../../../packages/design-system/textarea/examples/constellation/text-area-form";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_validation from "!!raw-loader!../../../../../../packages/design-system/textarea/examples/constellation/text-area-validation";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_resize from "!!raw-loader!../../../../../../packages/design-system/textarea/examples/constellation/text-area-resize";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_appearance_standard from "!!raw-loader!../../../../../../packages/design-system/textarea/examples/constellation/text-area-appearance-standard";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_appearance_subtle from "!!raw-loader!../../../../../../packages/design-system/textarea/examples/constellation/text-area-appearance-subtle";
import * as React from 'react';
export default {
  TextareaDefault,
  TextareaForm,
  TextareaValidation,
  TextareaResize,
  TextAreaAppearanceStandard,
  TextAreaAppearanceSubtle,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_form,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_validation,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_resize,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_appearance_standard,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtext_area_appearance_subtle,
  React
};