import isProd from './is-prod';

export const filterReadyForPreview = (
	nodes: Queries.ContentfulGuideline[],
	isProduction = isProd,
) => {
	/**
	 * There are three cases for `readyForPreview`
	 * 1. `false`: The node is a draft, but is not ready to be shown on staging
	 * 2. `true`: The node is a draft, and can be rendered on staging with a draft `Lozenge`. Contentful's Content Delivery API will not return draft content in production
	 * 3. `undefined`: The node is not a draft and can be rendered on staging, and productions
	 */
	return isProduction() ? nodes : nodes.filter((item) => item.readyForPreview !== false);
};
