import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

export const CATEGORY_NAMES = {
	GET_STARTED: 'get started',
	BRAND: 'brand',
	FOUNDATIONS: 'foundations',
	COMPONENTS: 'components',
	CONTENT: 'content',
	PATTERNS: 'patterns',
	RESOURCES: 'resources',
	LICENSE: 'license',
	TOKENS: 'tokens',
};

export const PAGE_GUTTER = {
	/**
	 * The static value to use outside of any media query, assuming you cascade gutters upwards.
	 */
	base: token('space.200', '16px'),
	sm: token('space.300', '24px'),
	md: token('space.500', '40px'),
	lg: token('space.1000', '80px'),
};

/**
 * Breakpoints at which the site navigation changes
 * between mobile and desktop.
 *
 * Only to be used for applying styles that are directly
 * related to the navigation.
 */
export const NAVIGATION_BREAKPOINTS = {
	MOBILE: media.below.md,
	DESKTOP: media.above.md,
};
