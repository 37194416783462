/* eslint-disable @repo/internal/react/no-class-components */

import React from 'react';

declare global {
	interface Window {
		Sentry?: {
			configureScope: any;
			captureException: (error: any) => void;
		};
	}
}

/**
 * __Error boundary__
 *
 * An error boundary displays fallback content in the event the main content fails to render.
 *
 */
class ErrorBoundary extends React.Component<
	{ children: any; fallback?: any },
	{ hasError: boolean }
> {
	constructor(props: { children: any; fallback?: any }) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: any) {
		if (
			typeof window === 'undefined' ||
			!window.Sentry ||
			!window.ResizeObserver ||
			!window.IntersectionObserver ||
			!Object.fromEntries ||
			!Promise.allSettled
		) {
			return;
		}

		window.Sentry.configureScope((scope: any) => {
			Object.keys(errorInfo).forEach((key) => {
				scope.setExtra(key, errorInfo[key]);
			});
		});

		window.Sentry.captureException(error);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return this.props.fallback || <></>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
