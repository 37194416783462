/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { type TransformedTokenGrouped } from '../types';

type TokenDescriptionProps = {
	isLoading?: boolean;
	transformedToken?: TransformedTokenGrouped;
};

const styles = css({ margin: 0, overflowWrap: 'anywhere', textAlign: 'start' });

const TokenDescription = (props: TokenDescriptionProps) => {
	const { isLoading, transformedToken } = props;
	if (isLoading || !transformedToken) {
		return null;
	}

	return <p css={styles}>{transformedToken.attributes.description}</p>;
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default TokenDescription;
