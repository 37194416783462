import SectionMessage from '@atlaskit/section-message';
import PageLayoutBasic from "../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-basic";
import PageLayoutInteractive from "../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-interactive";
import PageLayoutIntegration from "../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-integration";
import PageLayoutResizeSidebar from "../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-resize-sidebar";
import PageLayoutLockedSidebar from "../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-locked-sidebar";
import PageLayoutCustomSkipLinks from "../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-custom-skip-links";
import PageLayoutServerRendered from "../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-server-rendered";
import { ExpandLeftSidebarKeyboardShortcut } from "../../../../../../packages/design-system/page-layout/examples/common/expand-sidebar-keyboard-shortcut";
import PageLayoutLeftSidebarWithKeyboardExpand from "../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-left-sidebar-keyboard-event";
import RAW_UNSAFE_atlaskitSLASHsection_message from "!!raw-loader!@atlaskit/section-message";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_basic from "!!raw-loader!../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-basic";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_interactive from "!!raw-loader!../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-interactive";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_integration from "!!raw-loader!../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-integration";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_resize_sidebar from "!!raw-loader!../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-resize-sidebar";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_locked_sidebar from "!!raw-loader!../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-locked-sidebar";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_custom_skip_links from "!!raw-loader!../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-custom-skip-links";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_server_rendered from "!!raw-loader!../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-server-rendered";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHcommonSLASHexpand_sidebar_keyboard_shortcut from "!!raw-loader!../../../../../../packages/design-system/page-layout/examples/common/expand-sidebar-keyboard-shortcut";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_left_sidebar_keyboard_event from "!!raw-loader!../../../../../../packages/design-system/page-layout/examples/constellation/page-layout-left-sidebar-keyboard-event";
import * as React from 'react';
export default {
  SectionMessage,
  PageLayoutBasic,
  PageLayoutInteractive,
  PageLayoutIntegration,
  PageLayoutResizeSidebar,
  PageLayoutLockedSidebar,
  PageLayoutCustomSkipLinks,
  PageLayoutServerRendered,
  ExpandLeftSidebarKeyboardShortcut,
  PageLayoutLeftSidebarWithKeyboardExpand,
  RAW_UNSAFE_atlaskitSLASHsection_message,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_basic,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_interactive,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_integration,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_resize_sidebar,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_locked_sidebar,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_custom_skip_links,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_server_rendered,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHcommonSLASHexpand_sidebar_keyboard_shortcut,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_layout_left_sidebar_keyboard_event,
  React
};