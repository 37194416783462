import SplitButtonDefault from "../../../../../../packages/design-system/button/examples/constellation/new-button/split-button/split-button-default";
import SplitButtonPrimary from "../../../../../../packages/design-system/button/examples/constellation/new-button/split-button/split-button-primary";
import SplitButtonCompact from "../../../../../../packages/design-system/button/examples/constellation/new-button/split-button/split-button-compact";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHnew_buttonSLASHsplit_buttonSLASHsplit_button_default from "!!raw-loader!../../../../../../packages/design-system/button/examples/constellation/new-button/split-button/split-button-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHnew_buttonSLASHsplit_buttonSLASHsplit_button_primary from "!!raw-loader!../../../../../../packages/design-system/button/examples/constellation/new-button/split-button/split-button-primary";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHnew_buttonSLASHsplit_buttonSLASHsplit_button_compact from "!!raw-loader!../../../../../../packages/design-system/button/examples/constellation/new-button/split-button/split-button-compact";
import * as React from 'react';
export default {
  SplitButtonDefault,
  SplitButtonPrimary,
  SplitButtonCompact,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHnew_buttonSLASHsplit_buttonSLASHsplit_button_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHnew_buttonSLASHsplit_buttonSLASHsplit_button_primary,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHnew_buttonSLASHsplit_buttonSLASHsplit_button_compact,
  React
};