import React from 'react';

import Heading from '@atlaskit/heading';
import { Box, Text, xcss } from '@atlaskit/primitives';

import questions from '../data/questions';
import { type Question, type questionID } from '../data/types';

const questionContainerStyles = xcss({
	padding: 'space.200',
	backgroundColor: 'color.background.selected.bold',
	borderRadius: 'border.radius.200',
	marginBlockEnd: 'space.150',
});

/**
 * __Current question__
 *
 * A current question on the bottom of question list on the right-hand-side token wizard modal dialog.
 *
 */
const CurrentQuestion = ({ questionId }: { questionId: questionID }) => {
	const question: Question = questions[questionId];
	return (
		<Box xcss={questionContainerStyles}>
			<Heading size="small" as="h5" color="color.text.inverse">
				{question.title}
			</Heading>
			{question.metadata?.description && (
				<Text color="color.text.inverse">{question.metadata?.description}</Text>
			)}
		</Box>
	);
};

export default CurrentQuestion;
