import React from 'react';

import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { Box, Pressable, xcss } from '@atlaskit/primitives';

import { type Path } from '../data/types';

const summaryContainerStyles = xcss({
	display: 'grid',
	padding: 'space.200',
	alignItems: 'center',
	columnGap: 'space.050',
	gridTemplateColumns: '16fr 16fr 1fr',
	backgroundColor: 'color.background.selected',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border.selected',
	borderRadius: 'border.radius.200',
	color: 'color.text.selected',
	marginBlockEnd: 'space.150',
});

const pendingStyles = xcss({
	marginInline: 'space.200',
});

const questionSummaryStyles = xcss({
	margin: 'space.0',
	fontSize: '12px',
	fontWeight: 600,
	textAlign: 'left',
});

const answerSummaryStyles = xcss({
	margin: 'space.0',
	justifySelf: 'end',
	textAlign: 'end',
});
/**
 * __Summary__
 *
 * A summary button that displays the previous selections on the left-hand-side modal dialog.
 *
 */
const Summary = ({
	questionAndAnswerSummary,
	onClick,
	isPending,
}: {
	questionAndAnswerSummary: Path;
	onClick: () => void;
	isPending: boolean;
}) => {
	return (
		<Pressable
			xcss={[summaryContainerStyles, isPending && pendingStyles]}
			onClick={onClick}
			key={questionAndAnswerSummary.question}
			type="button"
		>
			<Box as="p" xcss={questionSummaryStyles}>
				{questionAndAnswerSummary.question}
			</Box>
			<Box as="p" xcss={answerSummaryStyles}>
				{questionAndAnswerSummary.answer}
			</Box>
			<ChevronRightIcon label="expand" size="medium" />
		</Pressable>
	);
};

export default Summary;
