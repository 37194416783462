import FlagDefault from "../../../../../../packages/design-system/flag/examples/constellation/flag-default";
import FlagWarning from "../../../../../../packages/design-system/flag/examples/constellation/flag-warning";
import FlagError from "../../../../../../packages/design-system/flag/examples/constellation/flag-error";
import FlagSuccess from "../../../../../../packages/design-system/flag/examples/constellation/flag-success";
import FlagInfo from "../../../../../../packages/design-system/flag/examples/constellation/flag-info";
import FlagActions from "../../../../../../packages/design-system/flag/examples/constellation/flag-actions";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_default from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flag-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_warning from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flag-warning";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_error from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flag-error";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_success from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flag-success";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_info from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flag-info";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_actions from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/flag-actions";
import * as React from 'react';
export default {
  FlagDefault,
  FlagWarning,
  FlagError,
  FlagSuccess,
  FlagInfo,
  FlagActions,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_warning,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_error,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_success,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_info,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHflag_actions,
  React
};