import AvatarDefault from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-default";
import AvatarDisabled from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-disabled";
import AvatarCircle from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-circle";
import AvatarSquare from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-square";
import AvatarTooltip from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-tooltip";
import AvatarSmall from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-small";
import AvatarXSmall from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-x-small";
import AvatarMedium from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-medium";
import AvatarLarge from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-large";
import AvatarXLarge from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-x-large";
import AvatarXXLarge from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-x-x-large";
import AvatarBusy from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-presence-busy";
import AvatarFocus from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-presence-focus";
import AvatarOffline from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-presence-offline";
import AvatarOnline from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-presence-online";
import AvatarApproved from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-status-approved";
import AvatarDeclined from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-status-declined";
import AvatarLocked from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-status-locked";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_default from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_disabled from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-disabled";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_circle from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-circle";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_square from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-square";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_tooltip from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-tooltip";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_small from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-small";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_x_small from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-x-small";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_medium from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-medium";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_large from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-large";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_x_large from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-x-large";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_x_x_large from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-x-x-large";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_presence_busy from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-presence-busy";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_presence_focus from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-presence-focus";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_presence_offline from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-presence-offline";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_presence_online from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-presence-online";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_status_approved from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-status-approved";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_status_declined from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-status-declined";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_status_locked from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-status-locked";
import * as React from 'react';
export default {
  AvatarDefault,
  AvatarDisabled,
  AvatarCircle,
  AvatarSquare,
  AvatarTooltip,
  AvatarSmall,
  AvatarXSmall,
  AvatarMedium,
  AvatarLarge,
  AvatarXLarge,
  AvatarXXLarge,
  AvatarBusy,
  AvatarFocus,
  AvatarOffline,
  AvatarOnline,
  AvatarApproved,
  AvatarDeclined,
  AvatarLocked,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_disabled,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_circle,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_square,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_tooltip,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_small,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_x_small,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_medium,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_large,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_x_large,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_x_x_large,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_presence_busy,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_presence_focus,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_presence_offline,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_presence_online,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_status_approved,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_status_declined,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_status_locked,
  React
};