import React from 'react';

import { token } from '@atlaskit/tokens';

const AvatarBrand = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<rect fill={token('color.icon.accent.blue')} height="24" rx="3" width="24" />
		<path
			d="m12.608 12.532c.96 0 1.368.408 1.368 1.164 0 .72-.42 1.056-1.344 1.056h-2.16v-2.22zm-.288-3.204c.876 0 1.284.324 1.284 1.02s-.372 1.116-1.284 1.116h-1.848v-2.136zm-3.288 6.672h3.78c1.764 0 2.616-.864 2.616-2.124 0-1.116-.564-1.74-1.728-1.932.888-.216 1.332-.804 1.332-1.764 0-1.272-.78-2.064-2.388-2.064h-3.612z"
			fill={token('color.icon.inverse')}
		/>
	</svg>
);

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default AvatarBrand;
