import TagDefault from "../../../../../../packages/design-system/tag/examples/constellation/tag-default";
import TagLink from "../../../../../../packages/design-system/tag/examples/constellation/tag-link";
import TagRemovable from "../../../../../../packages/design-system/tag/examples/constellation/tag-removable";
import TagRemovableLink from "../../../../../../packages/design-system/tag/examples/constellation/tag-removable-link";
import TagRounded from "../../../../../../packages/design-system/tag/examples/constellation/tag-rounded";
import TagRoundedLink from "../../../../../../packages/design-system/tag/examples/constellation/tag-rounded-link";
import TagColors from "../../../../../../packages/design-system/tag/examples/constellation/tag-colors";
import TagMaxLength from "../../../../../../packages/design-system/tag/examples/constellation/tag-max-length";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_default from "!!raw-loader!../../../../../../packages/design-system/tag/examples/constellation/tag-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_link from "!!raw-loader!../../../../../../packages/design-system/tag/examples/constellation/tag-link";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_removable from "!!raw-loader!../../../../../../packages/design-system/tag/examples/constellation/tag-removable";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_removable_link from "!!raw-loader!../../../../../../packages/design-system/tag/examples/constellation/tag-removable-link";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_rounded from "!!raw-loader!../../../../../../packages/design-system/tag/examples/constellation/tag-rounded";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_rounded_link from "!!raw-loader!../../../../../../packages/design-system/tag/examples/constellation/tag-rounded-link";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_colors from "!!raw-loader!../../../../../../packages/design-system/tag/examples/constellation/tag-colors";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_max_length from "!!raw-loader!../../../../../../packages/design-system/tag/examples/constellation/tag-max-length";
import * as React from 'react';
export default {
  TagDefault,
  TagLink,
  TagRemovable,
  TagRemovableLink,
  TagRounded,
  TagRoundedLink,
  TagColors,
  TagMaxLength,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_link,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_removable,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_removable_link,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_rounded,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_rounded_link,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_colors,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtag_max_length,
  React
};