import MenuDefault from "../../../../../../packages/design-system/menu/examples/constellation/menu-default";
import MenuStructure from "../../../../../../packages/design-system/menu/examples/constellation/menu-structure";
import ButtonItem from "../../../../../../packages/design-system/menu/examples/constellation/button-item";
import LinkItem from "../../../../../../packages/design-system/menu/examples/constellation/link-item";
import CustomItem from "../../../../../../packages/design-system/menu/examples/constellation/custom-item";
import HeadingItemCustom from "../../../../../../packages/design-system/menu/examples/constellation/heading-item-custom";
import HeadingItem from "../../../../../../packages/design-system/menu/examples/constellation/heading-item";
import HeadingItemLeveling from "../../../../../../packages/design-system/menu/examples/constellation/heading-item-leveling";
import MenuScrollable from "../../../../../../packages/design-system/menu/examples/constellation/menu-scrollable";
import MenuScrollableSection from "../../../../../../packages/design-system/menu/examples/constellation/menu-scrollable-section";
import MenuDensity from "../../../../../../packages/design-system/menu/examples/constellation/menu-density";
import MenuLoading from "../../../../../../packages/design-system/menu/examples/constellation/menu-loading";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_default from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/menu-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_structure from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/menu-structure";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbutton_item from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/button-item";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlink_item from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/link-item";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_item from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/custom-item";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_item_custom from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/heading-item-custom";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_item from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/heading-item";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_item_leveling from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/heading-item-leveling";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_scrollable from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/menu-scrollable";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_scrollable_section from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/menu-scrollable-section";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_density from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/menu-density";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_loading from "!!raw-loader!../../../../../../packages/design-system/menu/examples/constellation/menu-loading";
import * as React from 'react';
export default {
  MenuDefault,
  MenuStructure,
  ButtonItem,
  LinkItem,
  CustomItem,
  HeadingItemCustom,
  HeadingItem,
  HeadingItemLeveling,
  MenuScrollable,
  MenuScrollableSection,
  MenuDensity,
  MenuLoading,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_structure,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbutton_item,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlink_item,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcustom_item,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_item_custom,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_item,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_item_leveling,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_scrollable,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_scrollable_section,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_density,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmenu_loading,
  React
};