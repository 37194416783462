import SectionMessageDefault from "../../../../../../packages/design-system/section-message/examples/constellation/section-message-default";
import SectionMessageWarning from "../../../../../../packages/design-system/section-message/examples/constellation/section-message-warning";
import SectionMessageError from "../../../../../../packages/design-system/section-message/examples/constellation/section-message-error";
import SectionMessageSuccess from "../../../../../../packages/design-system/section-message/examples/constellation/section-message-success";
import SectionMessageDiscovery from "../../../../../../packages/design-system/section-message/examples/constellation/section-message-discovery";
import SectionMessageActions from "../../../../../../packages/design-system/section-message/examples/constellation/section-message-actions";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_default from "!!raw-loader!../../../../../../packages/design-system/section-message/examples/constellation/section-message-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_warning from "!!raw-loader!../../../../../../packages/design-system/section-message/examples/constellation/section-message-warning";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_error from "!!raw-loader!../../../../../../packages/design-system/section-message/examples/constellation/section-message-error";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_success from "!!raw-loader!../../../../../../packages/design-system/section-message/examples/constellation/section-message-success";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_discovery from "!!raw-loader!../../../../../../packages/design-system/section-message/examples/constellation/section-message-discovery";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_actions from "!!raw-loader!../../../../../../packages/design-system/section-message/examples/constellation/section-message-actions";
import * as React from 'react';
export default {
  SectionMessageDefault,
  SectionMessageWarning,
  SectionMessageError,
  SectionMessageSuccess,
  SectionMessageDiscovery,
  SectionMessageActions,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_warning,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_error,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_success,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_discovery,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHsection_message_actions,
  React
};