import AvatarItemBackgroundColor from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-background-color";
import AvatarItemIsDisabled from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-is-disabled";
import AvatarItemPrimaryText from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-primary-text";
import AvatarItemSecondaryText from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-secondary-text";
import AvatarItemText from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-text";
import AvatarItemIsTruncationDisabled from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-is-truncation-disabled";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_background_color from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-background-color";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_is_disabled from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-is-disabled";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_primary_text from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-primary-text";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_secondary_text from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-secondary-text";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_text from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-text";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_is_truncation_disabled from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-item-is-truncation-disabled";
import * as React from 'react';
export default {
  AvatarItemBackgroundColor,
  AvatarItemIsDisabled,
  AvatarItemPrimaryText,
  AvatarItemSecondaryText,
  AvatarItemText,
  AvatarItemIsTruncationDisabled,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_background_color,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_is_disabled,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_primary_text,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_secondary_text,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_text,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_item_is_truncation_disabled,
  React
};