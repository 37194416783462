import React from 'react';

import BaseTable from './components/base-table';
import { COLOR_ROLES } from './table-data';

/**
 * __Color roles table__
 *
 * A color roles table describes the intention behind the color.
 *
 */
const ColorRolesTable = () => <BaseTable tableData={COLOR_ROLES} categoryName="Role" />;

export default ColorRolesTable;
