import ImageDefault from "../../../../../../packages/design-system/image/examples/constellation/image-default";
import ImageThemed from "../../../../../../packages/design-system/image/examples/constellation/image-themed";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHimage_default from "!!raw-loader!../../../../../../packages/design-system/image/examples/constellation/image-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHimage_themed from "!!raw-loader!../../../../../../packages/design-system/image/examples/constellation/image-themed";
import * as React from 'react';
export default {
  ImageDefault,
  ImageThemed,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHimage_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHimage_themed,
  React
};