/**
 * THIS FILE WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::35d3625b04d052c37199113601e6c7ba>>
 * @codegenCommand yarn build tokens
 */
const tokens = [
  {
    "attributes": {
      "group": "raw",
      "state": "active",
      "introduced": "1.20.1",
      "description": "Text transform uppercase token used for backwards compatibility between new and old theming solutions"
    },
    "value": "uppercase",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "raw",
        "state": "active",
        "introduced": "1.20.1",
        "description": "Text transform uppercase token used for backwards compatibility between new and old theming solutions"
      },
      "value": "uppercase"
    },
    "name": "utility.UNSAFE.textTransformUppercase",
    "path": [
      "utility",
      "UNSAFE",
      "textTransformUppercase"
    ],
    "cleanName": "utility.UNSAFE.textTransformUppercase"
  },
  {
    "attributes": {
      "group": "letterSpacing",
      "state": "deprecated",
      "introduced": "0.10.33",
      "description": "Helpful guidance goes here",
      "deprecated": "1.29.0"
    },
    "value": "0",
    "filePath": "schema/themes/atlassian-typography-modernized/letter-spacing.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "letterSpacing",
        "state": "deprecated",
        "introduced": "0.10.33",
        "description": "Helpful guidance goes here",
        "deprecated": "1.29.0"
      },
      "value": "LetterSpacing0"
    },
    "name": "font.letterSpacing.0",
    "path": [
      "font",
      "letterSpacing",
      "0"
    ],
    "cleanName": "font.letterSpacing.0"
  },
  {
    "attributes": {
      "group": "letterSpacing",
      "state": "deprecated",
      "introduced": "0.10.33",
      "description": "Helpful guidance goes here",
      "deprecated": "1.29.0"
    },
    "value": "0",
    "filePath": "schema/themes/atlassian-typography-modernized/letter-spacing.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "letterSpacing",
        "state": "deprecated",
        "introduced": "0.10.33",
        "description": "Helpful guidance goes here",
        "deprecated": "1.29.0"
      },
      "value": "LetterSpacing0"
    },
    "name": "font.letterSpacing.100",
    "path": [
      "font",
      "letterSpacing",
      "100"
    ],
    "cleanName": "font.letterSpacing.100"
  },
  {
    "attributes": {
      "group": "letterSpacing",
      "state": "deprecated",
      "introduced": "0.10.33",
      "description": "Helpful guidance goes here",
      "deprecated": "1.29.0"
    },
    "value": "0",
    "filePath": "schema/themes/atlassian-typography-modernized/letter-spacing.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "letterSpacing",
        "state": "deprecated",
        "introduced": "0.10.33",
        "description": "Helpful guidance goes here",
        "deprecated": "1.29.0"
      },
      "value": "LetterSpacing0"
    },
    "name": "font.letterSpacing.200",
    "path": [
      "font",
      "letterSpacing",
      "200"
    ],
    "cleanName": "font.letterSpacing.200"
  },
  {
    "attributes": {
      "group": "letterSpacing",
      "state": "deprecated",
      "introduced": "0.10.33",
      "description": "Helpful guidance goes here",
      "deprecated": "1.29.0"
    },
    "value": "0",
    "filePath": "schema/themes/atlassian-typography-modernized/letter-spacing.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "letterSpacing",
        "state": "deprecated",
        "introduced": "0.10.33",
        "description": "Helpful guidance goes here",
        "deprecated": "1.29.0"
      },
      "value": "LetterSpacing0"
    },
    "name": "font.letterSpacing.300",
    "path": [
      "font",
      "letterSpacing",
      "300"
    ],
    "cleanName": "font.letterSpacing.300"
  },
  {
    "attributes": {
      "group": "letterSpacing",
      "state": "deprecated",
      "introduced": "0.10.33",
      "description": "Helpful guidance goes here",
      "deprecated": "1.29.0"
    },
    "value": "0",
    "filePath": "schema/themes/atlassian-typography-modernized/letter-spacing.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "letterSpacing",
        "state": "deprecated",
        "introduced": "0.10.33",
        "description": "Helpful guidance goes here",
        "deprecated": "1.29.0"
      },
      "value": "LetterSpacing0"
    },
    "name": "font.letterSpacing.400",
    "path": [
      "font",
      "letterSpacing",
      "400"
    ],
    "cleanName": "font.letterSpacing.400"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For overlapping brand with product promotions, such as marketplace content. Migrate instances of H900 to Heading XXL."
    },
    "value": "normal 700 32px/36px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For overlapping brand with product promotions, such as marketplace content. Migrate instances of H900 to Heading XXL."
      },
      "value": {
        "fontWeight": "FontWeight700",
        "fontSize": "FontSize32",
        "lineHeight": "LineHeight36",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.heading.xxlarge",
    "path": [
      "font",
      "heading",
      "xxlarge"
    ],
    "cleanName": "font.heading.xxlarge"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For overlapping brand with product promotions, such as marketplace content. Migrate instances of H800 to Heading XL."
    },
    "value": "normal 700 28px/32px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For overlapping brand with product promotions, such as marketplace content. Migrate instances of H800 to Heading XL."
      },
      "value": {
        "fontWeight": "FontWeight700",
        "fontSize": "FontSize28",
        "lineHeight": "LineHeight32",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.heading.xlarge",
    "path": [
      "font",
      "heading",
      "xlarge"
    ],
    "cleanName": "font.heading.xlarge"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "Product page titles, such as forms. Migrate instances of H700 to Heading L."
    },
    "value": "normal 700 24px/28px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "Product page titles, such as forms. Migrate instances of H700 to Heading L."
      },
      "value": {
        "fontWeight": "FontWeight700",
        "fontSize": "FontSize24",
        "lineHeight": "LineHeight28",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.heading.large",
    "path": [
      "font",
      "heading",
      "large"
    ],
    "cleanName": "font.heading.large"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "Headers in large components, such as modal dialogs. Migrate instances of H600 to Heading M."
    },
    "value": "normal 700 20px/24px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "Headers in large components, such as modal dialogs. Migrate instances of H600 to Heading M."
      },
      "value": {
        "fontWeight": "FontWeight700",
        "fontSize": "FontSize20",
        "lineHeight": "LineHeight24",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.heading.medium",
    "path": [
      "font",
      "heading",
      "medium"
    ],
    "cleanName": "font.heading.medium"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For headers in small components where space is limited. Migrate instances of H500 to Heading S."
    },
    "value": "normal 700 16px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For headers in small components where space is limited. Migrate instances of H500 to Heading S."
      },
      "value": {
        "fontWeight": "FontWeight700",
        "fontSize": "FontSize16",
        "lineHeight": "LineHeight20",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.heading.small",
    "path": [
      "font",
      "heading",
      "small"
    ],
    "cleanName": "font.heading.small"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For headers in small components where space is limited. Migrate instances of H400 to Heading XS."
    },
    "value": "normal 700 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For headers in small components where space is limited. Migrate instances of H400 to Heading XS."
      },
      "value": {
        "fontWeight": "FontWeight700",
        "fontSize": "FontSize14",
        "lineHeight": "LineHeight20",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.heading.xsmall",
    "path": [
      "font",
      "heading",
      "xsmall"
    ],
    "cleanName": "font.heading.xsmall"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For headers in fine print or tight spaces. Use sparingly. Migrate instances of H100, H200 and H300 to Heading XXS."
    },
    "value": "normal 700 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For headers in fine print or tight spaces. Use sparingly. Migrate instances of H100, H200 and H300 to Heading XXS."
      },
      "value": {
        "fontWeight": "FontWeight700",
        "fontSize": "FontSize12",
        "lineHeight": "LineHeight16",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.heading.xxsmall",
    "path": [
      "font",
      "heading",
      "xxsmall"
    ],
    "cleanName": "font.heading.xxsmall"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For long-form text, such as in blogs."
    },
    "value": "normal 400 16px/24px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For long-form text, such as in blogs."
      },
      "value": {
        "fontWeight": "FontWeight400",
        "fontSize": "FontSize16",
        "lineHeight": "LineHeight24",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.body.large",
    "path": [
      "font",
      "body",
      "large"
    ],
    "cleanName": "font.body.large"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "Use in short descriptions or labels. The default size for text in components. Migrate instances of Paragraph Default and UI Text to Body M."
    },
    "value": "normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "Use in short descriptions or labels. The default size for text in components. Migrate instances of Paragraph Default and UI Text to Body M."
      },
      "value": {
        "fontWeight": "FontWeight400",
        "fontSize": "FontSize14",
        "lineHeight": "LineHeight20",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.body.[default]",
    "path": [
      "font",
      "body",
      "[default]"
    ],
    "cleanName": "font.body"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "Use in secondary level content such as fine print or semantic messaging. Use sparingly. Migrate instances of Small text to Body S."
    },
    "value": "normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "Use in secondary level content such as fine print or semantic messaging. Use sparingly. Migrate instances of Small text to Body S."
      },
      "value": {
        "fontWeight": "FontWeight400",
        "fontSize": "FontSize12",
        "lineHeight": "LineHeight16",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.body.small",
    "path": [
      "font",
      "body",
      "small"
    ],
    "cleanName": "font.body.small"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "UNSAFE - Do not use. Temporary 12px fontsize token. Will be deprecated and replaced with stable small token"
    },
    "value": "normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "UNSAFE - Do not use. Temporary 12px fontsize token. Will be deprecated and replaced with stable small token"
      },
      "value": {
        "fontWeight": "FontWeight400",
        "fontSize": "FontSize12",
        "lineHeight": "LineHeight16",
        "fontFamily": "FontFamilyWebSans",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.body.UNSAFE_small",
    "path": [
      "font",
      "body",
      "UNSAFE_small"
    ],
    "cleanName": "font.body.UNSAFE_small"
  },
  {
    "attributes": {
      "group": "typography",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For representing code only, either inline or in code blocks."
    },
    "value": "normal 400 0.875em/1 ui-monospace, Menlo, \"Segoe UI Mono\", \"Ubuntu Mono\", monospace",
    "filePath": "schema/themes/atlassian-typography-modernized/theme.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "typography",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For representing code only, either inline or in code blocks."
      },
      "value": {
        "fontWeight": "FontWeight400",
        "fontSize": "FontSizeCode",
        "lineHeight": "LineHeight1",
        "fontFamily": "FontFamilyWebMono",
        "fontStyle": "normal",
        "letterSpacing": "LetterSpacing0"
      }
    },
    "name": "font.code.[default]",
    "path": [
      "font",
      "code",
      "[default]"
    ],
    "cleanName": "font.code"
  },
  {
    "attributes": {
      "group": "fontWeight",
      "state": "active",
      "introduced": "0.10.33",
      "description": "Default font weight for body text styles."
    },
    "value": "400",
    "filePath": "schema/themes/atlassian-typography-modernized/font-weight.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontWeight",
        "state": "active",
        "introduced": "0.10.33",
        "description": "Default font weight for body text styles."
      },
      "value": "FontWeight400"
    },
    "name": "font.weight.regular",
    "path": [
      "font",
      "weight",
      "regular"
    ],
    "cleanName": "font.weight.regular"
  },
  {
    "attributes": {
      "group": "fontWeight",
      "state": "active",
      "introduced": "0.10.33",
      "description": "For all text that may appear beside iconography, such as in a button."
    },
    "value": "500",
    "filePath": "schema/themes/atlassian-typography-modernized/font-weight.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontWeight",
        "state": "active",
        "introduced": "0.10.33",
        "description": "For all text that may appear beside iconography, such as in a button."
      },
      "value": "FontWeight500"
    },
    "name": "font.weight.medium",
    "path": [
      "font",
      "weight",
      "medium"
    ],
    "cleanName": "font.weight.medium"
  },
  {
    "attributes": {
      "group": "fontWeight",
      "state": "active",
      "introduced": "0.10.33",
      "description": "Use with caution as fallback fonts do not support this font weight and will default to bold weight."
    },
    "value": "600",
    "filePath": "schema/themes/atlassian-typography-modernized/font-weight.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontWeight",
        "state": "active",
        "introduced": "0.10.33",
        "description": "Use with caution as fallback fonts do not support this font weight and will default to bold weight."
      },
      "value": "FontWeight600"
    },
    "name": "font.weight.semibold",
    "path": [
      "font",
      "weight",
      "semibold"
    ],
    "cleanName": "font.weight.semibold"
  },
  {
    "attributes": {
      "group": "fontWeight",
      "state": "active",
      "introduced": "0.10.33",
      "description": "Use sparingly for emphasising text, such as in a lozenge."
    },
    "value": "700",
    "filePath": "schema/themes/atlassian-typography-modernized/font-weight.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontWeight",
        "state": "active",
        "introduced": "0.10.33",
        "description": "Use sparingly for emphasising text, such as in a lozenge."
      },
      "value": "FontWeight700"
    },
    "name": "font.weight.bold",
    "path": [
      "font",
      "weight",
      "bold"
    ],
    "cleanName": "font.weight.bold"
  },
  {
    "attributes": {
      "group": "fontFamily",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For our default product UI heading text."
    },
    "value": "ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/font-family.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontFamily",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For our default product UI heading text."
      },
      "value": "FontFamilyWebSans"
    },
    "name": "font.family.heading",
    "path": [
      "font",
      "family",
      "heading"
    ],
    "cleanName": "font.family.heading"
  },
  {
    "attributes": {
      "group": "fontFamily",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For our default product UI body text."
    },
    "value": "ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/font-family.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontFamily",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For our default product UI body text."
      },
      "value": "FontFamilyWebSans"
    },
    "name": "font.family.body",
    "path": [
      "font",
      "family",
      "body"
    ],
    "cleanName": "font.family.body"
  },
  {
    "attributes": {
      "group": "fontFamily",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For representing code only."
    },
    "value": "ui-monospace, Menlo, \"Segoe UI Mono\", \"Ubuntu Mono\", monospace",
    "filePath": "schema/themes/atlassian-typography-modernized/font-family.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontFamily",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For representing code only."
      },
      "value": "FontFamilyWebMono"
    },
    "name": "font.family.code",
    "path": [
      "font",
      "family",
      "code"
    ],
    "cleanName": "font.family.code"
  },
  {
    "attributes": {
      "group": "fontFamily",
      "state": "deprecated",
      "introduced": "0.10.33",
      "description": "For representing code only.",
      "deprecated": "1.29.0"
    },
    "value": "ui-monospace, Menlo, \"Segoe UI Mono\", \"Ubuntu Mono\", monospace",
    "filePath": "schema/themes/atlassian-typography-modernized/font-family.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontFamily",
        "state": "deprecated",
        "introduced": "0.10.33",
        "description": "For representing code only.",
        "deprecated": "1.29.0"
      },
      "value": "FontFamilyWebMono"
    },
    "name": "font.family.monospace",
    "path": [
      "font",
      "family",
      "monospace"
    ],
    "cleanName": "font.family.monospace"
  },
  {
    "attributes": {
      "group": "fontFamily",
      "state": "deprecated",
      "introduced": "0.10.33",
      "description": "Helpful guidance goes here",
      "deprecated": "1.29.0"
    },
    "value": "ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/font-family.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontFamily",
        "state": "deprecated",
        "introduced": "0.10.33",
        "description": "Helpful guidance goes here",
        "deprecated": "1.29.0"
      },
      "value": "FontFamilyWebSans"
    },
    "name": "font.family.sans",
    "path": [
      "font",
      "family",
      "sans"
    ],
    "cleanName": "font.family.sans"
  },
  {
    "attributes": {
      "group": "fontFamily",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For our brand heading text. Uses Charlie Display."
    },
    "value": "\"Charlie Display\", ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/font-family.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontFamily",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For our brand heading text. Uses Charlie Display."
      },
      "value": "FontFamilyCharlieDisplay"
    },
    "name": "font.family.brand.heading",
    "path": [
      "font",
      "family",
      "brand",
      "heading"
    ],
    "cleanName": "font.family.brand.heading"
  },
  {
    "attributes": {
      "group": "fontFamily",
      "state": "active",
      "introduced": "1.14.0",
      "description": "For our brand body text. Uses Charlie Text."
    },
    "value": "\"Charlie Text\", ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif",
    "filePath": "schema/themes/atlassian-typography-modernized/font-family.tsx",
    "isSource": true,
    "original": {
      "attributes": {
        "group": "fontFamily",
        "state": "active",
        "introduced": "1.14.0",
        "description": "For our brand body text. Uses Charlie Text."
      },
      "value": "FontFamilyCharlieText"
    },
    "name": "font.family.brand.body",
    "path": [
      "font",
      "family",
      "brand",
      "body"
    ],
    "cleanName": "font.family.brand.body"
  }
];

export default tokens;
