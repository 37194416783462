import React from 'react';

import { Code } from '@atlaskit/code';
import { Text } from '@atlaskit/primitives';
import { Table, TBody, TD, TH, THead, TR } from '@atlaskit/table/primitives';

import HorizontalScrollContainer from '../horizontal-scroll-container';
import CollapsingTopMargin from '../utils/collapsing-top-margin';

interface Breakpoint {
	name: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';
	viewport: string;
	columns: number;
	gutters: string;
	margins: string;
}
const breakpoints: Breakpoint[] = [
	{
		name: 'xxs',
		viewport: '320 - 479px',
		columns: 2,
		gutters: '12px',
		margins: '16px',
	},
	{
		name: 'xs',
		viewport: '480 - 767px',
		columns: 6,
		gutters: '12px',
		margins: '16px',
	},
	{
		name: 's',
		viewport: '768 - 1023px',
		columns: 6,
		gutters: '12px',
		margins: '16px',
	},
	{
		name: 'm',
		viewport: '1024 - 1439px',
		columns: 12,
		gutters: '16px',
		margins: '32px',
	},
	{
		name: 'l',
		viewport: '1440 - 1767px',
		columns: 12,
		gutters: '16px',
		margins: '32px',
	},
	{
		name: 'xl',
		viewport: '1768+px',
		columns: 12,
		gutters: '16px',
		margins: '32px',
	},
];

/**
 * __Breakpoints table__
 */
export const BreakpointsTable = () => {
	return (
		<CollapsingTopMargin>
			<HorizontalScrollContainer>
				<Table>
					<THead>
						<TR isBodyRow={false}>
							<TH>Breakpoint</TH>
							<TH>Viewport</TH>
							<TH>Columns</TH>
							<TH>Gutters</TH>
							<TH>Margins</TH>
						</TR>
					</THead>
					<TBody>
						{breakpoints.map((breakpoint) => {
							return (
								<TR key={breakpoint.name}>
									<TD>
										<Code>{breakpoint.name}</Code>
									</TD>
									<TD>
										<Text>{breakpoint.viewport}</Text>
									</TD>
									<TD>
										<Text>{breakpoint.columns}</Text>
									</TD>
									<TD>
										<Text>{breakpoint.gutters}</Text>
									</TD>
									<TD>
										<Text>{breakpoint.margins}</Text>
									</TD>
								</TR>
							);
						})}
					</TBody>
				</Table>
			</HorizontalScrollContainer>
		</CollapsingTopMargin>
	);
};
