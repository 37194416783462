import React from 'react';

import BaseTable from './components/base-table';
import { SEMANTIC_CHART_TOKENS } from './table-data';

/**
 * __Semantic chart tokens table__
 *
 * A semantic chart tokens table lists all the semantic chart tokens and their descriptions.
 *
 */
const SemanticChartTokensTable = () => (
	<BaseTable categoryName="Token" tableData={SEMANTIC_CHART_TOKENS} />
);
export default SemanticChartTokensTable;
