/* eslint-disable @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard */
/* eslint-disable @atlaskit/design-system/no-styled-tagged-template-expression -- needs manual remediation */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

const GRADIENT_PERCENTAGE = 75;

export type ExampleCodeProps = {
	// TODO: Fix this
	// eslint-disable-next-line @repo/internal/react/boolean-prop-naming-convention
	collapsed: boolean;
	hintHeight?: number;
};

/**
 * __Example code__
 *
 * An example code shows the code that  was used to render and example.
 *
 */
const ExampleCode = styled.div<ExampleCodeProps>`
	background-color: ${token('color.background.neutral')};
	position: relative;
	z-index: 0;

	code {
		padding: ${token('space.300', '24px')} ${token('space.300', '24px')} !important;
		white-space: pre-wrap !important;
	}

	[id^='example-'] {
		span {
			background-color: ${token('color.background.neutral.subtle')};
		}

		--mask: linear-gradient(
			to bottom,
			${token('color.background.neutral.bold')} ${100 - GRADIENT_PERCENTAGE}%,
			transparent
		);
		mask: ${(p) => (p.collapsed ? 'var(--mask)' : 'none')};
		position: relative;
		max-height: ${(p) => (p.collapsed ? `${p.hintHeight}px` : 'none')};
		overflow: hidden;
		box-sizing: border-box;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export default ExampleCode;
