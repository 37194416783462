import { type Results } from './types';
const results: Results = {
	'text/default_resultNode': [
		{
			name: 'color.text',
		},
	],
	'text/neutral/subtle_resultNode': [
		{
			name: 'color.text.subtle',
		},
	],
	'text/neutral/subtlest_resultNode': [
		{
			name: 'color.text.subtlest',
		},
	],
	'text/neutral/disabled_resultNode': [
		{
			name: 'color.text.disabled',
		},
	],
	'text/neutral/on-bold_resultNode': [
		{
			name: 'color.text.inverse',
		},
		{
			name: 'color.text.warning.inverse',
		},
	],
	'text/colored/accent/gray_resultNode': [
		{
			name: 'color.text.accent.gray',
		},
		{
			name: 'color.text.accent.gray.bolder',
		},
	],
	'text/link/default_resultNode': [
		{
			name: 'color.link',
		},
		{
			name: 'color.link.visited',
		},
	],
	'text/link/subtle_resultNode': [
		{
			name: 'color.text.subtle',
		},
	],
	'text/colored/brand_resultNode': [
		{
			name: 'color.text.brand',
		},
	],
	'text/colored/information_resultNode': [
		{
			name: 'color.text.information',
		},
	],
	'text/colored/success_resultNode': [
		{
			name: 'color.text.success',
		},
	],
	'text/colored/warning_resultNode': [
		{
			name: 'color.text.warning',
		},
	],
	'text/colored/danger_resultNode': [
		{
			name: 'color.text.danger',
		},
	],
	'text/colored/discovery_resultNode': [
		{
			name: 'color.text.discovery',
		},
	],
	'text/colored/selected_resultNode': [
		{
			name: 'color.text.selected',
		},
	],
	'text/colored/accent/blue_resultNode': [
		{
			name: 'color.text.accent.blue',
		},
		{
			name: 'color.text.accent.blue.bolder',
		},
	],
	'text/colored/accent/teal_resultNode': [
		{
			name: 'color.text.accent.teal',
		},
		{
			name: 'color.text.accent.teal.bolder',
		},
	],
	'text/colored/accent/green_resultNode': [
		{
			name: 'color.text.accent.green',
		},
		{
			name: 'color.text.accent.green.bolder',
		},
	],
	'text/colored/accent/yellow_resultNode': [
		{
			name: 'color.text.accent.yellow',
		},
		{
			name: 'color.text.accent.yellow.bolder',
		},
	],
	'text/colored/accent/orange_resultNode': [
		{
			name: 'color.text.accent.orange',
		},
		{
			name: 'color.text.accent.orange.bolder',
		},
	],
	'text/colored/accent/red_resultNode': [
		{
			name: 'color.text.accent.red',
		},
		{
			name: 'color.text.accent.red.bolder',
		},
	],
	'text/colored/accent/magenta_resultNode': [
		{
			name: 'color.text.accent.magenta',
		},
		{
			name: 'color.text.accent.magenta.bolder',
		},
	],
	'text/colored/accent/lime_resultNode': [
		{
			name: 'color.text.accent.lime',
		},
		{
			name: 'color.text.accent.lime.bolder',
		},
	],
	'text/colored/accent/purple_resultNode': [
		{
			name: 'color.text.accent.purple',
		},
		{
			name: 'color.text.accent.purple.bolder',
		},
	],
	'background-surface/surface/main-background_resultNode': [
		{
			name: 'elevation.surface',
			hints: [''],
		},
	],
	'background-surface/surface/modal-dropdown_resultNode': [
		{
			name: 'elevation.surface.overlay',
			hints: ['Pair this elevation with `shadow.overlay`'],
		},
	],
	'background-surface/surface/card_resultNode': [
		{
			name: 'elevation.surface.raised',
			hints: ['Pair this elevation with `shadow.raised`'],
		},
	],
	'background-surface/surface/grouping_resultNode': [
		{
			name: 'elevation.surface.sunken',
			hints: [
				'Use elevation.surface.sunken on top of the elevation.surface',
				'You can also use color.border + no background to group content together',
			],
		},
	],
	'background-surface/background/colored/brand_resultNode': [
		{
			name: 'color.background.brand.subtlest',
			pairings: [
				{
					background: 'color.background.brand.subtlest',
					icon: 'color.icon',
					text: 'color.text',
					border: 'color.border',
				},
			],
		},
		{
			name: 'color.background.brand.bold',
			pairings: [
				{
					background: 'color.background.brand.bold',
					icon: 'color.icon.inverse',
					text: 'color.text.inverse',
					border: 'color.border',
				},
			],
		},
		{
			name: 'color.background.brand.boldest',
			pairings: [
				{
					background: 'color.background.brand.boldest',
					icon: 'color.icon.inverse',
					text: 'color.text.inverse',
					border: 'color.border',
				},
			],
		},
	],
	'background-surface/background/colored/information_resultNode': [
		{
			name: 'color.background.information',
			pairings: [
				{
					background: 'color.background.information',
					icon: 'color.icon.information',
					text: 'color.text',
					border: 'color.border.information',
				},
			],
		},
		{
			name: 'color.background.information.bold',
			pairings: [
				{
					background: 'color.background.information.bold',
					icon: 'color.icon.inverse',
					text: 'color.text.inverse',
					border: 'color.border.information',
				},
			],
		},
	],
	'background-surface/background/colored/success_resultNode': [
		{
			name: 'color.background.success',
			pairings: [
				{
					background: 'color.background.success',
					icon: 'color.icon.success',
					text: 'color.text',
					border: 'color.border.success',
				},
			],
		},
		{
			name: 'color.background.success.bold',
			pairings: [
				{
					background: 'color.background.success.bold',
					icon: 'color.icon.inverse',
					text: 'color.text.inverse',
					border: 'color.border.success',
				},
			],
		},
	],
	'background-surface/background/colored/warning_resultNode': [
		{
			name: 'color.background.warning',
			pairings: [
				{
					background: 'color.background.warning',
					icon: 'color.icon.warning',
					text: 'color.text',
					border: 'color.border.warning',
				},
			],
		},
		{
			name: 'color.background.warning.bold',
			pairings: [
				{
					background: 'color.background.warning.bold',
					icon: 'color.icon.inverse',
					text: 'color.text.inverse',
					border: 'color.border.warning',
				},
			],
		},
	],
	'background-surface/background/colored/danger_resultNode': [
		{
			name: 'color.background.danger',
			pairings: [
				{
					background: 'color.background.danger',
					icon: 'color.icon.danger',
					text: 'color.text',
					border: 'color.border.danger',
				},
			],
		},
		{
			name: 'color.background.danger.bold',
			pairings: [
				{
					background: 'color.background.danger.bold',
					icon: 'color.icon.inverse',
					text: 'color.text.inverse',
					border: 'color.border.danger',
				},
			],
		},
	],
	'background-surface/background/colored/discovery_resultNode': [
		{
			name: 'color.background.discovery',
			pairings: [
				{
					background: 'color.background.discovery',
					icon: 'color.icon.discovery',
					text: 'color.text',
					border: 'color.border.discovery',
				},
			],
		},
		{
			name: 'color.background.discovery.bold',
			pairings: [
				{
					background: 'color.background.discovery.bold',
					icon: 'color.icon.inverse',
					text: 'color.text.inverse',
					border: 'color.border.discovery',
				},
			],
		},
	],
	'background-surface/background/colored/selected_resultNode': [
		{
			name: 'color.background.selected',
			pairings: [
				{
					background: 'color.background.selected',
					icon: 'color.icon.selected',
					text: 'color.text',
					border: 'color.border.selected',
				},
			],
		},
		{
			name: 'color.background.selected.bold',
			pairings: [
				{
					background: 'color.background.selected.bold',
					icon: 'color.icon.inverse',
					text: 'color.text.inverse',
					border: 'color.border.selected',
				},
			],
		},
	],
	'background-surface/background/colored/accent/blue_resultNode': [
		{
			name: 'color.background.accent.blue.subtlest',
		},
		{
			name: 'color.background.accent.blue.subtler',
		},
		{
			name: 'color.background.accent.blue.subtle',
		},
		{
			name: 'color.background.accent.blue.bolder',
		},
	],
	'background-surface/background/colored/accent/teal_resultNode': [
		{
			name: 'color.background.accent.teal.subtlest',
		},
		{
			name: 'color.background.accent.teal.subtler',
		},
		{
			name: 'color.background.accent.teal.subtle',
		},
		{
			name: 'color.background.accent.teal.bolder',
		},
	],
	'background-surface/background/colored/accent/green_resultNode': [
		{
			name: 'color.background.accent.green.subtlest',
		},
		{
			name: 'color.background.accent.green.subtler',
		},
		{
			name: 'color.background.accent.green.subtle',
		},
		{
			name: 'color.background.accent.green.bolder',
		},
	],
	'background-surface/background/colored/accent/yellow_resultNode': [
		{
			name: 'color.background.accent.yellow.subtlest',
		},
		{
			name: 'color.background.accent.yellow.subtler',
		},
		{
			name: 'color.background.accent.yellow.subtle',
		},
		{
			name: 'color.background.accent.yellow.bolder',
		},
	],
	'background-surface/background/colored/accent/orange_resultNode': [
		{
			name: 'color.background.accent.orange.subtlest',
		},
		{
			name: 'color.background.accent.orange.subtler',
		},
		{
			name: 'color.background.accent.orange.subtle',
		},
		{
			name: 'color.background.accent.orange.bolder',
		},
	],
	'background-surface/background/colored/accent/red_resultNode': [
		{
			name: 'color.background.accent.red.subtlest',
		},
		{
			name: 'color.background.accent.red.subtler',
		},
		{
			name: 'color.background.accent.red.subtle',
		},
		{
			name: 'color.background.accent.red.bolder',
		},
	],
	'background-surface/background/colored/accent/magenta_resultNode': [
		{
			name: 'color.background.accent.magenta.subtlest',
		},
		{
			name: 'color.background.accent.magenta.subtler',
		},
		{
			name: 'color.background.accent.magenta.subtle',
		},
		{
			name: 'color.background.accent.magenta.bolder',
		},
	],
	'background-surface/background/colored/accent/lime_resultNode': [
		{
			name: 'color.background.accent.lime.subtlest',
		},
		{
			name: 'color.background.accent.lime.subtler',
		},
		{
			name: 'color.background.accent.lime.subtle',
		},
		{
			name: 'color.background.accent.lime.bolder',
		},
	],
	'background-surface/background/colored/accent/purple_resultNode': [
		{
			name: 'color.background.accent.purple.subtlest',
		},
		{
			name: 'color.background.accent.purple.subtler',
		},
		{
			name: 'color.background.accent.purple.subtle',
		},
		{
			name: 'color.background.accent.purple.bolder',
		},
	],
	'background-surface/background/colored/accent/gray_resultNode': [
		{
			name: 'color.background.accent.gray.subtlest',
		},
		{
			name: 'color.background.accent.gray.subtler',
		},
		{
			name: 'color.background.accent.gray.subtle',
		},
		{
			name: 'color.background.accent.gray.bolder',
		},
	],
	'background-surface/background/neutral/input_resultNode': [
		{
			name: 'color.background.input',
		},
	],
	'background-surface/background/neutral/disabled_resultNode': [
		{
			name: 'color.background.disabled',
		},
	],
	'background-surface/background/neutral/neutral_resultNode': [
		{
			name: 'color.background.neutral',
			pairings: [
				{
					text: 'color.text',
					border: 'color.border',
					icon: 'color.icon',
					background: 'color.background.neutral',
				},
			],
		},
	],
	'background-surface/background/neutral/subtle_resultNode': [
		{
			name: 'color.background.neutral.subtle',
			hints: [
				'Use color.background.neutral.subtle for the resting state; though transparent it allows for fade animation',
			],
			pairings: [
				{
					text: 'color.text',
					border: 'color.border',
					icon: 'color.icon',
					background: 'color.background.neutral.subtle',
				},
			],
		},
	],
	'background-surface/background/neutral/vibrant_resultNode': [
		{
			name: 'color.background.neutral.bold',
			pairings: [
				{
					text: 'color.text.inverse',
					border: 'color.border',
					icon: 'color.icon.inverse',
					background: 'color.background.neutral.bold',
				},
			],
		},
	],
	'background-surface/background/neutral/on-bold_resultNode': [
		{
			name: 'color.background.inverse.subtle',
		},
	],
	'shadow/raised_resultNode': [
		{
			name: 'elevation.shadow.raised',
			hints: ['Make sure to pair this shadow with the matching surface'],
		},
	],
	'shadow/overlay_resultNode': [
		{
			name: 'elevation.shadow.overlay',
			hints: ['Make sure to pair this shadow with the matching surface'],
		},
	],
	'shadow/overflow_resultNode': [
		{
			name: 'elevation.shadow.overflow',
		},
		{
			name: 'elevation.shadow.overflow.spread',
		},
		{
			name: 'elevation.shadow.overflow.perimeter',
		},
	],
	'border/radius/indicator_resultNode': [
		{
			name: 'border.radius.050',
		},
	],
	'border/radius/default_resultNode': [
		{
			name: 'border.radius',
		},
		{
			name: 'border.radius.100',
		},
	],
	'border/radius/card_resultNode': [
		{
			name: 'border.radius.200',
		},
		{
			name: 'border.radius.300',
		},
		{
			name: 'border.radius.400',
		},
	],
	'border/radius/circle_resultNode': [
		{
			name: 'border.radius.circle',
		},
	],
	'border/color/colored/brand_resultNode': [
		{
			name: 'color.border.brand',
		},
	],
	'border/color/colored/information_resultNode': [
		{
			name: 'color.border.information',
		},
	],
	'border/color/colored/success_resultNode': [
		{
			name: 'color.border.success',
		},
	],
	'border/color/colored/warning_resultNode': [
		{
			name: 'color.border.warning',
		},
	],
	'border/color/colored/danger_resultNode': [
		{
			name: 'color.border.danger',
		},
	],
	'border/color/colored/discovery_resultNode': [
		{
			name: 'color.border.discovery',
		},
	],
	'border/color/colored/focused_resultNode': [
		{
			name: 'color.border.focused',
		},
	],
	'border/color/colored/selected_resultNode': [
		{
			name: 'color.border.selected',
		},
	],
	'border/color/colored/blue_resultNode': [
		{
			name: 'color.border.accent.blue',
		},
	],
	'border/color/colored/teal_resultNode': [
		{
			name: 'color.border.accent.teal',
		},
	],
	'border/color/colored/green_resultNode': [
		{
			name: 'color.border.accent.green',
		},
	],
	'border/color/colored/yellow_resultNode': [
		{
			name: 'color.border.accent.yellow',
		},
	],
	'border/color/colored/orange_resultNode': [
		{
			name: 'color.border.accent.orange',
		},
	],
	'border/color/colored/red_resultNode': [
		{
			name: 'color.border.accent.red',
		},
	],
	'border/color/colored/magenta_resultNode': [
		{
			name: 'color.border.accent.magenta',
		},
	],
	'border/color/colored/lime_resultNode': [
		{
			name: 'color.border.accent.lime',
		},
	],
	'border/color/colored/purple_resultNode': [
		{
			name: 'color.border.accent.purple',
		},
	],
	'border/color/colored/gray_resultNode': [
		{
			name: 'color.border.accent.gray',
		},
	],
	'border/color/neutral/input_resultNode': [
		{
			name: 'color.border.input',
		},
	],
	'border/color/neutral/disabled_resultNode': [
		{
			name: 'color.border.disabled',
		},
	],
	'border/color/neutral/neutral_resultNode': [
		{
			name: 'color.border',
		},
		{
			name: 'color.border.bold',
		},
		{
			name: 'color.border.inverse',
		},
	],
	'icon/colored/brand_resultNode': [
		{
			name: 'color.icon.brand',
		},
	],
	'icon/colored/information_resultNode': [
		{
			name: 'color.icon.information',
		},
	],
	'icon/colored/success_resultNode': [
		{
			name: 'color.icon.success',
		},
	],
	'icon/colored/warning_resultNode': [
		{
			name: 'color.icon.warning',
		},
	],
	'icon/colored/danger_resultNode': [
		{
			name: 'color.icon.danger',
		},
	],
	'icon/colored/discovery_resultNode': [
		{
			name: 'color.icon.discovery',
		},
	],
	'icon/colored/selected_resultNode': [
		{
			name: 'color.icon.selected',
		},
	],
	'icon/colored/blue_resultNode': [
		{
			name: 'color.icon.accent.blue',
		},
	],
	'icon/colored/teal_resultNode': [
		{
			name: 'color.icon.accent.teal',
		},
	],
	'icon/colored/green_resultNode': [
		{
			name: 'color.icon.accent.green',
		},
	],
	'icon/colored/yellow_resultNode': [
		{
			name: 'color.icon.accent.yellow',
		},
	],
	'icon/colored/orange_resultNode': [
		{
			name: 'color.icon.accent.orange',
		},
	],
	'icon/colored/red_resultNode': [
		{
			name: 'color.icon.accent.red',
		},
	],
	'icon/colored/magenta_resultNode': [
		{
			name: 'color.icon.accent.magenta',
		},
	],
	'icon/colored/lime_resultNode': [
		{
			name: 'color.icon.accent.lime',
		},
	],
	'icon/colored/purple_resultNode': [
		{
			name: 'color.icon.accent.purple',
		},
	],
	'icon/colored/gray_resultNode': [
		{
			name: 'color.icon.accent.gray',
		},
	],
	'icon/neutral/default_resultNode': [
		{
			name: 'color.icon',
		},
	],
	'icon/neutral/subtle_resultNode': [
		{
			name: 'color.icon.subtle',
		},
	],
	'icon/neutral/on-bold_resultNode': [
		{
			name: 'color.icon.inverse',
		},
		{
			name: 'color.icon.warning.inverse',
			hints: [
				'Ensure that `color.icon.warning.inverse` is used when the icon is on a warning background.',
			],
		},
	],
	'icon/neutral/disabled_resultNode': [
		{
			name: 'color.icon.disabled',
		},
	],
	'data-visualisation/end-user_resultNode': [
		{
			name: 'color.chart.green.bold',
		},
		{
			name: 'color.chart.green.bolder',
		},
		{
			name: 'color.chart.green.boldest',
		},
		{
			name: 'color.chart.red.bold',
		},
		{
			name: 'color.chart.red.bolder',
		},
		{
			name: 'color.chart.red.boldest',
		},
		{
			name: 'color.chart.blue.bold',
		},
		{
			name: 'color.chart.blue.bolder',
		},
		{
			name: 'color.chart.blue.boldest',
		},
		{
			name: 'color.chart.purple.bold',
		},
		{
			name: 'color.chart.purple.bolder',
		},
		{
			name: 'color.chart.purple.boldest',
		},
		{
			name: 'color.chart.teal.bold',
		},
		{
			name: 'color.chart.teal.bolder',
		},
		{
			name: 'color.chart.teal.boldest',
		},
		{
			name: 'color.chart.orange.bold',
		},
		{
			name: 'color.chart.orange.bolder',
		},
		{
			name: 'color.chart.orange.boldest',
		},
		{
			name: 'color.chart.yellow.bold',
		},
		{
			name: 'color.chart.yellow.bolder',
		},
		{
			name: 'color.chart.yellow.boldest',
		},
		{
			name: 'color.chart.magenta.bold',
		},
		{
			name: 'color.chart.magenta.bolder',
		},
		{
			name: 'color.chart.magenta.boldest',
		},
		{
			name: 'color.chart.lime.bold',
		},
		{
			name: 'color.chart.lime.bolder',
		},
		{
			name: 'color.chart.lime.boldest',
		},
		{
			name: 'color.chart.gray.bold',
		},
		{
			name: 'color.chart.gray.bolder',
		},
		{
			name: 'color.chart.gray.boldest',
		},
	],
	'data-visualisation/product/neutral_resultNode': [
		{
			name: 'color.chart.neutral',
		},
	],
	'data-visualisation/product/none/one-color_resultNode': [
		{
			name: 'color.chart.brand',
		},
	],
	'data-visualisation/product/none/primary-and-neutral_resultNode': [
		{
			name: 'color.chart.brand',
		},
		{
			name: 'color.chart.neutral',
		},
	],
	'data-visualisation/product/none/more-than-one-color_resultNode': [
		{
			name: 'color.chart.categorical.1',
		},
		{
			name: 'color.chart.categorical.2',
		},
		{
			name: 'color.chart.categorical.3',
		},
		{
			name: 'color.chart.categorical.4',
		},
		{
			name: 'color.chart.categorical.5',
		},
		{
			name: 'color.chart.categorical.6',
		},
		{
			name: 'color.chart.categorical.7',
		},
		{
			name: 'color.chart.categorical.8',
		},
	],
	'data-visualisation/product/success/one-or-more_resultNode': [
		{
			name: 'color.chart.success',
		},
		{
			name: 'color.chart.success.bold',
		},
	],
	'data-visualisation/product/success/primary-and-neutral_resultNode': [
		{
			name: 'color.chart.success.bold',
		},
		{
			name: 'color.chart.neutral',
		},
	],
	'data-visualisation/product/warning/one-or-more_resultNode': [
		{
			name: 'color.chart.warning',
		},
		{
			name: 'color.chart.warning.bold',
		},
	],
	'data-visualisation/product/warning/primary-and-neutral_resultNode': [
		{
			name: 'color.chart.warning.bold',
		},
		{
			name: 'color.chart.neutral',
		},
	],
	'data-visualisation/product/danger/one-or-more_resultNode': [
		{
			name: 'color.chart.danger',
		},
		{
			name: 'color.chart.danger.bold',
		},
	],
	'data-visualisation/product/danger/primary-and-neutral_resultNode': [
		{
			name: 'color.chart.danger.bold',
		},
		{
			name: 'color.chart.neutral',
		},
	],
	'data-visualisation/product/discovery/one-or-more_resultNode': [
		{
			name: 'color.chart.discovery',
		},
		{
			name: 'color.chart.discovery.bold',
		},
	],
	'data-visualisation/product/discovery/primary-and-neutral_resultNode': [
		{
			name: 'color.chart.discovery.bold',
		},
		{
			name: 'color.chart.neutral',
		},
	],
	'data-visualisation/product/information/one-or-more_resultNode': [
		{
			name: 'color.chart.information',
		},
		{
			name: 'color.chart.information.bold',
		},
	],
	'data-visualisation/product/information/primary-and-neutral_resultNode': [
		{
			name: 'color.chart.information.bold',
		},
		{
			name: 'color.chart.neutral',
		},
	],
	'data-visualisation/product/brand/one-or-more_resultNode': [
		{
			name: 'color.chart.brand',
		},
	],
	'data-visualisation/product/brand/primary-and-neutral_resultNode': [
		{
			name: 'color.chart.brand',
		},
		{
			name: 'color.chart.neutral',
		},
	],
	'opacity/disabled_resultNode': [
		{
			name: 'opacity.disabled',
		},
	],
	'opacity/loading_resultNode': [
		{
			name: 'opacity.loading',
		},
	],
	'other/blanket/modal_resultNode': [
		{
			name: 'color.blanket',
		},
	],
	'other/blanket/deletion_resultNode': [
		{
			name: 'color.blanket.danger',
		},
	],
	'other/blanket/selection_resultNode': [
		{
			name: 'color.blanket.selected',
		},
	],
	'other/skeleton_resultNode': [
		{
			name: 'color.skeleton',
		},
	],
	'other/skeleton/subtle_resultNode': [
		{
			name: 'color.skeleton.subtle',
		},
	],
};
export default results;
