const TRUNCATE_SIZE = 10;
const MAX_HITS_LIST = 9;

type HitNode = {
	isHighlighted: boolean;
	value: string;
};

export function formatAndTrimResult(parsedHit: HitNode[]) {
	const result = parsedHit.slice(0, MAX_HITS_LIST).map((node: HitNode, index: number) => {
		const { isHighlighted, value } = node;
		if (isHighlighted) {
			return node;
		}

		const sentences = value.split('.');

		// Don't truncate if the result is short.
		if (value.split(' ').length < TRUNCATE_SIZE) {
			return node;
		}

		// Keep the first sentence in the search result.
		if (index === 0 && parsedHit[index + 1]?.isHighlighted) {
			const lastSentenceBeforeHit = sentences[sentences.length - 1].trim();
			return {
				isHighlighted,
				value: `${lastSentenceBeforeHit} `,
			};
		}

		const firstSentenceAfterHit =
			sentences[0].length < TRUNCATE_SIZE && sentences.length > 1
				? `${sentences[0]}. ${sentences[1]}`
				: sentences[0];
		const firstSentenceBeforeNextHit = sentences[sentences.length - 1];

		return {
			isHighlighted,
			value:
				firstSentenceAfterHit +
				(firstSentenceBeforeNextHit
					? ` ... ${index === MAX_HITS_LIST - 1 ? '' : firstSentenceBeforeNextHit} `
					: '.'),
		};
	});

	// Add ellipsis to the sentence after the last hit
	const lastHit = result[result.length - 1];
	if (parsedHit.length > TRUNCATE_SIZE + 1 && !lastHit.value.trim().endsWith('...')) {
		lastHit.value = lastHit.value + ' ...';
	}

	return result;
}
