import React, { Fragment } from 'react';

import { token } from '@atlaskit/tokens';

import { Example } from '@af/design-system-docs-ui';
import Card from './components/token-card';

const elevationBackgroundChangeStylesCode = `
// Default:
Background color: elevation.surface
Hovered background color: elevation.surface.hovered
Pressed background color: elevation.surface.pressed

// Default (bordered):
Background color: elevation.surface
Hovered background color: elevation.surface.hovered
Pressed background color: elevation.surface.pressed
Border: 1px solid color.border

// Raised:
Background color: elevation.surface.raised
Hovered background color: elevation.surface.raised.hovered
Pressed background color: elevation.surface.raised.pressed
Shadow: elevation.shadow.raised

// Overlay
Background color: elevation.surface.overlay
Hovered background color: elevation.surface.overlay.hovered
Pressed background color: elevation.surface.overlay.pressed
Shadow: elevation.shadow.overlay

`;

const elevationChangeStylesCode = `
// Default:
Background color: elevation.surface
Hovered background color: elevation.surface.overlay
Pressed background color: elevation.surface.raised
Hovered shadow: elevation.shadow.overlay
Pressed shadow: elevation.shadow.raised

// Default (with border)
Background color: elevation.surface
Hovered background color: elevation.surface.overlay
Pressed background color: elevation.surface.raised
Border: 1px solid color.border
Hovered shadow: elevation.shadow.overlay
Pressed shadow: elevation.shadow.raised

// Raised
Background color: elevation.surface.raised
Hovered background color: elevation.surface.overlay
Pressed background color: elevation.surface.raised
Shadow: elevation.shadow.raised
Hovered shadow: elevation.shadow.overlay
Pressed shadow: elevation.shadow.raised
`;

const defaultBackgroundChangeStyles = {
	default: {
		label: 'Default',
		backgroundColor: token('elevation.surface', '#FFFFFF'),
		hoverBackgroundColor: token('elevation.surface.hovered', '#F1F2F4'),
		activeBackgroundColor: token('elevation.surface.pressed', '#DCDFE4'),
		shadow: 'none',
	},
	defaultOutline: {
		label: 'Default (bordered)',
		backgroundColor: token('elevation.surface', '#FFFFFF'),
		hoverBackgroundColor: token('elevation.surface.hovered', '#F1F2F4'),
		activeBackgroundColor: token('elevation.surface.pressed', '#DCDFE4'),
		shadow: 'none',
		border: `${token('border.width.outline', '2px')} solid ${token('color.border', '#091E4224')}`,
	},
};
const backgroundChangeStyles = {
	raised: {
		label: 'Raised',
		backgroundColor: token('elevation.surface.raised', '#FFFFFF'),
		hoverBackgroundColor: token('elevation.surface.raised.hovered', '#F1F2F4'),
		activeBackgroundColor: token('elevation.surface.raised.pressed', '#DCDFE4'),
		shadow: token('elevation.shadow.raised', '0px 1px 1px #091E4240, 0px 0px 1px #091E424F'),
	},
	overlay: {
		label: 'Overlay',
		backgroundColor: token('elevation.surface.overlay', '#FFFFFF'),
		hoverBackgroundColor: token('elevation.surface.overlay.hovered', '#F1F2F4'),
		activeBackgroundColor: token('elevation.surface.overlay.pressed', '#DCDFE4'),
		shadow: token('elevation.shadow.overlay', '0px 8px 12px #091E4226, 0px 0px 1px #091E424F'),
	},
};

const elevationChangeStyles = {
	default: {
		label: 'Default',
		backgroundColor: token('elevation.surface', '#FFFFFF'),
		hoverBackgroundColor: token('elevation.surface.overlay', '#FFFFFF'),
		activeBackgroundColor: token('elevation.surface.raised', '#FFFFFF'),
		shadow: 'none',
		hoverShadow: token('elevation.shadow.overlay', '0px 8px 12px #091E4226, 0px 0px 1px #091E424F'),
		activeShadow: token('elevation.shadow.raised', '0px 1px 1px #091E4240, 0px 0px 1px #091E424F'),
	},
	defaultOutline: {
		label: 'Default (bordered)',
		backgroundColor: token('elevation.surface', '#FFFFFF'),
		hoverBackgroundColor: token('elevation.surface.overlay', '#FFFFFF'),
		activeBackgroundColor: token('elevation.surface.raised', '#FFFFFF'),
		border: `${token('border.width.outline', '2px')} solid ${token('color.border', '#091E4224')}`,
		shadow: 'none',
		hoverShadow: token('elevation.shadow.overlay', '0px 8px 12px #091E4226, 0px 0px 1px #091E424F'),
		activeShadow: token('elevation.shadow.raised', '0px 1px 1px #091E4240, 0px 0px 1px #091E424F'),
	},
	raised: {
		label: 'Raised',
		backgroundColor: token('elevation.surface.raised', '#FFFFFF'),
		hoverBackgroundColor: token('elevation.surface.overlay', '#FFFFFF'),
		activeBackgroundColor: token('elevation.surface.raised', '#FFFFFF'),
		shadow: token('elevation.shadow.raised', '0px 1px 1px #091E4240, 0px 0px 1px #091E424F'),
		hoverShadow: token('elevation.shadow.overlay', '0px 8px 12px #091E4226, 0px 0px 1px #091E424F'),
		activeShadow: token('elevation.shadow.raised', '0px 1px 1px #091E4240, 0px 0px 1px #091E424F'),
	},
};

const TokenElevationBackgroundChange = () => {
	return (
		<Fragment>
			<div
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					display: 'flex',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					gap: '1.5rem',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					flexWrap: 'wrap',
				}}
			>
				{Object.entries(defaultBackgroundChangeStyles).map(([key, subStyle]) => (
					<Card key={key} tokenSet={subStyle} />
				))}
			</div>
			<div
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					display: 'flex',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					marginTop: '1.5rem',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					gap: '1.5rem',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					flexWrap: 'wrap',
				}}
			>
				{Object.entries(backgroundChangeStyles).map(([key, subStyle]) => (
					<Card key={key} tokenSet={subStyle} />
				))}
			</div>
		</Fragment>
	);
};
const TokenElevationChange = () => {
	return (
		<Fragment>
			<div
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					display: 'flex',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					marginTop: '1.5rem',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					gap: '1.5rem',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					flexWrap: 'wrap',
				}}
			>
				{Object.entries(elevationChangeStyles).map(([key, subStyle]) => (
					<Card key={key} tokenSet={subStyle} />
				))}
			</div>
		</Fragment>
	);
};

export const TokenElevationBackgroundChangeExample = () => {
	return (
		<Example
			Component={TokenElevationBackgroundChange}
			source={elevationBackgroundChangeStylesCode}
			packageName="@atlaskit/tokens"
		/>
	);
};

export const TokenElevationChangeExample = () => {
	return (
		<Example
			Component={TokenElevationChange}
			source={elevationChangeStylesCode}
			packageName="@atlaskit/tokens"
		/>
	);
};
