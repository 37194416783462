/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ReactNode } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { MenuGroup } from '@atlaskit/menu';
import Inline from '@atlaskit/primitives/inline';
import { InstantSearch, Index } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch';
import { ContextualAnalyticsData, INLINE_DIALOG } from '@atlassian/analytics-bridge';
import { hideOnDesktopNavigationStyles } from '@af/design-system-docs-ui';
import { token } from '@atlaskit/tokens';

import SearchInput from './input';
import Results from './results';

type SearchClientProps = {
	closeButton?: ReactNode;
	onResultClick?(): void;
	testId?: string;
};

const headerStyles = css({
	paddingInline: token('space.200', '16px'),
	// Uses same breakpoints as @atlaskit/modal
	'@media (max-width: 479.98px)': {
		paddingBlock: token('space.150', '16px'),
		position: 'sticky',
		backgroundColor: token('elevation.surface', '#FFFFFF'),
		insetBlockStart: 0,
		insetInlineStart: 0,
		insetInlineEnd: 0,
	},
	'@media (min-width: 480px)': {
		paddingBlock: token('space.200', '16px'),
	},
});

const SearchClient = ({ closeButton, onResultClick, testId }: SearchClientProps) => {
	const indices: { name: string; title: string }[] = [
		{ name: 'Components', title: 'Components' },
		{ name: 'Foundations', title: 'Foundations' },
		{ name: 'Patterns', title: 'Patterns' },
		{ name: 'Content', title: 'Content' },
		{ name: 'Brand', title: 'Brand' },
		{ name: 'Resources', title: 'Resources' },
		{ name: 'Get started', title: 'Get started' },
		{ name: 'Tokens', title: 'Tokens' },
	];

	const searchClient = algoliasearch(
		process.env.GATSBY_CONSTELLATION_ALGOLIA_APP_ID!,
		process.env.GATSBY_CONSTELLATION_ALGOLIA_SEARCH_KEY!,
	);

	return (
		<InstantSearch indexName="Components" searchClient={searchClient}>
			<div css={headerStyles}>
				<Inline space="space.100" alignBlock="center">
					<SearchInput testId={testId} />
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
					<div css={hideOnDesktopNavigationStyles}>{closeButton}</div>
				</Inline>
			</div>

			{indices.map(({ name }) => (
				<Index key={name} indexName={name} />
			))}

			<ContextualAnalyticsData sourceType={INLINE_DIALOG} sourceName="search">
				<MenuGroup testId={testId ? `${testId}-results` : undefined}>
					<Results onResultClick={onResultClick} />
				</MenuGroup>
			</ContextualAnalyticsData>
		</InstantSearch>
	);
};

export default SearchClient;
