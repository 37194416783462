import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import MetaTags from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/meta-tags';
import { LinkButton } from '@atlaskit/button/new';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { ContextualAnalyticsData, SCREEN, FireScreenAnalytics } from '@atlassian/analytics-bridge';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VerticallyAlignedContent = styled.div({
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const GlobalError = () => {
	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="error">
			<FireScreenAnalytics />
			<MetaTags title="Something unexpected happened" />
			<VerticallyAlignedContent>
				<EmptyState
					header="Something unexpected happened"
					description="Our team has been notified, please refresh the page and try again."
					primaryAction={
						<LinkButton onClick={() => window.location.reload()} href={window.location.href}>
							Refresh
						</LinkButton>
					}
				/>
			</VerticallyAlignedContent>
		</ContextualAnalyticsData>
	);
};

export default GlobalError;
