import React from 'react';

import BaseTable from './components/base-table';
import { BRAND_AND_NEUTRAL_CHART_TOKENS } from './table-data';

/**
 * __Brand and neutral chart tokens table__
 *
 * A brand and neutral chart tokens table describes brand and
 * neutral charts tokens that can be used for Single-color charts.
 *
 */
const BrandAndNeutralChartTokensTable = () => (
	<BaseTable tableData={BRAND_AND_NEUTRAL_CHART_TOKENS} categoryName="Token" />
);
export default BrandAndNeutralChartTokensTable;
