import defaultExample from "../../../../../../packages/design-system/app-provider/examples/constellation/0-default";
import themeSwitcherExample from "../../../../../../packages/design-system/app-provider/examples/constellation/1-theme-switcher";
import routerLinkComponentExample from "../../../../../../packages/design-system/app-provider/examples/constellation/2-router-link-component";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASH0_default from "!!raw-loader!../../../../../../packages/design-system/app-provider/examples/constellation/0-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASH1_theme_switcher from "!!raw-loader!../../../../../../packages/design-system/app-provider/examples/constellation/1-theme-switcher";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASH2_router_link_component from "!!raw-loader!../../../../../../packages/design-system/app-provider/examples/constellation/2-router-link-component";
import * as React from 'react';
export default {
  defaultExample,
  themeSwitcherExample,
  routerLinkComponentExample,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASH0_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASH1_theme_switcher,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASH2_router_link_component,
  React
};