import React, { type ReactNode } from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const sidebarScrollContainerStyles = xcss({
	paddingBlock: 'space.200',
});

const desktopStyles = xcss({
	height: '100%',
	backgroundColor: 'elevation.surface',
	borderInlineEnd: '1px solid transparent',
	borderInlineColor: 'color.border',
	overflowY: 'auto',
});

export type ScrollContainerProps = {
	device: 'desktop' | 'mobile';
	children: ReactNode;
};

/**
 * __Scroll container__
 *
 * A scroll container for the nav items in the sidebar.
 */
const ScrollContainer = ({ children, device }: ScrollContainerProps) => {
	return (
		<Box xcss={[sidebarScrollContainerStyles, device === 'desktop' && desktopStyles]}>
			{children}
		</Box>
	);
};

export default ScrollContainer;
