import BasicExample from "../../../../../../packages/design-system/spinner/examples/0-basic";
import SizeExample from "../../../../../../packages/design-system/spinner/examples/1-sizes";
import AnimatedExitExample from "../../../../../../packages/design-system/spinner/examples/animated-exit";
import DelayingExample from "../../../../../../packages/design-system/spinner/examples/delaying";
import SpinnerOverContentExample from "../../../../../../packages/design-system/spinner/examples/constellation/spinner-over-content";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASH0_basic from "!!raw-loader!../../../../../../packages/design-system/spinner/examples/0-basic";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASH1_sizes from "!!raw-loader!../../../../../../packages/design-system/spinner/examples/1-sizes";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHanimated_exit from "!!raw-loader!../../../../../../packages/design-system/spinner/examples/animated-exit";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHdelaying from "!!raw-loader!../../../../../../packages/design-system/spinner/examples/delaying";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspinner_over_content from "!!raw-loader!../../../../../../packages/design-system/spinner/examples/constellation/spinner-over-content";
import * as React from 'react';
export default {
  BasicExample,
  SizeExample,
  AnimatedExitExample,
  DelayingExample,
  SpinnerOverContentExample,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASH0_basic,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASH1_sizes,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHanimated_exit,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHdelaying,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspinner_over_content,
  React
};