import React from 'react';

import { token } from '@atlaskit/tokens';

const AvatarResources = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="24" height="24" rx="3" fill={token('color.icon.accent.magenta')} />
		<path
			d="M11.9 9.4C13.124 9.4 13.532 9.928 13.532 10.696C13.532 11.512 13.136 11.992 11.9 11.992H10.472V9.4H11.9ZM14.972 10.672C14.972 9.088 14.036 8.116 12.104 8.116H9.032V16H10.472V13.228H12.104H12.116L13.664 16H15.356L13.544 13C14.504 12.64 14.972 11.836 14.972 10.672Z"
			fill={token('color.icon.inverse')}
		/>
	</svg>
);

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default AvatarResources;
