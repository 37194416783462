import React, { forwardRef, type Ref } from 'react';
import { Link, type GatsbyLinkProps } from 'gatsby';
import type { RouterLinkComponentProps } from '@atlaskit/app-provider';

export type LinkConfig = Pick<GatsbyLinkProps<{}>, 'to' | 'replace'>;

/**
 * Configuration for how Gatsby link components are consumed by Atlassian Design System
 * components through the AppProvider.
 *
 * In simple cases where the `href` prop is a string, this passes straight through to the `Link` component,
 * and allows ADS components to automatically utilize router links without additional configuration or
 * component overrides.
 * @example
 * ```
 * import { LinkButton } from '@atlaskit/button/new';
 *
 * const MyComponent = () => (
 *   <LinkButton href='my-route'>
 *     Hello world
 *   </LinkButton>
 * );
 * ```
 *
 * For more complex cases, where the `href` prop is an object, this maps the object properties to the `Link` component.
 * To support this, ADS link components support generic types which need to be passed a matching configuration object.
 * In this case, the `LinkConfig` type above is required to be passed.
 * @example
 * ```
 * import type { LinkConfig } from '../components/app-provider-router-link';
 * import { LinkButton } from '@atlaskit/button/new';
 *
 * const MyComponent = () => (
 *   <LinkButton<LinkConfig>
 *     href={{
 *       to: 'my-route',
 *       replace: true,
 *     }}
 *   >
 *     Hello world
 *   </LinkButton>
 * );
 * ```
 *
 * @note ADS has built-in detection of internal, external, and non-HTTP-based links, so this configuration is only used
 * for internal links that need to be handled by the router. Other links will be passed directly to a native `<a>` tag.
 *
 * @see https://atlassian.design/components/app-provider/examples#router-links
 */
const AppProviderRouterLink = forwardRef(
	(
		{ href, children, ...rest }: RouterLinkComponentProps<LinkConfig>,
		ref: Ref<HTMLAnchorElement>,
	) => {
		if (!href || typeof href === 'string') {
			return (
				<Link to={href} {...rest}>
					{children}
				</Link>
			);
		}

		return (
			<Link to={href?.to} replace={href?.replace} {...rest}>
				{children}
			</Link>
		);
	},
);

export default AppProviderRouterLink;
