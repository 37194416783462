import React from 'react';

import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { easeInOut, mediumDurationMs } from '@atlaskit/motion';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';

const expandButtonStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	width: token('space.500'),
	padding: 'space.0',
	flexShrink: 0,

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
	':focus-visible': {
		outline: 'none',
		borderColor: 'color.border.focused',
		borderWidth: 'border.width.outline',
		borderStyle: 'solid',
	},
});

const expandButtonOpenStyles = xcss({
	backgroundColor: 'color.background.selected',

	':hover': {
		backgroundColor: 'color.background.selected.hovered',
	},
	':active': {
		backgroundColor: 'color.background.selected.pressed',
	},
});

const chevronIconStyles = xcss({
	transform: 'rotate(0deg)',
	transition: `transform ${mediumDurationMs}ms ${easeInOut}`,
});

const chevronIconExpandedStyles = xcss({
	transform: 'rotate(90deg)',
});

type ExpandButtonProps = {
	isOpen: boolean;
	isSelected: boolean;
	onClick?: () => void;
	tabIndex?: number;
	title: string;
};

/**
 * __Expand button__
 *
 * A button used to expand / collapse subnavigations.
 */
const ExpandButton = ({
	isOpen = false,
	isSelected = false,
	onClick,
	tabIndex,
	title,
}: ExpandButtonProps) => {
	return (
		<Pressable
			xcss={[expandButtonStyles, isSelected && expandButtonOpenStyles]}
			onClick={onClick}
			aria-expanded={isOpen}
			tabIndex={tabIndex}
		>
			<Box xcss={[chevronIconStyles, isOpen && chevronIconExpandedStyles]}>
				<ChevronRightIcon
					label="Expand"
					primaryColor={isSelected ? token('color.icon.selected') : token('color.icon')}
				/>
			</Box>
			<VisuallyHidden>{`Expand ${title} menu`}</VisuallyHidden>
		</Pressable>
	);
};

export default ExpandButton;
