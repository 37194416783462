/* eslint-disable no-param-reassign */
// This file is included as an alternative to a root patch package approach.
// See gatsby-node.js for usage
module.exports = function inherits(ctor, superCtor) {
	if (superCtor) {
		ctor.super_ = superCtor;
		ctor.prototype = Object.create(superCtor.prototype, {
			constructor: {
				value: ctor,
				enumerable: false,
				writable: true,
				configurable: true,
			},
		});
	}
};
