/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { token } from '@atlaskit/tokens';

const videoWrapperStyles = css({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBlockEnd: '56.25%',
});

const videoStyles = css({
	width: '100%',
	height: '100%',
	position: 'absolute',
	insetBlockStart: token('space.0', '0px'),
	insetInlineStart: token('space.0', '0px'),
});

export interface VideoEmbedProps {
	url: string;
}

/**
 * __Video embed__
 *
 * Renders a responsive YouTube video in an iframe given a YouTube video URL
 */
const VideoEmbed = ({ url }: VideoEmbedProps) => {
	return (
		<div css={[videoWrapperStyles]}>
			<iframe
				css={[videoStyles]}
				src={`${url}?rel=0&modestbranding=1`} // Hide related videos and YouTube logo
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowFullScreen
			></iframe>
		</div>
	);
};

export default VideoEmbed;
