import SectionMessage from '@atlaskit/section-message';
import MotionResizingHeight from "../../../../../../packages/design-system/motion/examples/constellation/motion-resizing-height";
import RAW_UNSAFE_atlaskitSLASHsection_message from "!!raw-loader!@atlaskit/section-message";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_resizing_height from "!!raw-loader!../../../../../../packages/design-system/motion/examples/constellation/motion-resizing-height";
import * as React from 'react';
export default {
  SectionMessage,
  MotionResizingHeight,
  RAW_UNSAFE_atlaskitSLASHsection_message,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_resizing_height,
  React
};