/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useCallback, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import debounce from 'lodash/debounce';

import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';

const focusRingStyles = css({
	outlineColor: token('color.border.focused'),
	outlineOffset: token('border.width.outline', '2px'),
	outlineStyle: 'solid',
	outlineWidth: token('border.width.outline', '2px'),
});
const overflowShadowStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'&:focus-visible': focusRingStyles,
	background: `
      /* Shadow covers */
      linear-gradient(to right, ${token('elevation.surface')}, transparent) left center,
      linear-gradient(to left, ${token('elevation.surface')}, transparent) right center,

      /* Shadows */
      linear-gradient(to right, ${token(
				'elevation.shadow.overflow.spread',
			)}, ${token('elevation.surface')}) left center,
      linear-gradient(to left, ${token(
				'elevation.shadow.overflow.spread',
			)}, ${token('elevation.surface')}) right center,

      /* Perimeter */
      linear-gradient(to right, ${token(
				'elevation.shadow.overflow.perimeter',
			)}, ${token('elevation.surface')}) left center,
      linear-gradient(to left, ${token(
				'elevation.shadow.overflow.perimeter',
			)}, ${token('elevation.surface')}) right center;
      `,
	backgroundAttachment: 'local, local, scroll, scroll, scroll, scroll',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '30px 100%, 30px 100%, 8px 98%, 8px 99%, 1px 99%, 1px 99%',
	'@supports not selector(*:focus-visible)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		'&:focus': focusRingStyles,
	},
	'@media screen and (forced-colors: active), screen and (-ms-high-contrast: active)': {
		'&:focus-visible': {
			outline: '1px solid',
		},
	},
});

/**
 * __Horizontal Scroll container__
 *
 * A container for tables that enables horizontal scrolling of an element:
 * - a scroll bar (overflowX: 'auto')
 * - keyboard focus (tabIndex = 0)
 * - a scroll shadow
 *
 * Before using this, consider whether another layout is possible at small widths.
 *
 * This should only be used for tables or other elements where 2-dimensional positioning
 * is important, and the content can't be broken into smaller pieces at small widths.
 * https://www.w3.org/WAI/standards-guidelines/act/rules/0ssw9k/proposed/
 *
 */
const HorizontalScrollContainer = ({
	children,
	isFocusable = true,
}: {
	children: React.ReactNode;
	/**
	 * Whether the container can be focused when it is scrollable.
	 * Focus is required for keyboard navigation, but can be disabled if
	 * the child content is already focusable.
	 * @default false
	 */
	isFocusable?: boolean;
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [isScrollable, setIsScrollable] = useState(false);

	/**
	 * Very naive check - if child is larger than parent, show scroll styles.
	 * Doesn't account for changes in width
	 */
	const checkWidth = useCallback(() => {
		debounce(
			(_) => {
				if (ref.current) {
					const { scrollWidth, clientWidth } = ref.current;
					if (scrollWidth > clientWidth) {
						setIsScrollable(true);
					} else {
						setIsScrollable(false);
					}
				}
			},
			400,
			{ leading: true },
		)(undefined);
	}, []);

	return (
		<div
			/**
			 * Tables can be a valid example of content that may need to scroll horizontally on
			 * a vertically scrolling page.
			 * To enable a horizontally scrollable table to be navigated with the keyboard,
			 * we need to add a tabIndex to the table element.
			 */
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex={isFocusable && isScrollable ? 0 : undefined}
			role={isScrollable ? 'region' : undefined}
			aria-label={isScrollable ? 'Scrollable content' : undefined}
			css={[
				isScrollable && overflowShadowStyles,
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				{
					width: '100%',
					overflowX: 'auto',
				},
			]}
			ref={ref}
		>
			{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={{ width: '100%' }}>
				{children}
				{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
				<div css={{ position: 'relative' }}>
					<WidthObserver setWidth={checkWidth} />
				</div>
			</div>
		</div>
	);
};

export default HorizontalScrollContainer;
