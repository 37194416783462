const { GATSBY_CONSTELLATION_ENVIRONMENT, GATSBY_CONSTELLATION_LOCAL_PACKAGES } = process.env;

const PACKAGES = [
	'@atlaskit/app-provider',
	'@atlaskit/atlassian-navigation',
	'@atlaskit/avatar-group',
	'@atlaskit/avatar',
	'@atlaskit/badge',
	'@atlaskit/banner',
	'@atlaskit/blanket',
	'@atlaskit/breadcrumbs',
	'@atlaskit/button',
	'@atlaskit/calendar',
	'@atlaskit/checkbox',
	'@atlaskit/code',
	'@atlaskit/comment',
	'@atlaskit/css',
	'@atlaskit/css-reset',
	'@atlaskit/datetime-picker',
	'@atlaskit/drawer',
	'@atlaskit/dropdown-menu',
	'@atlaskit/dynamic-table',
	'@atlaskit/empty-state',
	'@atlaskit/eslint-plugin-design-system',
	'@atlaskit/eslint-plugin-ui-styling-standard',
	'@atlaskit/flag',
	'@atlaskit/focus-ring',
	'@atlaskit/form',
	'@atlaskit/grid',
	'@atlaskit/heading',
	'@atlaskit/icon-object',
	'@atlaskit/icon',
	'@atlaskit/image',
	'@atlaskit/inline-dialog',
	'@atlaskit/inline-edit',
	'@atlaskit/inline-message',
	'@atlaskit/link',
	'@atlaskit/logo',
	'@atlaskit/lozenge',
	'@atlaskit/menu',
	'@atlaskit/modal-dialog',
	'@atlaskit/motion',
	'@atlaskit/onboarding',
	'@atlaskit/page',
	'@atlaskit/page-header',
	'@atlaskit/page-layout',
	'@atlaskit/pagination',
	'@atlaskit/popper',
	'@atlaskit/popup',
	'@atlaskit/portal',
	'@atlaskit/pragmatic-drag-and-drop',
	'@atlaskit/pragmatic-drag-and-drop-auto-scroll',
	'@atlaskit/pragmatic-drag-and-drop-docs',
	'@atlaskit/pragmatic-drag-and-drop-flourish',
	'@atlaskit/pragmatic-drag-and-drop-hitbox',
	'@atlaskit/pragmatic-drag-and-drop-live-region',
	'@atlaskit/pragmatic-drag-and-drop-react-accessibility',
	'@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-autoscroll',
	'@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration',
	'@atlaskit/pragmatic-drag-and-drop-react-drop-indicator',
	'@atlaskit/pragmatic-drag-and-drop-unit-testing',
	'@atlaskit/primitives',
	'@atlaskit/progress-bar',
	'@atlaskit/progress-indicator',
	'@atlaskit/progress-tracker',
	'@atlaskit/radio',
	'@atlaskit/range',
	'@atlaskit/section-message',
	'@atlaskit/select',
	'@atlaskit/side-navigation',
	'@atlaskit/skeleton',
	'@atlaskit/spinner',
	'@atlaskit/storybook-addon-design-system',
	'@atlaskit/stylelint-design-system',
	'@atlaskit/table',
	'@atlaskit/table-tree',
	'@atlaskit/tabs',
	'@atlaskit/tag-group',
	'@atlaskit/tag',
	'@atlaskit/textarea',
	'@atlaskit/textfield',
	'@atlaskit/toggle',
	'@atlaskit/tokens',
	'@atlaskit/tooltip',
	'@atlaskit/visually-hidden',
	'@atlassian/navigation-system',
].filter((p) => {
	const filterPackages = (GATSBY_CONSTELLATION_LOCAL_PACKAGES || '')
		.split(',')
		.map((input) => input.trim());
	return GATSBY_CONSTELLATION_LOCAL_PACKAGES && GATSBY_CONSTELLATION_ENVIRONMENT === 'LOCAL'
		? filterPackages.includes(p)
		: true;
});

if (PACKAGES.length === 0) {
	throw new Error(
		`List of PACKAGES is empty. Check the packages provided to start the service: ${GATSBY_CONSTELLATION_LOCAL_PACKAGES}`,
	);
}

exports.packages = PACKAGES;

exports.constellationCategories = {
	defaultPackageCategory: 'Components',
	packageCategories: ['Components', 'Primitives', 'Libraries', 'Tooling'],
};
exports.packageDocsConfig = {
	folder: 'constellation',
	internalDocsPath: 'src/mdx',
	includeCodeTab: 'indexed', // 'all' | 'indexed' | 'none'
};

/**
 * Hopefully we can refactor to incorporate these into a more
 * systematic approach.
 */
exports.overrides = {
	'@atlassian/navigation-system': {
		slug: 'navigation-system',
	},
	'@atlaskit/css': {
		hasCodeDocs: false,
	},

	'@atlaskit/icon': {
		//Hides the local navigation for the icon-explorer tab
		hideLocalNavForTabs: ['icon-explorer'],
	},

	'@atlaskit/pragmatic-drag-and-drop-docs': {
		slug: 'pragmatic-drag-and-drop',
		hasCodeDocs: false,
		hideChangelog: true,
		description: 'Flexible and fast drag and drop for any experience on any tech stack',
	},
	// TODO
	'@atlaskit/pragmatic-drag-and-drop': {
		slug: 'pragmatic-drag-and-drop/core-package/changelog',
		hideChangelog: false,
		hasCodeDocs: true,
		order: 50,
	},
	'@atlaskit/pragmatic-drag-and-drop-hitbox': {
		slug: 'pragmatic-drag-and-drop/optional-packages/hitbox',
		order: 1,
	},
	'@atlaskit/pragmatic-drag-and-drop-react-drop-indicator': {
		slug: 'pragmatic-drag-and-drop/optional-packages/react-drop-indicator',
		order: 2,
	},
	'@atlaskit/pragmatic-drag-and-drop-flourish': {
		slug: 'pragmatic-drag-and-drop/optional-packages/flourish',
		order: 3,
	},
	'@atlaskit/pragmatic-drag-and-drop-auto-scroll': {
		slug: 'pragmatic-drag-and-drop/optional-packages/auto-scroll',
		order: 4,
	},
	'@atlaskit/pragmatic-drag-and-drop-react-accessibility': {
		slug: 'pragmatic-drag-and-drop/optional-packages/react-accessibility',
		order: 5,
	},
	'@atlaskit/pragmatic-drag-and-drop-live-region': {
		slug: 'pragmatic-drag-and-drop/optional-packages/live-region',
		order: 6,
	},
	'@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration': {
		slug: 'pragmatic-drag-and-drop/optional-packages/react-beautiful-dnd-migration',
		order: 7,
	},
	'@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-autoscroll': {
		slug: 'pragmatic-drag-and-drop/optional-packages/react-beautiful-dnd-autoscroll',
		order: 8,
	},
	'@atlaskit/pragmatic-drag-and-drop-unit-testing': {
		slug: 'pragmatic-drag-and-drop/optional-packages/unit-testing',
		order: 9,
	},
};

/**
 * __Contentful ID for the "workaround" page__.
 *
 * The workaround page exists to ensure that gatsby-source-contentful won't
 * fail the build if it doesn't have an example of a particular Content Type
 * being used. It has an example of every Content Type on it so that someone
 * can't accidentally edit a page on Contentful, remove the last usage of a
 * Content Type, and leave our GraphQL queries asking for data that is no
 * longer in Gatsby's GraphQL schema that is inferred from source plugin data.
 *
 * See https://www.gatsbyjs.com/plugins/gatsby-source-contentful/#restrictions-and-limitations.
 *
 * We filter this page out of queries for navigation and Algolia search indexing,
 * and also conditionally apply a noindex meta tag.
 */
exports.WORKAROUND_PAGE_ID = '6VVmx8ld65HMF5iYbJoK0X';
