import CommentDefault from "../../../../../../packages/design-system/comment/examples/constellation/comment-default";
import CommentFull from "../../../../../../packages/design-system/comment/examples/constellation/comment-full";
import CommentNested from "../../../../../../packages/design-system/comment/examples/constellation/comment-nested";
import CommentSaving from "../../../../../../packages/design-system/comment/examples/constellation/comment-saving";
import CommentEdited from "../../../../../../packages/design-system/comment/examples/constellation/comment-edited";
import CommentRestricted from "../../../../../../packages/design-system/comment/examples/constellation/comment-restricted";
import CommentHighlighted from "../../../../../../packages/design-system/comment/examples/constellation/comment-highlighted";
import CommentCustomHeadingLevelExample from "../../../../../../packages/design-system/comment/examples/constellation/comment-custom-heading-level";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_default from "!!raw-loader!../../../../../../packages/design-system/comment/examples/constellation/comment-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_full from "!!raw-loader!../../../../../../packages/design-system/comment/examples/constellation/comment-full";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_nested from "!!raw-loader!../../../../../../packages/design-system/comment/examples/constellation/comment-nested";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_saving from "!!raw-loader!../../../../../../packages/design-system/comment/examples/constellation/comment-saving";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_edited from "!!raw-loader!../../../../../../packages/design-system/comment/examples/constellation/comment-edited";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_restricted from "!!raw-loader!../../../../../../packages/design-system/comment/examples/constellation/comment-restricted";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_highlighted from "!!raw-loader!../../../../../../packages/design-system/comment/examples/constellation/comment-highlighted";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_custom_heading_level from "!!raw-loader!../../../../../../packages/design-system/comment/examples/constellation/comment-custom-heading-level";
import * as React from 'react';
export default {
  CommentDefault,
  CommentFull,
  CommentNested,
  CommentSaving,
  CommentEdited,
  CommentRestricted,
  CommentHighlighted,
  CommentCustomHeadingLevelExample,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_full,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_nested,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_saving,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_edited,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_restricted,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_highlighted,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHcomment_custom_heading_level,
  React
};