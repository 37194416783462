import React, { type SyntheticEvent, useCallback, useEffect, useState } from 'react';

import SearchIcon from '@atlaskit/icon/glyph/search';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { Show, UNSAFE_useMediaQuery } from '@atlaskit/primitives/responsive';
import Textfield from '@atlaskit/textfield';

import {
	categories,
	filterIcons,
	getGlobalIcons,
	getObjectIcons,
	iconObjectImportPrefix,
	legacyIconImportPrefix,
	sortIntoCategories,
} from '../helpers';
import { type AllIconsList, type IconData, type IconsList } from '../types';

import GridSection from './grid-section';
import LegacyIconDetails from './legacy-icon-details';

const LegacyIconDetailsBoxStyles = xcss({
	position: 'sticky',
	insetBlockStart: '64px',
});

const modalIconDetailsStyles = xcss({
	width: '100%',
	borderRadius: '4px',
	overflow: 'auto',
});

type IconExplorerProps = {
	iconType: 'global' | 'object';
	testId?: string;
};

const SearchIconBefore = () => (
	<Box paddingInlineStart="space.100">
		<SearchIcon label="Search" size="small" />
	</Box>
);

export function LegacyIconExplorer({ iconType, testId }: IconExplorerProps) {
	const [selectedIcon, setSelectedIcon] = useState<IconData | undefined>();
	const [allIcons, setAllIcons] = useState<IconsList>();
	const [icons, setIcons] = useState<AllIconsList>();
	const [searchQuery, setSearchQuery] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const isBelowSm = UNSAFE_useMediaQuery('below.sm');

	useEffect(() => {
		const iconPromise = iconType === 'global' ? getGlobalIcons() : getObjectIcons();
		iconPromise.then((icons) => setAllIcons(icons as IconsList));
	}, [iconType]);

	useEffect(() => {
		if (allIcons) {
			const icons = filterIcons(allIcons, searchQuery) as IconData[];
			setIcons(sortIntoCategories(icons, iconType));
		}
	}, [setIcons, searchQuery, allIcons, iconType]);

	const updateSearchQuery = useCallback(
		(query: string) => {
			setSearchQuery(query);
		},
		[setSearchQuery],
	);

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedIcon(undefined);
	};

	const updateSelectedIcon = useCallback(
		(icon: IconData) => {
			setSelectedIcon((oldIcon) => (oldIcon?.packageName !== icon.packageName ? icon : undefined));
			isBelowSm?.matches && setIsModalOpen(true);
		},
		[setSelectedIcon, isBelowSm?.matches],
	);

	if (!icons) {
		return null;
	}

	return (
		<Inline space="space.200" testId={testId}>
			<Stack space="space.400" grow="fill">
				<Textfield
					value={searchQuery}
					placeholder="Search"
					elemBeforeInput={<SearchIconBefore />}
					onChange={(evt: SyntheticEvent<HTMLInputElement>) =>
						updateSearchQuery(evt.currentTarget.value)
					}
				/>
				{iconType === 'global' ? (
					<>
						{icons.global.length > 0 && (
							<GridSection
								isLegacy={true}
								importPath={legacyIconImportPrefix}
								heading="Global icons"
								icons={icons?.global}
								onIconClick={updateSelectedIcon}
								selectedIcon={isModalOpen ? selectedIcon?.packageName : undefined}
							/>
						)}

						{categories.map((category) => {
							return (
								icons[category].length > 0 && (
									<GridSection
										isLegacy={true}
										importPath={`${legacyIconImportPrefix}/${category}`}
										heading={category}
										icons={icons?.[category]}
										key={category}
										onIconClick={updateSelectedIcon}
										selectedIcon={isModalOpen ? selectedIcon?.packageName : undefined}
									/>
								)
							);
						})}
					</>
				) : (
					<>
						<GridSection
							isLegacy={true}
							importPath={`${iconObjectImportPrefix}/.../16`}
							heading="Small"
							icons={icons?.['16']}
							onIconClick={updateSelectedIcon}
							selectedIcon={selectedIcon?.packageName}
						/>

						<GridSection
							isLegacy={true}
							importPath={`${iconObjectImportPrefix}/.../24`}
							heading="Medium"
							icons={icons?.['24']}
							onIconClick={updateSelectedIcon}
							selectedIcon={selectedIcon?.packageName}
						/>
					</>
				)}
			</Stack>
			<ModalTransition>
				{isModalOpen && selectedIcon && (
					<Modal onClose={closeModal}>
						<Box xcss={modalIconDetailsStyles}>
							<LegacyIconDetails icon={selectedIcon} isModal={true} onClose={closeModal} />
						</Box>
					</Modal>
				)}
			</ModalTransition>
			<Show above="sm">
				<Box xcss={LegacyIconDetailsBoxStyles}>
					<LegacyIconDetails icon={selectedIcon} />
				</Box>
			</Show>
		</Inline>
	);
}
