import BasicFlashExample from "../../../../../../packages/pragmatic-drag-and-drop/flourish/examples/basic";
import SectionMessage from '@atlaskit/section-message';
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHbasic from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/flourish/examples/basic";
import RAW_UNSAFE_atlaskitSLASHsection_message from "!!raw-loader!@atlaskit/section-message";
import * as React from 'react';
export default {
  BasicFlashExample,
  SectionMessage,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHbasic,
  RAW_UNSAFE_atlaskitSLASHsection_message,
  React
};