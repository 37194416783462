/**
 * Extracted from @atlaskit/docs
 *
 * Used for converting relative imports to package imports
 * e.g. "../../src" -> "@atlaskit/icon"
 */

/* eslint-disable no-useless-escape */
// @ts-ignore
import replaceImports from 'codesandboxer/dist/replaceImports';
// You can test this regex here https://regex101.com/r/7yGMmN/1
const srcEntryPointRegexString =
	/((?:import|export)[^'"\`]*['"\`])((\.\.\/){1,}src\/)([^/]*['"\`]\s*)/;
// You can test this regex here https://regex101.com/r/HLvZ7M/1
const glyphPathRegexString = /((?:import|export)[^'"\`]*['"\`])((\.\.\/){1,})(glyph\/(.*['"\`]))/;
// You can test this regex here https://regex101.com/r/0loMLs/1
const svgEntryPointRegexString =
	/((?:import|export)[^'"\`]*['"\`])((\.\.\/){1,}src\/entry-points\/(svg))([^/]*['"\`])/;

export default function replaceSrc(content: string, name: string) {
	let replacedCode = content;

	if (name) {
		// Replace ../src/<entry-point> with ${name}/<entry-point>
		replacedCode = replacedCode.replace(srcEntryPointRegexString, `$1${name}/$4`);

		// Replace import SVG from '../../src/entry-points/svg' with import SVG from '@atlaskit/icon/svg'
		replacedCode = replacedCode.replace(svgEntryPointRegexString, `$1${name}/$4$5`);
		// Replace ../../glyph/<glyph-name> with ${name}/glyph/<glyph-name>
		replacedCode = replacedCode.replace(glyphPathRegexString, `$1${name}/$4`);

		replacedCode = replaceImports(replacedCode, [[`(?:..\/)*src`, name]]);
	}

	return replacedCode;
}
