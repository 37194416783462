import React, { useContext } from 'react';

import { SideNavigationProps } from './index';

/**
 * __Side navigation context__
 *
 * Context for the side navigation to provide easy access to
 * data throughout deep menu nesting.
 */
const SideNavigationContext = React.createContext<{
	device: SideNavigationProps['device'];
}>({
	device: 'mobile',
});

export const useSideNavigation = () => {
	return useContext(SideNavigationContext);
};

export default SideNavigationContext;
