import React, { type ReactNode } from 'react';

import { Anchor, Box, Stack, Text, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

const footerNavStyles = xcss({
	borderBlockStartColor: token('color.border'),
	borderBlockStartStyle: 'solid',
	borderBlockStartWidth: '1px',
	paddingBlock: 'space.300',
	paddingInline: 'space.100',
	[media.above.sm]: {
		paddingInline: 'space.0',
	},
});

const navStyles = xcss({
	listStyle: 'none',
	padding: 'space.0',
	margin: 'space.0',
	paddingInline: 'space.100',
	[media.above.sm]: {
		gap: 'space.400',
		flexDirection: 'row',
		paddingInline: 'space.0',
	},
});

const navLinkStyles = xcss({
	textDecoration: 'none',
	color: 'color.text.subtlest',
	':hover': {
		color: 'color.text.subtle',
		textDecoration: 'underline',
	},
	':active': {
		color: 'color.text',
	},
});

const separatorStyles = xcss({
	display: 'none',
	[media.above.sm]: {
		display: 'inline',
	},
});

const listItemStyles = xcss({
	margin: 'space.0',
});

type FooterLinkProps = {
	href: string;
	isExternal?: boolean;
	children: ReactNode;
};

const FooterLink = ({ href, isExternal, children }: FooterLinkProps) => {
	return (
		<Text weight="medium">
			<Anchor
				href={href}
				target={isExternal ? '_blank' : undefined}
				rel={isExternal ? 'noopener' : undefined}
				xcss={navLinkStyles}
			>
				{children}
			</Anchor>
		</Text>
	);
};

/**
 * __Footer content__
 */
const FooterContent = () => (
	<Box as="nav" xcss={footerNavStyles}>
		<Stack space="space.200" alignInline="center" alignBlock="center" xcss={navStyles}>
			<Text color="color.text.subtlest">&copy; {new Date().getFullYear()} Atlassian</Text>
			<Box xcss={separatorStyles}>
				<Text color="color.text.subtlest">•</Text>
			</Box>
			<Stack as="ul" space="space.200" alignInline="center" xcss={navStyles}>
				<Box as="li" xcss={listItemStyles}>
					<FooterLink href="https://www.atlassian.com/company/careers" isExternal>
						Careers
					</FooterLink>
				</Box>
				<Box as="li" xcss={listItemStyles}>
					<FooterLink href="https://www.atlassian.com/legal/trademark" isExternal>
						Trademark
					</FooterLink>
				</Box>
				<Box as="li" xcss={listItemStyles}>
					<FooterLink href="https://www.atlassian.com/legal/privacy-policy" isExternal>
						Privacy
					</FooterLink>
				</Box>
				<Box as="li" xcss={listItemStyles}>
					<FooterLink href="/license">License</FooterLink>
				</Box>
			</Stack>
		</Stack>
	</Box>
);

export default FooterContent;
