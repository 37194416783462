import SectionMessage from '@atlaskit/section-message';
import NestedDropTargetExample from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/nested-drop-targets";
import RAW_UNSAFE_atlaskitSLASHsection_message from "!!raw-loader!@atlaskit/section-message";
import RAW_UNSAFEUPUPSLASHUPUPSLASHUPUPSLASHexamplesSLASHnested_drop_targets from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/nested-drop-targets";
import * as React from 'react';
export default {
  SectionMessage,
  NestedDropTargetExample,
  RAW_UNSAFE_atlaskitSLASHsection_message,
  RAW_UNSAFEUPUPSLASHUPUPSLASHUPUPSLASHexamplesSLASHnested_drop_targets,
  React
};