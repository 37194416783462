import React from 'react';

import Heading from '@atlaskit/heading';
import { Box, Grid, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import palettesRaw from '@atlaskit/tokens/palettes-raw';

interface DedicationCardProps {
	color: string;
	description: string;
	dedicatedTo: string;
}

const borderColorStyle = (colorValue: string | undefined) => {
	return {
		borderColor: colorValue ? colorValue : token('color.skeleton'),
	};
};
const boxStyles = xcss({
	backgroundColor: 'elevation.surface',
	borderRadius: 'border.radius',
	borderStyle: 'solid',
	borderWidth: 'border.width',
	padding: 'space.200',
});

const colorSwatchStyles = (colorValue: string | undefined) => {
	return {
		width: '24px',
		height: '24px',
		backgroundColor: colorValue ? colorValue : token('color.skeleton'),
		borderColor: colorValue ? colorValue : token('color.skeleton'),
		borderRadius: token('border.radius.circle'),
	};
};

const DedicationCard = ({ color, description, dedicatedTo }: DedicationCardProps) => {
	const colorValue: string | undefined = palettesRaw.find(
		(x) => x.name === `color.palette.${color}`,
	)?.value as string | undefined;
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<Box style={borderColorStyle(colorValue)} xcss={boxStyles}>
			<Stack space="space.200">
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
				<Box style={colorSwatchStyles(colorValue)} />
				<Box>
					<Stack space="space.050">
						<Heading size="xsmall" as="h3">
							{color} - {dedicatedTo}
						</Heading>
						<Text as="p">{description}</Text>
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

/**
 *
 * Dedicates colors to Alex and Rosie
 *
 */
const ColorPaletteDedication = () => {
	return (
		<Grid templateColumns="1fr 1fr" columnGap="space.100">
			<DedicationCard
				color="Red300"
				description="Alexandria reflects genuine love and dedication."
				dedicatedTo="Alexandria"
			/>
			<DedicationCard
				color="Magenta300"
				description="Like its namesake, Rosie makes the world a brighter place."
				dedicatedTo="Rosie"
			/>
		</Grid>
	);
};

export default ColorPaletteDedication;
