import VisuallyHiddenDefault from "../../../../../../packages/design-system/visually-hidden/examples/constellation/visually-hidden-default";
import VisuallyHiddenButtonsExample from "../../../../../../packages/design-system/visually-hidden/examples/constellation/visually-hidden-buttons";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHvisually_hidden_default from "!!raw-loader!../../../../../../packages/design-system/visually-hidden/examples/constellation/visually-hidden-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHvisually_hidden_buttons from "!!raw-loader!../../../../../../packages/design-system/visually-hidden/examples/constellation/visually-hidden-buttons";
import * as React from 'react';
export default {
  VisuallyHiddenDefault,
  VisuallyHiddenButtonsExample,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHvisually_hidden_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHvisually_hidden_buttons,
  React
};