import React from 'react';

import Button from '@atlaskit/button/new';
import SearchIcon from '@atlaskit/icon/glyph/search';

interface SearchButtonProps {
	onClick?(): void;
}

/**
 * __Search button__
 */
const SearchButton = ({ onClick }: SearchButtonProps) => {
	return (
		<Button onClick={onClick} iconBefore={SearchIcon} appearance="subtle">
			Search
		</Button>
	);
};

export default SearchButton;
