import settingsDo from "../../../../../../packages/design-system/icon/constellation/index/images/settings-do.png";
import settingsDont from "../../../../../../packages/design-system/icon/constellation/index/images/settings-dont.png";
import menuDo from "../../../../../../packages/design-system/icon/constellation/index/images/menu-do.png";
import menuDont from "../../../../../../packages/design-system/icon/constellation/index/images/menu-dont.png";
import * as React from 'react';
export default {
  settingsDo,
  settingsDont,
  menuDo,
  menuDont,
  React
};