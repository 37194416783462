import FormButtonFeedbackDo from "../../../../../../packages/design-system/form/constellation/images/form-button-feedback-do.png";
import FormButtonFeedbackDont from "../../../../../../packages/design-system/form/constellation/images/form-button-feedback-dont.png";
import FormDefaultValuesDo from "../../../../../../packages/design-system/form/constellation/images/form-default-values-do.png";
import FormDefaultValuesDont from "../../../../../../packages/design-system/form/constellation/images/form-default-values-dont.png";
import FormGroupingDo from "../../../../../../packages/design-system/form/constellation/images/form-grouping-do.png";
import FormGroupingDont from "../../../../../../packages/design-system/form/constellation/images/form-grouping-dont.png";
import FormInputMasksDo from "../../../../../../packages/design-system/form/constellation/images/form-input-masks-do.png";
import FormInputMasksDont from "../../../../../../packages/design-system/form/constellation/images/form-input-masks-dont.png";
import FormMultipleActionButtonsDo from "../../../../../../packages/design-system/form/constellation/images/form-multiple-action-buttons-do.png";
import FormMultipleActionButtonsDont from "../../../../../../packages/design-system/form/constellation/images/form-multiple-action-buttons-dont.png";
import FormOneColumnDo from "../../../../../../packages/design-system/form/constellation/images/form-one-column-do.png";
import FormOneColumnDont from "../../../../../../packages/design-system/form/constellation/images/form-one-column-dont.png";
import * as React from 'react';
export default {
  FormButtonFeedbackDo,
  FormButtonFeedbackDont,
  FormDefaultValuesDo,
  FormDefaultValuesDont,
  FormGroupingDo,
  FormGroupingDont,
  FormInputMasksDo,
  FormInputMasksDont,
  FormMultipleActionButtonsDo,
  FormMultipleActionButtonsDont,
  FormOneColumnDo,
  FormOneColumnDont,
  React
};