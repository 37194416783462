import React, { type ReactNode, useCallback, useContext, useMemo, useReducer } from 'react';

import __noop from '@atlaskit/ds-lib/noop';

import { isVersionAMajor } from '../utils/is-version-a-major';

export type Heading = {
	id: string;
	value: string | ReactNode;
	depth: number;
};

/**
 * __Heading context__
 *
 * Stores a list of headings used to generate local navigation,
 * which provides section links in the right sidebar of the page.
 */
const HeadingContext = React.createContext<{
	headings: Heading[];
	addHeading(heading: Heading): void;
	removeHeading(heading: Heading): void;
}>({
	headings: [],
	addHeading: __noop,
	removeHeading: __noop,
});

export default HeadingContext;

export const useHeadings = () => {
	return useContext(HeadingContext);
};

type HeadingReducerState = Heading[];
type HeadingReducerAction = {
	type: 'add' | 'remove';
	payload: Heading;
};

const headingReducer = (headings: Heading[], action: HeadingReducerAction) => {
	if (action.type === 'add') {
		// Check if heading already exists
		if (headings.some((heading) => heading.id === action.payload.id)) {
			// eslint-disable-next-line no-console
			console.error(`Heading '${action.payload.id}' already exists in the context.`);
			return headings;
		}

		return [...headings, action.payload];
	} else if (action.type === 'remove') {
		return headings.filter((heading) => heading.id !== action.payload.id);
	}
	throw Error('HeadingContentProvider: Unknown action.');
};

/**
 * __Headings context provider__
 *
 * Provides a list of headings used to generate local navigation,
 * which provides section links in the right sidebar of the page.
 */
export const HeadingContextProvider = ({
	children,
	page,
	initialHeadings = [],
}: {
	children: React.ReactNode;
	page?: string;
	initialHeadings?: Heading[];
}) => {
	const [headings, dispatch] = useReducer<React.Reducer<HeadingReducerState, HeadingReducerAction>>(
		headingReducer,
		initialHeadings,
	);

	const addHeading = useCallback(
		(heading: Heading) => {
			// Only show major version headings in the changelog
			if (page === 'changelog') {
				if (
					heading.depth === 3 ||
					(heading.depth === 2 &&
						typeof heading.value === 'string' &&
						!isVersionAMajor(heading.value))
				) {
					return;
				}
				dispatch({
					type: 'add',
					payload: {
						...heading,
						value:
							// Prevents additional text after version numbers being displayed (see avatar changelog for example)
							typeof heading.value === 'string' ? heading.value.split(' ')[0] : heading.value,
					},
				});
				return;
			}

			dispatch({ type: 'add', payload: heading });
		},
		[page],
	);
	const removeHeading = useCallback((heading: Heading) => {
		dispatch({ type: 'remove', payload: heading });
	}, []);

	const value = useMemo(
		() => ({
			headings,
			addHeading,
			removeHeading,
		}),
		[headings, addHeading, removeHeading],
	);

	return <HeadingContext.Provider value={value}>{children}</HeadingContext.Provider>;
};
