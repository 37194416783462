import buttonItemDo from "../../../../../../packages/design-system/menu/constellation/index/images/button-item-do.png";
import buttonItemDont from "../../../../../../packages/design-system/menu/constellation/index/images/button-item-dont.png";
import linkItemDo from "../../../../../../packages/design-system/menu/constellation/index/images/link-item-do.png";
import linkItemDont from "../../../../../../packages/design-system/menu/constellation/index/images/link-item-dont.png";
import linkItemIconDo from "../../../../../../packages/design-system/menu/constellation/index/images/link-item-icon-do.png";
import linkItemIconDont from "../../../../../../packages/design-system/menu/constellation/index/images/link-item-icon-dont.png";
import * as React from 'react';
export default {
  buttonItemDo,
  buttonItemDont,
  linkItemDo,
  linkItemDont,
  linkItemIconDo,
  linkItemIconDont,
  React
};