import React from 'react';

import { Code } from '@atlaskit/code';
import { type Breakpoint, media, UNSAFE_BREAKPOINTS_CONFIG } from '@atlaskit/primitives/responsive';
import { Table, TBody, TD, TH, THead, TR } from '@atlaskit/table/primitives';

import HorizontalScrollContainer from '../horizontal-scroll-container';
import CollapsingTopMargin from '../utils/collapsing-top-margin';

type Direction = 'only' | 'above' | 'below';

interface MediaQueryData {
	media: `media.${Direction}.${Breakpoint}`;
	breakpoint: Breakpoint;
	query: string;
	pixelValue: `${string}px`;
}

const remToPixel = (rem: `${string}rem`): `${string}px` => {
	return `${Number(rem.replace('rem', '')) * 16}px`;
};

const mediaQueriesData: MediaQueryData[] = [];

Object.entries(media).forEach((prams) => {
	const direction = prams[0] as Direction;
	const breakpoints = prams[1];

	Object.entries(breakpoints).forEach((p) => {
		const breakpoint = p[0] as Breakpoint;
		const query = p[1];
		if (query) {
			mediaQueriesData.push({
				media: `media.${direction}.${breakpoint}`,
				breakpoint,
				query,
				pixelValue: remToPixel(UNSAFE_BREAKPOINTS_CONFIG[breakpoint].min),
			});
		}
	});
});

type MediaQueryTableProps = {
	testId?: string;
	rows: MediaQueryData[];
};

const BaseMediaQueriesTable = ({ testId, rows }: MediaQueryTableProps) => {
	return (
		<CollapsingTopMargin>
			<HorizontalScrollContainer>
				<Table testId={testId}>
					<THead>
						<TR isBodyRow={false}>
							<TH>Media</TH>
							<TH>Breakpoint</TH>
							<TH>Query</TH>
							<TH>Pixel Value</TH>
						</TR>
					</THead>
					<TBody>
						{rows.map((row: MediaQueryData) => {
							return (
								<TR key={row.media}>
									<TD>
										<Code>{row.media}</Code>
									</TD>{' '}
									<TD>
										<Code>{row.breakpoint}</Code>
									</TD>
									<TD>
										<Code>{row.query}</Code>
									</TD>
									<TD>
										<Code>{row.pixelValue}</Code>
									</TD>
								</TR>
							);
						})}
					</TBody>
				</Table>
			</HorizontalScrollContainer>
		</CollapsingTopMargin>
	);
};

/**
 * __MediaQueriesTable__
 *
 * Shows tabulated data of media queries that are available to makers, including:
 * - Media Key
 * - Breakpoint
 * - Raw Media Query
 * - Pixel Value Of Breakpoint
 *
 * - [Examples](https://atlassian.design/components/primitives/xcss/examples)
 * - [Code](https://atlassian.design/components/primitives/xcss/code)
 * - [Usage](https://atlassian.design/components/primitives/xcss/usage)
 */
export const MediaQueriesTable = ({ testId }: { testId?: string }) => (
	<BaseMediaQueriesTable testId={testId} rows={mediaQueriesData} />
);
