import DrawerDefaultExample from "../../../../../../packages/design-system/drawer/examples/constellation/drawer-default";
import DrawerWidths from "../../../../../../packages/design-system/drawer/examples/constellation/drawer-widths/index";
import DrawerWidthsDescription from "../../../../../../packages/design-system/drawer/examples/constellation/drawer-widths/description";
import DrawerSurfaceDetection from "../../../../../../packages/design-system/drawer/examples/constellation/drawer-surface-detection";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_default from "!!raw-loader!../../../../../../packages/design-system/drawer/examples/constellation/drawer-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_widthsSLASHindex from "!!raw-loader!../../../../../../packages/design-system/drawer/examples/constellation/drawer-widths/index";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_widthsSLASHdescription from "!!raw-loader!../../../../../../packages/design-system/drawer/examples/constellation/drawer-widths/description";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_surface_detection from "!!raw-loader!../../../../../../packages/design-system/drawer/examples/constellation/drawer-surface-detection";
import { widths } from "../../../../../../packages/design-system/drawer/src/constants";
import * as React from 'react';
export default {
  DrawerDefaultExample,
  DrawerWidths,
  DrawerWidthsDescription,
  DrawerSurfaceDetection,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_widthsSLASHindex,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_widthsSLASHdescription,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_surface_detection,
  widths,
  React
};