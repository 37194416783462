import React, { type FC, type ReactElement, useLayoutEffect, useRef, useState } from 'react';

import useClipboard from 'react-use-clipboard';

import { Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

const baseStyles = xcss({
	display: 'flex',
	margin: 'space.0',
	padding: 'space.0',
	rowGap: 'space.050',
	flexDirection: 'column',
	background: 'none',
	border: 'none',
	cursor: 'pointer',
	overflowWrap: 'anywhere',
	textAlign: 'left',
});

const fixedWidthStyles = xcss({
	width: '96px', // same as Box width 'size.600'
});

const copyMessages = {
	prompt: 'Copy to clipboard',
	success: 'Copied!',
};

interface TokenButtonProps {
	children: ((props: { isHovered?: boolean }) => ReactElement) | ReactElement;
	copyValue?: string;
	isFixedWidth?: boolean;
	variantLabel?: string;
	testId?: string;
	hasTheme?: boolean;
	hasBorder?: boolean;
}

const tokenBorderStyles = xcss({
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderWidth: 'border.width',
	// @ts-expect-error
	paddingBlockEnd: `calc(${token('space.075')} - ${token('border.width')})`,
});

const tokenColorModeStyles = xcss({
	minWidth: '100%',
	maxWidth: '100%',
	padding: 'space.075',
	backgroundColor: 'elevation.surface',
	borderRadius: 'border.radius',
	':focus': {
		backgroundColor: 'elevation.surface',
		borderColor: 'color.border.focused',
	},
	':hover': {
		backgroundColor: 'elevation.surface.hovered',
	},
	':active': {
		backgroundColor: 'elevation.surface.pressed',
	},
});

const TokenButton: FC<TokenButtonProps> = ({
	hasTheme,
	hasBorder,
	children,
	copyValue,
	variantLabel,
	isFixedWidth,
	testId,
}) => {
	const [isHovered, setIsHovered] = useState(false);

	const [isCopied, setCopied] = useClipboard(copyValue || '', {
		successDuration: 1000,
	});

	// There is a bug with tooltip where it doesn't center correctly
	// when the text is changed.
	const updateTooltip = useRef<() => void>();
	useLayoutEffect(() => {
		updateTooltip.current?.();
	}, [isCopied]);

	return (
		<Tooltip
			content={({ update }) => {
				updateTooltip.current = update;
				return isCopied ? copyMessages.success : copyMessages.prompt;
			}}
			position="top"
			delay={0}
		>
			{(tooltipProps) => (
				<Pressable
					xcss={[
						baseStyles,
						isFixedWidth && fixedWidthStyles,
						hasTheme && tokenColorModeStyles,
						hasBorder && tokenBorderStyles,
					]}
					aria-label={variantLabel}
					testId={testId}
					{...tooltipProps}
					onClick={(e) => {
						tooltipProps.onClick(e);
						setCopied();
					}}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					{children && typeof children === 'function' ? children({ isHovered }) : children}
				</Pressable>
			)}
		</Tooltip>
	);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default TokenButton;
