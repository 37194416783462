import tabsConciseDo from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-concise-do.png";
import tabsConciseDont from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-concise-dont.png";
import tabsContentDo from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-content-do.png";
import tabsContentDont from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-content-dont.png";
import tabsContextDo from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-context-do.png";
import tabsContextDont from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-context-dont.png";
import tabsGroupingDo from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-grouping-do.png";
import tabsGroupingDont from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-grouping-dont.png";
import tabsHierarchyDo from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-hierarchy-do.png";
import tabsHierarchyDont from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-hierarchy-dont.png";
import tabsImportanceDo from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-importance-do.png";
import tabsImportanceDont from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-importance-dont.png";
import tabsIndependentDo from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-independent-do.png";
import tabsIndependentDont from "../../../../../../packages/design-system/tabs/constellation/index/images/tabs-independent-dont.png";
import * as React from 'react';
export default {
  tabsConciseDo,
  tabsConciseDont,
  tabsContentDo,
  tabsContentDont,
  tabsContextDo,
  tabsContextDont,
  tabsGroupingDo,
  tabsGroupingDont,
  tabsHierarchyDo,
  tabsHierarchyDont,
  tabsImportanceDo,
  tabsImportanceDont,
  tabsIndependentDo,
  tabsIndependentDont,
  React
};