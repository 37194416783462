/**
 * Helper to create CSS token variables from the merged-tokens for light and dark modes.
 * To be use when token color values need to be displayed in the context of their colorMode and independent
 * of the displayed theme.
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, type SerializedStyles } from '@emotion/react';

import { type ThemeColorModes } from '@atlaskit/tokens';
import tokenNameCssMap from '@atlaskit/tokens/token-names';

import mergedTokens from './merged-tokens';
import { type TransformedTokenMerged } from './types';

const tokenNames = Object.keys(tokenNameCssMap);
type TokenNames = keyof typeof tokenNameCssMap;

type ColorModeTokenMerged = {
	cleanName: TokenNames;
	value: string;
	darkToken: { value: string };
} & TransformedTokenMerged;

export const tokenColorModeValues = mergedTokens
	.filter(
		(t): t is ColorModeTokenMerged =>
			!!t.cleanName &&
			!!t.darkToken?.value &&
			t.darkToken.value !== t.value &&
			tokenNames.includes(t.cleanName),
	)
	.reduce(
		(a, t) =>
			Object.assign(a, {
				[t.cleanName]: { light: t.value, dark: t.darkToken.value },
			}),
		{} as { [K in TokenNames]: { light: string; dark: string } },
	);

export type ThemeColorModeRaw = Record<
	Exclude<ThemeColorModes, 'auto'>,
	{ [K in TokenNames]: string }
>;
const themeColorModeRaw: ThemeColorModeRaw = Object.entries(tokenColorModeValues).reduce(
	(a, [n, { dark, light }]) => {
		const k = tokenNameCssMap[n as TokenNames];
		return {
			light: { ...a.light, [k]: light },
			dark: { ...a.dark, [k]: dark },
		};
	},
	{ light: {}, dark: {} } as ThemeColorModeRaw,
);

export type ThemeColorModeStyles = Record<Exclude<ThemeColorModes, 'auto'>, SerializedStyles>;
const themeColorModeStyles: ThemeColorModeStyles = {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-array-arguments, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	light: css([themeColorModeRaw.light]),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-array-arguments, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	dark: css([themeColorModeRaw.dark]),
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export default themeColorModeStyles;
