import React from 'react';

import { token } from '@atlaskit/tokens';

const AvatarTokens = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<rect fill={token('color.icon.accent.yellow')} height="24" rx="3" width="24" />
		<path
			d="M8.9994 8.116V9.412H11.2314V16H12.6714V9.412H14.9034V8.116H8.9994Z"
			fill={token('color.icon.inverse')}
		/>
	</svg>
);

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default AvatarTokens;
