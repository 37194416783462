import iconVisualNoiseDont from "../../../../../../packages/design-system/icon/constellation/icon-legacy/images/dos-donts/visual-noise-dont.png";
import iconVisualNoiseDo from "../../../../../../packages/design-system/icon/constellation/icon-legacy/images/dos-donts/visual-noise-do.png";
import iconMeaningfulTextDont from "../../../../../../packages/design-system/icon/constellation/icon-legacy/images/dos-donts/meaningful-text-dont.png";
import iconMeaningfulTextDo from "../../../../../../packages/design-system/icon/constellation/icon-legacy/images/dos-donts/meaningful-text-do.png";
import iconCouplingDont from "../../../../../../packages/design-system/icon/constellation/icon-legacy/images/dos-donts/coupling-dont.png";
import iconCouplingDo from "../../../../../../packages/design-system/icon/constellation/icon-legacy/images/dos-donts/coupling-do.png";
import iconConceptDont from "../../../../../../packages/design-system/icon/constellation/icon-legacy/images/dos-donts/concept-dont.png";
import iconConceptDo from "../../../../../../packages/design-system/icon/constellation/icon-legacy/images/dos-donts/concept-do.png";
import * as React from 'react';
export default {
  iconVisualNoiseDont,
  iconVisualNoiseDo,
  iconMeaningfulTextDont,
  iconMeaningfulTextDo,
  iconCouplingDont,
  iconCouplingDo,
  iconConceptDont,
  iconConceptDo,
  React
};