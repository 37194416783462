import React from 'react';

import type { CustomGlyphProps } from '@atlaskit/icon/types';

export default (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.6935 8.47387L11.4185 11.7471C11.3917 11.7739 11.3599 11.7953 11.3249 11.8098C11.2899 11.8243 11.2524 11.8318 11.2145 11.8318C11.1766 11.8318 11.139 11.8243 11.104 11.8098C11.069 11.7953 11.0372 11.7739 11.0105 11.7471L9.65538 10.3939C9.62863 10.367 9.59683 10.3457 9.56183 10.3312C9.52682 10.3166 9.48929 10.3092 9.45138 10.3092C9.41348 10.3092 9.37595 10.3166 9.34094 10.3312C9.30593 10.3457 9.27414 10.367 9.24738 10.3939L7.68923 11.952C7.66238 11.9788 7.64108 12.0106 7.62654 12.0456C7.61201 12.0806 7.60452 12.1181 7.60452 12.156C7.60452 12.1939 7.61201 12.2315 7.62654 12.2665C7.64108 12.3015 7.66238 12.3333 7.68923 12.36L11.0308 15.6923C11.0576 15.7203 11.0899 15.7426 11.1256 15.7578C11.1613 15.773 11.1997 15.7808 11.2385 15.7808C11.2773 15.7808 11.3156 15.773 11.3513 15.7578C11.387 15.7426 11.4193 15.7203 11.4462 15.6923L16.68 10.4603C16.7068 10.4336 16.7282 10.4018 16.7427 10.3668C16.7572 10.3318 16.7647 10.2942 16.7647 10.2563C16.7647 10.2184 16.7572 10.1809 16.7427 10.1459C16.7282 10.1109 16.7068 10.0791 16.68 10.0523L15.1015 8.47387C15.0748 8.44702 15.043 8.42571 15.008 8.41118C14.973 8.39664 14.9354 8.38916 14.8975 8.38916C14.8596 8.38916 14.8221 8.39664 14.7871 8.41118C14.7521 8.42571 14.7203 8.44702 14.6935 8.47387Z"
			fill="#336177"
		/>
		<path
			d="M12 1.69476C9.96182 1.69476 7.96941 2.29915 6.27472 3.43151C4.58003 4.56386 3.25919 6.17332 2.47921 8.05635C1.69923 9.93939 1.49515 12.0114 1.89278 14.0104C2.29041 16.0095 3.27189 17.8457 4.7131 19.2869C6.15431 20.7281 7.99053 21.7096 9.98955 22.1072C11.9886 22.5048 14.0606 22.3008 15.9436 21.5208C17.8267 20.7408 19.4361 19.42 20.5685 17.7253C21.7008 16.0306 22.3052 14.0382 22.3052 12C22.3023 9.26778 21.2156 6.6483 19.2837 4.71633C17.3517 2.78436 14.7322 1.6977 12 1.69476ZM12 4.46399C13.49 4.46399 14.9466 4.90582 16.1856 5.7336C17.4245 6.56138 18.3902 7.73795 18.9605 9.11453C19.5308 10.4911 19.6801 12.0059 19.3895 13.4673C19.099 14.9287 18.3816 16.2712 17.3281 17.3249C16.2746 18.3787 14.9323 19.0964 13.471 19.3873C12.0096 19.6782 10.4948 19.5293 9.11808 18.9593C7.74136 18.3894 6.56456 17.424 5.73648 16.1852C4.90839 14.9465 4.46621 13.49 4.46584 12C4.46584 10.0018 5.25962 8.08547 6.67255 6.67254C8.08547 5.25961 10.0018 4.46584 12 4.46584V4.46399Z"
			fill="#00C3F2"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 1.75385C12.6505 1.75383 13.2996 1.81565 13.9385 1.93846L12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12L1.93846 10.0615C2.39224 7.72498 3.64393 5.61875 5.47934 4.10327C7.31476 2.5878 9.61979 1.75728 12 1.75385Z"
			fill="#248FB4"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.0615 13.9385C21.5466 16.6065 19.9933 18.9609 17.7431 20.4842C15.4929 22.0074 12.7299 22.5747 10.0615 22.0615L12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12L22.0615 13.9385Z"
			fill="#248FB4"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.2462 12C22.2461 12.6505 22.1843 13.2996 22.0615 13.9385L24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0L13.9385 1.93846C16.275 2.39224 18.3812 3.64393 19.8967 5.47934C21.4122 7.31476 22.2427 9.61979 22.2462 12Z"
			fill="#43B02A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.75385 12C1.75386 11.3494 1.81568 10.7004 1.93846 10.0615L0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24L10.0615 22.0615C7.72482 21.6081 5.61839 20.3565 4.10285 18.521C2.58732 16.6855 1.75693 14.3803 1.75385 12Z"
			fill="#FF9200"
		/>
	</svg>
);
