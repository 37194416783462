import React from 'react';

import Icon from '@atlaskit/icon';

import { CATEGORY_NAMES } from '../utils/constants';

import avatarBrand from './icons/avatar-brand';
import avatarComponent from './icons/avatar-components';
import avatarContent from './icons/avatar-content';
import avatarFoundations from './icons/avatar-foundations';
import avatarGetStarted from './icons/avatar-get-started';
import avatarPatterns from './icons/avatar-patterns';
import avatarResources from './icons/avatar-resources';
import avatarTokens from './icons/avatar-tokens';

const getIcon = (heading: string = '') => {
	switch (heading.toLowerCase()) {
		case CATEGORY_NAMES.TOKENS:
			return avatarTokens;
		case CATEGORY_NAMES.BRAND:
			return avatarBrand;
		case CATEGORY_NAMES.COMPONENTS:
			return avatarComponent;
		case CATEGORY_NAMES.CONTENT:
			return avatarContent;
		case CATEGORY_NAMES.FOUNDATIONS:
			return avatarFoundations;
		case CATEGORY_NAMES.RESOURCES:
			return avatarResources;
		case CATEGORY_NAMES.PATTERNS:
			return avatarPatterns;
		case CATEGORY_NAMES.GET_STARTED:
			return avatarGetStarted;
		default:
			return null;
	}
};

interface HeadingIconProps {
	heading: string;
}

export default function HeadingIcon({ heading }: HeadingIconProps) {
	const glyph = getIcon(heading);
	// @ts-expect-error should this be dangerouslySetInnerGlyph?
	return <Icon glyph={glyph} label="" size="medium" />;
}
