import BleedDefault from "../../../../../../packages/design-system/primitives/examples/constellation/bleed/default";
import BleedBlock from "../../../../../../packages/design-system/primitives/examples/constellation/bleed/block";
import BleedInline from "../../../../../../packages/design-system/primitives/examples/constellation/bleed/inline";
import BleedAll from "../../../../../../packages/design-system/primitives/examples/constellation/bleed/all";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbleedSLASHdefault from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/bleed/default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbleedSLASHblock from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/bleed/block";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbleedSLASHinline from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/bleed/inline";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbleedSLASHall from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/bleed/all";
import { CodeDocsHeader } from '@af/design-system-docs-ui';
import * as React from 'react';
export default {
  BleedDefault,
  BleedBlock,
  BleedInline,
  BleedAll,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbleedSLASHdefault,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbleedSLASHblock,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbleedSLASHinline,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbleedSLASHall,
  CodeDocsHeader,
  React
};