import PageHeaderDefault from "../../../../../../packages/design-system/page-header/examples/constellation/page-header-default";
import PageHeaderComplex from "../../../../../../packages/design-system/page-header/examples/constellation/page-header-complex";
import PageHeaderCustomTitle from "../../../../../../packages/design-system/page-header/examples/constellation/page-header-custom-title";
import PageHeaderFocusHeading from "../../../../../../packages/design-system/page-header/examples/constellation/page-header-focus-heading";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_header_default from "!!raw-loader!../../../../../../packages/design-system/page-header/examples/constellation/page-header-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_header_complex from "!!raw-loader!../../../../../../packages/design-system/page-header/examples/constellation/page-header-complex";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_header_custom_title from "!!raw-loader!../../../../../../packages/design-system/page-header/examples/constellation/page-header-custom-title";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_header_focus_heading from "!!raw-loader!../../../../../../packages/design-system/page-header/examples/constellation/page-header-focus-heading";
import * as React from 'react';
export default {
  PageHeaderDefault,
  PageHeaderComplex,
  PageHeaderCustomTitle,
  PageHeaderFocusHeading,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_header_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_header_complex,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_header_custom_title,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpage_header_focus_heading,
  React
};