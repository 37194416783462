import React, { useEffect, useState } from 'react';

import { graphql, StaticQuery } from 'gatsby';

import { Status } from '@af/design-system-docs-ui';
import { useSetColorMode, useSetTheme } from '@atlaskit/app-provider';
import Button from '@atlaskit/button/new';
import DropdownMenu, { DropdownItemRadio, DropdownItemRadioGroup } from '@atlaskit/dropdown-menu';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';

import { LOCAL_STORAGE_THEME_KEY, LOCAL_STORAGE_TYPOGRAPHY_THEME_KEY } from '../../utils/constants';

type AppTheme = 'light' | 'dark' | 'auto';

const themeData = {
	light: {
		label: 'Light',
		description: null,
		imageSrc: 'modeLightThumb',
		status: null,
	},
	dark: {
		label: 'Dark',
		description: null,
		imageSrc: 'modeDarkThumb',
		status: null,
	},
	auto: {
		label: 'Match browser',
		description: null,
		imageSrc: 'modeLightThumb',
		status: null,
	},
} as const;

const typographyThemeData = {
	'typography-adg3': {
		label: 'Legacy',
		description: 'Original typography',
		imageSrc: 'modeTypographyLegacyThumb',
		status: null,
	},
	'typography-modernized': {
		label: 'Modernized',
		description: null,
		imageSrc: 'modeTypographyModernizedThumb',
		status: 'beta',
	},
} as const;

type TypographyTheme = keyof typeof typographyThemeData;

const ThemeImg = ({ src }: { src: string }) => (
	<Box
		alt="alt"
		as="img"
		src={src}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		xcss={xcss({
			width: '4rem',
			height: '3rem',
			borderRadius: 'border.radius',
		})}
	/>
);

export default function Settings() {
	const [isOpen, setIsOpen] = useState(false);
	const [appTheme, setAppTheme] = useState<AppTheme>('light');
	const [appTypographyTheme, setAppTypographyTheme] = useState<TypographyTheme>('typography-adg3');
	const [systemTheme, setSystemTheme] = useState<'light' | 'dark'>('light');

	const setTheme = useSetTheme();
	const setColorMode = useSetColorMode();

	// Color theme
	useEffect(() => {
		const localStorageTheme = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
		if (
			localStorageTheme === 'light' ||
			localStorageTheme === 'dark' ||
			localStorageTheme === 'auto'
		) {
			// If a light/dark/auto theme has previously been set, use that theme
			setAppTheme(localStorageTheme);
			setColorMode(localStorageTheme);
		} else if (localStorageTheme === 'none') {
			// If 'none' has previously been set, override it to light since this is closest to no tokens
			// Previously we had a "no tokens" option, however there is no reason not to load tokens anymore
			setAppTheme('light');
		} else {
			// If nothing set previously, set default theme to 'auto' and load tokens
			setAppTheme('auto');
			setColorMode('auto');
		}
	}, [setColorMode]);

	// Typography theme
	useEffect(() => {
		const localStorageTypographyTheme = localStorage.getItem(
			LOCAL_STORAGE_TYPOGRAPHY_THEME_KEY,
		) as TypographyTheme;
		if (
			localStorageTypographyTheme === 'typography-adg3' ||
			localStorageTypographyTheme === 'typography-modernized'
		) {
			setAppTypographyTheme(localStorageTypographyTheme);
			setTheme({ typography: localStorageTypographyTheme });
		} else {
			setAppTypographyTheme('typography-adg3');
			setTheme({ typography: 'typography-adg3' });
		}
	}, [setTheme]);

	useEffect(() => {
		const mql = window.matchMedia('(prefers-color-scheme: dark)');
		const listener = (e: MediaQueryListEvent) => setSystemTheme(e.matches ? 'dark' : 'light');

		mql.addEventListener('change', listener);
		return () => mql.removeEventListener('change', listener);
	}, []);

	const handleAppThemeChange = (theme: AppTheme | TypographyTheme) => {
		// Color theme
		if (theme === 'light' || theme === 'dark' || theme === 'auto') {
			setAppTheme(theme);
			localStorage.setItem(LOCAL_STORAGE_THEME_KEY, theme);
			setColorMode(theme || appTheme);
		}

		// Typography theme
		if (theme === 'typography-adg3' || theme === 'typography-modernized') {
			setAppTypographyTheme(theme);
			localStorage.setItem(LOCAL_STORAGE_TYPOGRAPHY_THEME_KEY, theme);
			setTheme({ typography: theme });
		}
	};

	return (
		<StaticQuery
			query={graphql`
				query modeLightThumb {
					modeLightThumb: file(relativePath: { eq: "theme-thumbs/LightThumb.svg" }) {
						publicURL
					}
					modeDarkThumb: file(relativePath: { eq: "theme-thumbs/DarkThumb.svg" }) {
						publicURL
					}
					modeTypographyLegacyThumb: file(
						relativePath: { eq: "theme-thumbs/TypographyLegacyThumb.svg" }
					) {
						publicURL
					}
					modeTypographyModernizedThumb: file(
						relativePath: { eq: "theme-thumbs/TypographyModernizedThumb.svg" }
					) {
						publicURL
					}
				}
			`}
			render={(data) => (
				<DropdownMenu<HTMLButtonElement>
					placement="bottom-end"
					isOpen={isOpen}
					onOpenChange={() => setIsOpen(!isOpen)}
					trigger={({ triggerRef, onClick, ...triggerProps }) => (
						<Button
							ref={triggerRef}
							onClick={(e) => {
								// @ts-expect-error
								onClick(e);
								setIsOpen(!isOpen);
							}}
							{...triggerProps}
						>
							Theme
						</Button>
					)}
				>
					<DropdownItemRadioGroup id="color-theme" title="Color theme">
						{Object.entries(themeData).map(([theme, metadata]) => (
							<DropdownItemRadio
								id={theme}
								key={theme}
								isSelected={appTheme === theme}
								onClick={() => handleAppThemeChange(theme as AppTheme)}
							>
								<Inline space="space.150" alignBlock="center">
									<ThemeImg
										src={
											data[
												themeData[
													theme === 'auto' ? systemTheme : (theme as keyof typeof themeData)
												].imageSrc
											].publicURL
										}
									/>
									<Stack>
										<Text weight="medium">
											{metadata.label} {metadata.status && <Status type={metadata.status} />}
										</Text>
										<Text size="UNSAFE_small" color="color.text.subtle">
											{metadata.description}
										</Text>
									</Stack>
								</Inline>
							</DropdownItemRadio>
						))}
					</DropdownItemRadioGroup>
					<DropdownItemRadioGroup id="typography-theme" title="Typography theme">
						{Object.entries(typographyThemeData).map(([theme, metadata]) => (
							<DropdownItemRadio
								id={theme}
								key={theme}
								isSelected={appTypographyTheme === theme}
								onClick={() => handleAppThemeChange(theme as TypographyTheme)}
							>
								<Inline space="space.150" alignBlock="center">
									<ThemeImg
										src={
											data[typographyThemeData[theme as keyof typeof typographyThemeData].imageSrc]
												.publicURL
										}
									/>
									<Stack>
										<Text weight="medium">
											{metadata.label} {metadata.status && <Status type={metadata.status} />}
										</Text>
										<Text size="UNSAFE_small" color="color.text.subtle">
											{metadata.description}
										</Text>
									</Stack>
								</Inline>
							</DropdownItemRadio>
						))}
					</DropdownItemRadioGroup>
				</DropdownMenu>
			)}
		/>
	);
}
