import React from 'react';

import { Code } from '@atlaskit/code';
import { Box } from '@atlaskit/primitives';
import { Table, TBody, TD, TH, THead, TR } from '@atlaskit/table/primitives';
import { spacing as spacingScale } from '@atlaskit/tokens/tokens-raw';

import HorizontalScrollContainer from '../horizontal-scroll-container';
import CollapsingTopMargin from '../utils/collapsing-top-margin';

// This should occur in tokens
const activeTokens = spacingScale
	.filter((t) => t.attributes.state === 'active')
	.filter((t) => t.attributes.group === 'spacing')
	.filter((t) => !t.name.includes('.negative'))
	.map((t) => {
		const rawPixelValue = Number(t.value.split('px')[0]);
		return Object.assign(
			{},
			{
				multiplier: `${rawPixelValue / 8}×`,
				remValue: `${rawPixelValue / 16}rem`,
			},
			t,
		);
	})
	.sort((a, b) => Number(a.value.split('px')[0]) - Number(b.value.split('px')[0]));

type SpacingTokensTableProps = {
	testId?: string;
};

/**
 * __Spacing tokens table__
 */
export const SpacingTokensTable = ({ testId }: SpacingTokensTableProps) => {
	return (
		<CollapsingTopMargin>
			<HorizontalScrollContainer>
				<Table testId={testId}>
					<THead>
						<TR isBodyRow={false}>
							<TH width="12ch">Token</TH>
							<TH width="18ch" align="number">
								Base unit multiplier
							</TH>
							<TH width="9ch" align="number">
								REM
							</TH>
							<TH align="number">Pixels</TH>
							<TH>Visual representation</TH>
						</TR>
					</THead>
					<TBody>
						{activeTokens.map((t) => {
							return (
								<TR key={t.cleanName}>
									<TD>
										<Code>{t.cleanName}</Code>
									</TD>
									<TD align="number">{t.multiplier}</TD>
									<TD align="number">{t.remValue}</TD>
									<TD align="number">{t.value}</TD>
									<TD>
										<Box
											paddingBlock="space.100"
											backgroundColor="color.background.accent.purple.subtle"
											style={{
												width: t.remValue,
											}}
										/>
									</TD>
								</TR>
							);
						})}
					</TBody>
				</Table>
			</HorizontalScrollContainer>
		</CollapsingTopMargin>
	);
};
