import React from 'react';

import { token } from '@atlaskit/tokens';

const AvatarFoundations = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<rect fill={token('color.icon.accent.teal')} height="24" rx="3" width="24" />
		<path
			d="m14.856 13.228v-1.296h-3.48v-2.52h3.84v-1.296h-5.28v7.884h1.44v-2.772z"
			fill={token('color.icon.inverse')}
		/>
	</svg>
);

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default AvatarFoundations;
