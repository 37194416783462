// @ts-check

import React from 'react';
import ReactDOM from 'react-dom';
import { FlagsProvider } from '@atlaskit/flag';

import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';
import ErrorBoundary from './src/__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/error-boundary';
import AnalyticsProvider from './src/analytics/analytics-provider';
import GlobalError from './src/components/global-error';
import featureFlags, { featureFlagsPlatform } from './src/feature-flags';

/**
 * @type {import('gatsby').GatsbyBrowser['wrapRootElement']}
 */
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
	return (
		<AnalyticsProvider>
			<FlagsProvider>
				<ErrorBoundary fallback={<GlobalError />}>{element}</ErrorBoundary>
			</FlagsProvider>
		</AnalyticsProvider>
	);
};

export { wrapPageElement } from './src/utils/wrap-page-element';

/**
 * @type {import('gatsby').GatsbyBrowser['onClientEntry']}
 */
export const onClientEntry = () => {
	try {
		setBooleanFeatureFlagResolver((flagKey) => {
			return featureFlagsPlatform[flagKey] || false;
		});

		Object.entries(featureFlags).forEach(([key, value]) => {
			// Initialize if it doesn't exist
			// OR override if the default flag state is true
			// Don't set the item if the user has enabled a flag that is disabled by default
			if (localStorage.getItem(`ff-${key}`) === null || value === true) {
				localStorage.setItem(`ff-${key}`, value);
			}
		});

		// Add a polyfill for Promise.allSettled
		if (Promise && !Promise.allSettled) {
			Promise.allSettled = (promises) => {
				return Promise.all(
					promises.map((promise) => {
						return promise
							.then((value) => {
								return { state: 'fulfilled', value };
							})
							.catch((reason) => {
								return { state: 'rejected', reason };
							});
					}),
				);
			};
		}
	} catch (err) {
		console.error(err);
	}
};

export const onInitialClientRender = async () => {
	if (process.env.NODE_ENV === 'development' && process.env.GATSBY_AXE_ENABLED === 'TRUE') {
		const { default: axe } = await import(
			/* webpackChunkName: "@atlaskit-internal_axe-core-for-dev-env" */
			'@axe-core/react'
		);
		axe(React, ReactDOM, 1000, {});
	}
};
