import React from 'react';

import { TypographyTable } from '../index';

const codeValues = [
	{
		previewText: 'Aa',
		tokenName: 'font.code',
		// NOTE: These are hardcoded for communication purposes and are purposefully not 100% accurate to the actual token value.
		fontSize: '12 px',
		lineHeight: '20 px',
		fontWeight: 400,
	},
];

/**
 * __Typography code table__
 */
export const TypographyCodeTable = () => {
	return (
		<TypographyTable
			values={codeValues}
			typographyTheme="typography-modernized"
			testId="typography-code-table"
		/>
	);
};
