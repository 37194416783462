import PaginationDefault from "../../../../../../packages/design-system/pagination/examples/constellation/pagination-default";
import PaginationTruncation from "../../../../../../packages/design-system/pagination/examples/constellation/pagination-truncation";
import PaginationDefaultSelectedIndex from "../../../../../../packages/design-system/pagination/examples/constellation/pagination-default-selected-index";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpagination_default from "!!raw-loader!../../../../../../packages/design-system/pagination/examples/constellation/pagination-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpagination_truncation from "!!raw-loader!../../../../../../packages/design-system/pagination/examples/constellation/pagination-truncation";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpagination_default_selected_index from "!!raw-loader!../../../../../../packages/design-system/pagination/examples/constellation/pagination-default-selected-index";
import * as React from 'react';
export default {
  PaginationDefault,
  PaginationTruncation,
  PaginationDefaultSelectedIndex,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpagination_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpagination_truncation,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHpagination_default_selected_index,
  React
};