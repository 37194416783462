import HeadingDefault from "../../../../../../packages/design-system/heading/examples/constellation/heading-default";
import HeadingAsProp from "../../../../../../packages/design-system/heading/examples/constellation/heading-as-prop";
import HeadingColorInverse from "../../../../../../packages/design-system/heading/examples/constellation/heading-color-inverse";
import HeadingColorProp from "../../../../../../packages/design-system/heading/examples/constellation/heading-color-prop";
import HeadingSpacing from "../../../../../../packages/design-system/heading/examples/constellation/heading-spacing";
import HeadingProvider from "../../../../../../packages/design-system/heading/examples/constellation/heading-provider";
import HeadingMigration from "../../../../../../packages/design-system/heading/examples/constellation/heading-migration";
import HeadingNew from "../../../../../../packages/design-system/heading/examples/constellation/heading-new";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_default from "!!raw-loader!../../../../../../packages/design-system/heading/examples/constellation/heading-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_as_prop from "!!raw-loader!../../../../../../packages/design-system/heading/examples/constellation/heading-as-prop";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_color_inverse from "!!raw-loader!../../../../../../packages/design-system/heading/examples/constellation/heading-color-inverse";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_color_prop from "!!raw-loader!../../../../../../packages/design-system/heading/examples/constellation/heading-color-prop";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_spacing from "!!raw-loader!../../../../../../packages/design-system/heading/examples/constellation/heading-spacing";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_provider from "!!raw-loader!../../../../../../packages/design-system/heading/examples/constellation/heading-provider";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_migration from "!!raw-loader!../../../../../../packages/design-system/heading/examples/constellation/heading-migration";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_new from "!!raw-loader!../../../../../../packages/design-system/heading/examples/constellation/heading-new";
import * as React from 'react';
export default {
  HeadingDefault,
  HeadingAsProp,
  HeadingColorInverse,
  HeadingColorProp,
  HeadingSpacing,
  HeadingProvider,
  HeadingMigration,
  HeadingNew,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_as_prop,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_color_inverse,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_color_prop,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_spacing,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_provider,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_migration,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHheading_new,
  React
};