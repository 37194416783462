import CheckIcon from "../../../../../../packages/pragmatic-drag-and-drop/documentation/constellation/assets/check-icon";
import CrossIcon from "../../../../../../packages/pragmatic-drag-and-drop/documentation/constellation/assets/cross-icon";
import QuestionIcon from "../../../../../../packages/pragmatic-drag-and-drop/documentation/constellation/assets/question-icon";
import ResultText from "../../../../../../packages/pragmatic-drag-and-drop/documentation/constellation/assets/result-text";
import * as React from 'react';
export default {
  CheckIcon,
  CrossIcon,
  QuestionIcon,
  ResultText,
  React
};