import ChessboardStarterCode from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-starter-code";
import KingInPlace from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/draggable-piece-in-place";
import KingWithState from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/draggable-piece-with-state";
import ChessboardDraggable from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-draggable";
import ChessboardDropTarget from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-drop-target";
import ChessboardColoredDropTargets from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-colored-drop-targets";
import ChessboardDropTargetsCanDrop from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-drop-targets-can-drop";
import ChessboardWithMonitor from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-monitor";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_starter_code from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-starter-code";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHdraggable_piece_in_place from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/draggable-piece-in-place";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHdraggable_piece_with_state from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/draggable-piece-with-state";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_draggable from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-draggable";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_drop_target from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-drop-target";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_colored_drop_targets from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-colored-drop-targets";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_drop_targets_can_drop from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-drop-targets-can-drop";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_monitor from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/pieces/getting-started/chessboard-monitor";
import * as React from 'react';
export default {
  ChessboardStarterCode,
  KingInPlace,
  KingWithState,
  ChessboardDraggable,
  ChessboardDropTarget,
  ChessboardColoredDropTargets,
  ChessboardDropTargetsCanDrop,
  ChessboardWithMonitor,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_starter_code,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHdraggable_piece_in_place,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHdraggable_piece_with_state,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_draggable,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_drop_target,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_colored_drop_targets,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_drop_targets_can_drop,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHpiecesSLASHgetting_startedSLASHchessboard_monitor,
  React
};