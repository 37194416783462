import AutoDismissFlagDefault from "../../../../../../packages/design-system/flag/examples/constellation/auto-dismiss-flag-default";
import AutoDismissFlagInfo from "../../../../../../packages/design-system/flag/examples/constellation/auto-dismiss-flag-info";
import AutoDismissFlagSuccess from "../../../../../../packages/design-system/flag/examples/constellation/auto-dismiss-flag-success";
import AutoDismissFlagActions from "../../../../../../packages/design-system/flag/examples/constellation/auto-dismiss-flag-actions";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHauto_dismiss_flag_default from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/auto-dismiss-flag-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHauto_dismiss_flag_info from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/auto-dismiss-flag-info";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHauto_dismiss_flag_success from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/auto-dismiss-flag-success";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHauto_dismiss_flag_actions from "!!raw-loader!../../../../../../packages/design-system/flag/examples/constellation/auto-dismiss-flag-actions";
import * as React from 'react';
export default {
  AutoDismissFlagDefault,
  AutoDismissFlagInfo,
  AutoDismissFlagSuccess,
  AutoDismissFlagActions,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHauto_dismiss_flag_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHauto_dismiss_flag_info,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHauto_dismiss_flag_success,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHauto_dismiss_flag_actions,
  React
};