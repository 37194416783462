import React from 'react';

import { token } from '@atlaskit/tokens';

const AvatarGetStarted = () => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<rect fill={token('color.icon.accent.purple')} height="24" rx="3" width="24" />
		<path
			d="m15.38 15.556v-3.984h-3.168v1.2h1.824v1.932c-.396.096-.816.12-1.116.12-2.088 0-3.072-1.116-3.072-2.784 0-1.692.972-2.76 3.168-2.76.708 0 1.476.108 2.112.384v-1.32c-.72-.276-1.524-.36-2.184-.36-2.844 0-4.524 1.524-4.524 4.092 0 2.388 1.476 4.044 4.224 4.044.912 0 2.1-.18 2.736-.564z"
			fill={token('color.icon.inverse')}
		/>
	</svg>
);

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default AvatarGetStarted;
