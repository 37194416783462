export const lorem = [
	'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	'Suspendisse tincidunt vehicula eleifend.',
	'Nunc tristique nisi tortor, at pretium purus interdum sed.',
	'Sed vel augue sit amet sapien elementum bibendum. Aenean aliquam elementum dui, quis euismod metus ultrices ut.',
	'Curabitur est sapien, feugiat vel est eget, molestie suscipit nibh.',
	'Nunc libero orci, lacinia id orci aliquam, pharetra facilisis leo.',
	'Quisque et turpis nec lacus luctus ultrices quis vel nisi.',
	'Cras maximus ex lorem, sit amet bibendum nibh placerat eu.',
	'In hac habitasse platea dictumst. ',
	'Duis molestie sem vel ante varius, rhoncus pretium arcu dictum.',
];
