import React, { type ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Box, Pressable, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';

const COPY_MESSAGE = {
	PROMPT: 'Copy to clipboard',
	SUCCESS: 'Copied',
	FAILURE: 'Copy failed',
};

const copyButtonStyles = xcss({
	padding: 'space.0',
	background: 'none',
	border: 'none',
});

const textStyles = xcss({
	display: 'inline-block',
	margin: 'space.0',
	paddingBlock: 'space.0',
	paddingInline: 'space.100',
	textAlign: 'center',
});

/**
 * __Token__
 *
 * A copy and paste block on the result panel.
 *
 */
const CopyPasteBlock = ({
	text,
	renderWrapper,
}: {
	text: string;
	renderWrapper: (copyContent: ReactNode) => JSX.Element;
}) => {
	const [copyMessage, setCopyMessage] = useState<string>(COPY_MESSAGE.PROMPT);

	const handleSuccess = useCallback(() => {
		setCopyMessage(COPY_MESSAGE.SUCCESS);
	}, [setCopyMessage]);

	const handleError = useCallback(() => {
		setCopyMessage(COPY_MESSAGE.FAILURE);
	}, [setCopyMessage]);

	const onCopy = () => {
		try {
			navigator.clipboard.writeText(text).then(handleSuccess, handleError);
			setTimeout(() => {
				resetPrompt();
			}, 1000);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('Unable to copy text');
		}
	};
	const resetPrompt = () => setCopyMessage(COPY_MESSAGE.PROMPT);

	const renderCopyContent = () => (
		<Box as="span" xcss={textStyles}>
			{text}
		</Box>
	);

	const updateTooltip = useRef<() => void>();
	useLayoutEffect(() => {
		updateTooltip.current?.();
	}, [copyMessage]);

	return (
		<Tooltip
			content={({ update }) => {
				updateTooltip.current = update;
				return copyMessage;
			}}
			position="top"
			onHide={resetPrompt}
		>
			{(tooltipProps) => (
				<Pressable
					xcss={copyButtonStyles}
					{...tooltipProps}
					onClick={(e) => {
						tooltipProps.onClick(e);
						onCopy();
					}}
				>
					{renderWrapper(renderCopyContent())}
				</Pressable>
			)}
		</Tooltip>
	);
};

export default CopyPasteBlock;
