import SectionMessage from '@atlaskit/section-message';
import MotionFadeOutSingleElement from "../../../../../../packages/design-system/motion/examples/constellation/motion-fade-out-single-element";
import MotionSlideIn from "../../../../../../packages/design-system/motion/examples/constellation/motion-slide-in";
import MotionZoomIn from "../../../../../../packages/design-system/motion/examples/constellation/motion-zoom-in";
import MotionShrinkOut from "../../../../../../packages/design-system/motion/examples/constellation/motion-shrink-out";
import MotionFadeInListOfElements from "../../../../../../packages/design-system/motion/examples/constellation/motion-fade-in-list-of-elements";
import MotionFadeInGridOfElements from "../../../../../../packages/design-system/motion/examples/constellation/motion-fade-in-grid-of-elements";
import MotionFadeBetweenElements from "../../../../../../packages/design-system/motion/examples/constellation/motion-fade-between-elements";
import MotionFadeOutListOfElements from "../../../../../../packages/design-system/motion/examples/constellation/motion-fade-out-list-of-elements";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_fade_out_single_element from "!!raw-loader!../../../../../../packages/design-system/motion/examples/constellation/motion-fade-out-single-element";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_slide_in from "!!raw-loader!../../../../../../packages/design-system/motion/examples/constellation/motion-slide-in";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_zoom_in from "!!raw-loader!../../../../../../packages/design-system/motion/examples/constellation/motion-zoom-in";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_shrink_out from "!!raw-loader!../../../../../../packages/design-system/motion/examples/constellation/motion-shrink-out";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_fade_in_list_of_elements from "!!raw-loader!../../../../../../packages/design-system/motion/examples/constellation/motion-fade-in-list-of-elements";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_fade_in_grid_of_elements from "!!raw-loader!../../../../../../packages/design-system/motion/examples/constellation/motion-fade-in-grid-of-elements";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_fade_between_elements from "!!raw-loader!../../../../../../packages/design-system/motion/examples/constellation/motion-fade-between-elements";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_fade_out_list_of_elements from "!!raw-loader!../../../../../../packages/design-system/motion/examples/constellation/motion-fade-out-list-of-elements";
import * as React from 'react';
export default {
  SectionMessage,
  MotionFadeOutSingleElement,
  MotionSlideIn,
  MotionZoomIn,
  MotionShrinkOut,
  MotionFadeInListOfElements,
  MotionFadeInGridOfElements,
  MotionFadeBetweenElements,
  MotionFadeOutListOfElements,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_fade_out_single_element,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_slide_in,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_zoom_in,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_shrink_out,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_fade_in_list_of_elements,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_fade_in_grid_of_elements,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_fade_between_elements,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmotion_fade_out_list_of_elements,
  React
};