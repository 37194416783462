import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import FooterContent from '../../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/content/footer-content';

const footerStyles = xcss({
	backgroundColor: 'elevation.surface',
	gridArea: 'footer',
});

type FooterProps = {
	id?: string;
};

/**
 * __Footer__
 *
 * The site footer.
 */
const Footer = ({ id }: FooterProps) => {
	return (
		<Box as="footer" id={id} xcss={footerStyles}>
			<FooterContent />
		</Box>
	);
};

export default Footer;
