/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment, useContext } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { Code } from '@atlaskit/code';
import { Box, Stack, Text } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';

import {
	type ActiveTheme,
	type TokenColumnWidth,
	type TransformedTokenGrouped,
	type TransformedTokenMerged,
} from '../types';

import TokenButtonValue from './token-button-value';
import { Font } from './token-button-variants';
import TokenDescription from './token-description';
import TokenLifecycle from './token-lifecycle';
import TokenName from './token-name';
import { TableColumnsContext, tableTokenColumnWidthStyles } from './token-table';

interface TokenDefinitionProps {
	token?: TransformedTokenGrouped;
	shouldHideDescription?: boolean;
	isLoading?: boolean;
	testId?: string;
}

export type TokenValue = {
	name: string;
	value: TransformedTokenMerged['value'];
	attributes: TransformedTokenMerged['attributes'];
	original: TransformedTokenMerged['original'];
	testId: string;
	forceTheme?: ActiveTheme;
	width: TokenColumnWidth;
};

const tokenCellStyles = css({
	width: '80%',
	[media.below.sm]: {
		width: '100%',
	},
});

const resetTableDefaultStyles = css({
	padding: 0,
});

/**
 * Represents a single token consisting of token name, description, and its value(s).
 *
 * Values can be a single value for spacing, or two values (light and dark) for color.
 *
 * For tokens with extensions, the description for the token grouping is rendered by `TokenRow` for the whole grouping (rather than for each definition).
 */
const TokenDefinition = ({
	token,
	shouldHideDescription,
	isLoading,
	testId,
}: TokenDefinitionProps) => {
	const columns = useContext(TableColumnsContext);

	if (!token) {
		return null;
	}

	const group = token.attributes.group;
	const isNumericToken = group === 'spacing' || group === 'shape';

	const pixelValue = token.attributes.state === 'active' && isNumericToken && token.value;

	const tokenValues = columns.slice(1).map(({ label, forceTheme, width = 'medium' }) => {
		let t;
		if (!isNumericToken && forceTheme?.colorMode === 'dark' && !!token.darkToken) {
			t = token.darkToken;
		} else if (
			!isNumericToken &&
			forceTheme?.typography === 'typography-modernized' &&
			!!token.modernizedToken
		) {
			t = token.modernizedToken;
		} else {
			t = token;
		}

		const value = isNumericToken ? pixelValue : t.value;
		const testValue =
			group === 'typography' ? t.cleanName : isNumericToken ? pixelValue : t.original.value;
		return {
			name: label,
			value,
			attributes: t.attributes,
			original: t.original,
			testId:
				testId &&
				`${testId}-token-item-value-${
					isLoading || pixelValue === undefined ? 'loading' : testValue
				}`,
			forceTheme,
			width,
		} as TokenValue;
	});

	// Don't show the TokenButton for these groups as they have a static preview and no value to copy
	const staticPreviewGroups = ['typography', 'fontWeight', 'fontFamily'];

	const isFontToken =
		group === 'typography' &&
		(token.name.includes('font.heading') ||
			token.name.includes('font.body') ||
			token.name.includes('font.code'));

	return (
		<Fragment>
			<td css={[resetTableDefaultStyles, tokenCellStyles]}>
				<Box>
					<Stack space="space.150">
						<Stack space="space.100">
							<TokenName name={token.name} attributes={token.attributes} />
							{!shouldHideDescription && <TokenDescription transformedToken={token} />}
							{!shouldHideDescription && isFontToken && (
								<Text weight="semibold">
									Token is applied using CSS <Code>font</Code>, not <Code>font-size</Code>.
								</Text>
							)}
						</Stack>
						{!shouldHideDescription && <TokenLifecycle transformedToken={token} />}
					</Stack>
				</Box>
			</td>
			{tokenValues.map((tokenValue) => (
				<td
					key={tokenValue.name}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					css={[resetTableDefaultStyles, tableTokenColumnWidthStyles[tokenValue.width]]}
				>
					{staticPreviewGroups.includes(tokenValue.attributes.group) ? (
						<Font tokenValue={tokenValue} tokenOriginal={token} />
					) : (
						<TokenButtonValue tokenValue={tokenValue} tokenOriginal={token} />
					)}
				</td>
			))}
		</Fragment>
	);
};

export default TokenDefinition;
