import React, { memo, useCallback, useRef, useState } from 'react';

import { Flex, Pressable, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import type { IconData, NewIconData } from '../types';

export type LegacyIconCellProps = {
	isLegacy: true;
	icon: IconData;
	testId?: string;
	onIconClick?: (icon: IconData) => void;
	isSelected?: boolean;
};

export type NewIconCellProps = {
	isLegacy: false;
	icon: NewIconData;
	testId?: string;
	onIconClick?: (icon: NewIconData) => void;
	isSelected?: boolean;
};

type IconCellProps = LegacyIconCellProps | NewIconCellProps;

const buttonStyles = xcss({
	display: 'flex',
	width: '120px',
	height: 'auto',
	paddingInline: 'space.100',
	paddingBlockStart: 'space.100',
	paddingBlockEnd: 'space.200',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexDirection: 'column',
	backgroundColor: 'color.background.neutral.subtle',
	borderRadius: 'border.radius.050',
	borderStyle: 'solid',
	borderWidth: 'border.width',
	':active': {
		backgroundColor: 'color.background.neutral.pressed',
	},
});

const buttonHoverStyles = xcss({
	backgroundColor: 'color.background.neutral.hovered',
});

const buttonSelectedStyles = xcss({
	backgroundColor: 'color.background.selected',

	':hover': {
		backgroundColor: 'color.background.selected.hovered',
	},
});

/**
 * __Icon cell__
 *
 * A single cell which is used in the grid of icons.
 * Contains the icon, click/hover behaviour, popup, and tooltip.
 */
const IconCell = memo(({ testId, isSelected, ...props }: IconCellProps) => {
	const [isMouseOver, setIsMouseOver] = useState(false);
	const isMouseOverRef = useRef(isMouseOver);
	isMouseOverRef.current = isMouseOver;

	const onButtonClick = useCallback(
		(evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
			// copy to clipboard
			evt.stopPropagation();

			//Required for typescript to identify whether props is LegacyIconCellProps or IconCellProps
			props.isLegacy ? props.onIconClick?.(props.icon) : props.onIconClick?.(props.icon);
		},
		[props],
	);

	const mouseEnterHandler = () => {
		setIsMouseOver(true);
	};

	const mouseLeaveHandler = () => {
		setIsMouseOver(false);
	};

	const icon = props.isLegacy ? (
		<props.icon.component
			label=""
			primaryColor={isSelected ? token('color.border.selected') : token('color.icon')}
		/>
	) : (
		<props.icon.component
			label=""
			color={isSelected ? token('color.icon.selected') : 'currentColor'}
			spacing="spacious"
		/>
	);

	return (
		<span data-testid={testId}>
			<Pressable
				style={{ borderColor: isSelected ? token('color.border.selected') : 'transparent' }}
				xcss={[buttonStyles, isMouseOver && buttonHoverStyles, isSelected && buttonSelectedStyles]}
				onClick={(evt) => {
					onButtonClick(evt);
				}}
				onMouseEnter={mouseEnterHandler}
				onMouseLeave={mouseLeaveHandler}
			>
				<Stack space="space.100">
					<Flex justifyContent="center">{icon}</Flex>
					<Text size="small" color={isSelected ? 'color.text.selected' : 'color.text'}>
						{props.icon.iconName}
					</Text>
				</Stack>
			</Pressable>
		</span>
	);
});

export default IconCell;
