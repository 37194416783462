/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import CheckIcon from '@atlaskit/icon/glyph/check-circle';
import CrossIcon from '@atlaskit/icon/glyph/cross-circle';
import { Box, Grid, Inline, media, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const imageContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderStartStartRadius: 'border.radius',
	borderStartEndRadius: 'border.radius',
	borderColor: 'color.border',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderBlockEnd: 'none',
	overflow: 'hidden',

	[media.above.sm]: {
		minHeight: '10rem',
	},
});

const imageStyles = css({
	maxWidth: '100%',
});

/**
 * This could be a stack and a box
 */
const contentContainerStyles = xcss({
	borderBlockStartStyle: 'solid',
	borderBlockStartWidth: '4px',
	padding: 'space.200',
	borderRadius: `0px 0px ${token('border.radius', '4px')} ${token('border.radius', '4px')}`,
});

const appearanceMap = {
	do: xcss({
		backgroundColor: 'color.background.success',
		borderColor: 'color.border.success',
	}),
	dont: xcss({
		backgroundColor: 'color.background.danger',
		borderColor: 'color.border.danger',
	}),
};

const headline3Styles = css({
	margin: token('space.0', '0px'),
	color: token('color.text'),
	font: token('font.heading.small'),
	fontFamily: token('font.family.brand.heading'),
	letterSpacing: '1px',
	textTransform: 'uppercase',
});

const gridStyles = xcss({
	gridColumn: 'span 12',
	[media.above.sm]: {
		gridColumn: 'span 6',
	},
});

const fullWidthStyles = xcss({
	gridColumn: 'span 12',
});

export type DoDontProps = {
	type: 'do' | 'dont';
	children: any;
	testId?: string;
	image?: {
		url: string;
		alt: string;
	};
	isGridLayout?: boolean;
};

/**
 * __DoDont__
 *
 * A DoDont visually describes suggested and discouraged patterns.
 *
 */
const DoDont = ({ type, children, testId, image, isGridLayout }: DoDontProps) => {
	return (
		<Grid
			testId={testId}
			templateRows={image && 'minmax(min-content, 160px) 1fr'}
			xcss={isGridLayout ? gridStyles : fullWidthStyles}
		>
			{image && (
				<Box xcss={imageContainerStyles}>
					<img src={image.url} alt={image.alt || ''} css={imageStyles} />
				</Box>
			)}

			<Stack space="space.150" xcss={[contentContainerStyles, appearanceMap[type]]}>
				<Inline alignBlock="center" space="space.100">
					{type === 'do' ? (
						<CheckIcon label="do" primaryColor={token('color.icon.success')} />
					) : (
						<CrossIcon label="don't" primaryColor={token('color.icon.danger')} />
					)}
					<h4 css={headline3Styles}>{type === 'do' ? 'Do' : 'Don’t'}</h4>
				</Inline>
				<Text as="p">{children}</Text>
			</Stack>
		</Grid>
	);
};

export default DoDont;
