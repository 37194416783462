import inlineEditableTextfieldDefault from "../../../../../../packages/design-system/inline-edit/examples/constellation/inline-editable-textfield-default";
import inlineEditableTextfieldCompact from "../../../../../../packages/design-system/inline-edit/examples/constellation/inline-editable-textfield-compact";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHinline_editable_textfield_default from "!!raw-loader!../../../../../../packages/design-system/inline-edit/examples/constellation/inline-editable-textfield-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHinline_editable_textfield_compact from "!!raw-loader!../../../../../../packages/design-system/inline-edit/examples/constellation/inline-editable-textfield-compact";
import * as React from 'react';
export default {
  inlineEditableTextfieldDefault,
  inlineEditableTextfieldCompact,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHinline_editable_textfield_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHinline_editable_textfield_compact,
  React
};