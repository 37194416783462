import IconObject16 from "../../../../../../packages/design-system/icon-object/examples/constellation/icon-object-16.tsx";
import IconObject24 from "../../../../../../packages/design-system/icon-object/examples/constellation/icon-object-24.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_object_16_tsx from "!!raw-loader!../../../../../../packages/design-system/icon-object/examples/constellation/icon-object-16.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_object_24_tsx from "!!raw-loader!../../../../../../packages/design-system/icon-object/examples/constellation/icon-object-24.tsx";
import * as React from 'react';
export default {
  IconObject16,
  IconObject24,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_object_16_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_object_24_tsx,
  React
};