import SpotlightSingle from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-single";
import SpotlightTour from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-tour";
import SpotlightActionsAppearance from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-actions-appearance";
import SpotlightActionsBefore from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-actions-before";
import SpotlightHeadingAfterElement from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-heading-after-element";
import SpotlightImage from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-image";
import SpotlightPulse from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-pulse";
import SpotlightPulseFalse from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-pulse-false";
import SpotlightDialogPlacement from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-dialog-placement";
import SpotlightDialogWidth from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-dialog-width";
import SpotlightTargetRadius from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-target-radius";
import SpotlightTargetBackground from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-target-background";
import SpotlightTargetReplacement from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-target-replacement";
import SpotlightBlanketIsTinted from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-blanket-is-tinted";
import SpotlightConditionalTargets from "../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-conditional-targets";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_single from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-single";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_tour from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-tour";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_actions_appearance from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-actions-appearance";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_actions_before from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-actions-before";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_heading_after_element from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-heading-after-element";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_image from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-image";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_pulse from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-pulse";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_pulse_false from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-pulse-false";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_dialog_placement from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-dialog-placement";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_dialog_width from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-dialog-width";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_target_radius from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-target-radius";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_target_background from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-target-background";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_target_replacement from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-target-replacement";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_blanket_is_tinted from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-blanket-is-tinted";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_conditional_targets from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/spotlight-conditional-targets";
import * as React from 'react';
export default {
  SpotlightSingle,
  SpotlightTour,
  SpotlightActionsAppearance,
  SpotlightActionsBefore,
  SpotlightHeadingAfterElement,
  SpotlightImage,
  SpotlightPulse,
  SpotlightPulseFalse,
  SpotlightDialogPlacement,
  SpotlightDialogWidth,
  SpotlightTargetRadius,
  SpotlightTargetBackground,
  SpotlightTargetReplacement,
  SpotlightBlanketIsTinted,
  SpotlightConditionalTargets,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_single,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_tour,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_actions_appearance,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_actions_before,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_heading_after_element,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_image,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_pulse,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_pulse_false,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_dialog_placement,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_dialog_width,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_target_radius,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_target_background,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_target_replacement,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_blanket_is_tinted,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHspotlight_conditional_targets,
  React
};