import AvatarSkeletonCircle from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-circle";
import AvatarSkeletonSquare from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-square";
import AvatarSkeletonSize from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-size";
import AvatarSkeletonColorDefault from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-color-default";
import AvatarSkeletonColor from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-color";
import AvatarSkeletonWeightNormal from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-weight-normal";
import AvatarSkeletonWeightStrong from "../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-weight-strong";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_circle from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-circle";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_square from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-square";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_size from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-size";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_color_default from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-color-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_color from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-color";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_weight_normal from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-weight-normal";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_weight_strong from "!!raw-loader!../../../../../../packages/design-system/avatar/examples/constellation/avatar-skeleton-weight-strong";
import * as React from 'react';
export default {
  AvatarSkeletonCircle,
  AvatarSkeletonSquare,
  AvatarSkeletonSize,
  AvatarSkeletonColorDefault,
  AvatarSkeletonColor,
  AvatarSkeletonWeightNormal,
  AvatarSkeletonWeightStrong,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_circle,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_square,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_size,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_color_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_color,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_weight_normal,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHavatar_skeleton_weight_strong,
  React
};