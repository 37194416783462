import React, { Fragment } from 'react';

import Link from '@atlaskit/link';
import { token } from '@atlaskit/tokens';

import AccentIcon from './components/accent-icon';
import type { ColorTable, GroupedColorTable } from './types';

export const COLOR_ROLES: ColorTable[] = [
	{
		name: 'neutral',
		description: (
			<Fragment>
				Use for default text and secondary UI elements, such as secondary buttons or navigation
				elements.
			</Fragment>
		),
		token: token('color.icon'),
	},
	{
		name: 'brand',
		description: (
			<Fragment>Use for primary actions or elements that communicate the Atlassian brand.</Fragment>
		),
		token: token('color.background.brand.bold'),
	},
	{
		name: 'information',
		description: (
			<Fragment>
				Use for informative UI, such as an information icon, or UI that communicates something is in
				progress.
			</Fragment>
		),
		token: token('color.background.information.bold'),
	},
	{
		name: 'success',
		description: (
			<Fragment>Use to communicate a favorable outcome, such as a success message.</Fragment>
		),
		token: token('color.background.success.bold'),
	},
	{
		name: 'warning',
		description: (
			<Fragment>
				Use for UI that communicates caution to prevent a mistake or error from occurring.
			</Fragment>
		),
		token: token('color.background.warning.bold'),
	},
	{
		name: 'danger',
		description: <Fragment>Use for UI that communicates danger or serious error states.</Fragment>,
		token: token('color.background.danger.bold'),
	},
	{
		name: 'discovery',
		description: (
			<Fragment>
				Use for UI that communicates something new, such as onboarding or new feature information.
			</Fragment>
		),
		token: token('color.background.discovery.bold'),
	},
	{
		name: 'accent',
		description: (
			<Fragment>
				Use for colors that don't have any specific meaning tied to them. You should be able to
				exchange one accent color for another, and the experience would remain unchanged. <br />
				<br />
				Accent colors: gray, red, green, blue, yellow, orange, teal, purple, magenta, and lime.
			</Fragment>
		),
		icon: <AccentIcon />,
	},
	{
		name: 'inverse',
		description: (
			<Fragment>
				Use for UI elements that sit on bold{' '}
				<Link href="#use-inverse-tokens-on-bold-backgrounds">emphasis</Link> backgrounds.
			</Fragment>
		),
		token: token('color.icon.inverse'),
		background: token('color.background.neutral.bold'),
		inverse: true,
	},
	{
		name: 'input',
		description: (
			<Fragment>
				Use for form fields. (Note that design system form fields will already have tokens applied.)
			</Fragment>
		),
		token: token('color.border.input'),

		input: true,
	},
];

export const BRAND_AND_NEUTRAL_CHART_TOKENS: ColorTable[] = [
	{
		name: 'color.chart.brand',
		description: (
			<Fragment>
				Our primary color for data visualization. Use when only one color is required.
			</Fragment>
		),
		token: token('color.chart.brand'),
	},
	{
		name: 'color.chart.neutral',
		description: (
			<Fragment>
				A secondary color for data visualization. Use for the less important data when you want
				other data to stand out.
			</Fragment>
		),
		token: token('color.chart.neutral'),
	},
];

export const CATEGORICAL_CHART_TOKENS: ColorTable[] = [
	{
		name: 'color.chart.categorical.1',
		description: <Fragment>For data visualization only. Follow the numbered sequence.</Fragment>,
		token: token('color.chart.categorical.1'),
	},
	{
		name: 'color.chart.categorical.2',
		description: <Fragment>For data visualization only. Follow the numbered sequence.</Fragment>,
		token: token('color.chart.categorical.2'),
	},
	{
		name: 'color.chart.categorical.3',
		description: <Fragment>For data visualization only. Follow the numbered sequence.</Fragment>,
		token: token('color.chart.categorical.3'),
	},
	{
		name: 'color.chart.categorical.4',
		description: <Fragment>For data visualization only. Follow the numbered sequence.</Fragment>,
		token: token('color.chart.categorical.4'),
	},
	{
		name: 'color.chart.categorical.5',
		description: <Fragment>For data visualization only. Follow the numbered sequence.</Fragment>,
		token: token('color.chart.categorical.5'),
	},
	{
		name: 'color.chart.categorical.6',
		description: <Fragment>For data visualization only. Follow the numbered sequence.</Fragment>,
		token: token('color.chart.categorical.6'),
	},
	{
		name: 'color.chart.categorical.7',
		description: <Fragment>For data visualization only. Follow the numbered sequence.</Fragment>,
		token: token('color.chart.categorical.7'),
	},
	{
		name: 'color.chart.categorical.8',
		description: <Fragment>For data visualization only. Follow the numbered sequence.</Fragment>,
		token: token('color.chart.categorical.8'),
	},
];

export const SEMANTIC_CHART_TOKENS: GroupedColorTable = {
	success: [
		{
			name: 'color.chart.success',
			description: (
				<Fragment>
					For data visualization communicating positive information, such as 'on track' or done.
				</Fragment>
			),
			token: token('color.chart.success'),
		},
		{
			name: 'color.chart.success.bold',
			description: (
				<Fragment>
					For data visualization communicating positive information, such as 'on track' or done.
				</Fragment>
			),
			token: token('color.chart.success.bold'),
		},
	],
	warning: [
		{
			name: 'color.chart.warning',
			description: (
				<Fragment>
					For data visualization communicating caution, such as 'at risk' or 'medium priority'.
				</Fragment>
			),
			token: token('color.chart.warning'),
		},
		{
			name: 'color.chart.warning.bold',
			description: (
				<Fragment>
					For data visualization communicating caution, such as 'at risk' or 'medium priority'.{' '}
				</Fragment>
			),
			token: token('color.chart.warning.bold'),
		},
	],
	danger: [
		{
			name: 'color.chart.danger',
			description: (
				<Fragment>
					For data visualization communicating negative or critical information, such as 'off track'
					or 'high priority'.
				</Fragment>
			),
			token: token('color.chart.danger'),
		},
		{
			name: 'color.chart.danger.bold',
			description: (
				<Fragment>
					For data visualization communicating negative or critical information, such as 'off track'
					or 'high priority'.
				</Fragment>
			),
			token: token('color.chart.danger.bold'),
		},
	],
	information: [
		{
			name: 'color.chart.information',
			description: (
				<Fragment>
					For data visualization communicating low priority or in-progress statuses.
				</Fragment>
			),
			token: token('color.chart.information'),
		},
		{
			name: 'color.chart.information.bold',
			description: (
				<Fragment>
					For data visualization communicating low priority or in-progress statuses.
				</Fragment>
			),
			token: token('color.chart.information.bold'),
		},
	],
	discovery: [
		{
			name: 'color.chart.discovery',
			description: <Fragment>For data visualization communicating 'new' statuses.</Fragment>,
			token: token('color.chart.discovery'),
		},
		{
			name: 'color.chart.discovery.bold',
			description: <Fragment>For data visualization communicating 'new' statuses.</Fragment>,
			token: token('color.chart.discovery.bold'),
		},
	],
	neutral: [
		{
			name: 'color.chart.neutral',
			description: <Fragment>For data visualization communicating 'to-do' statuses.</Fragment>,
			token: token('color.chart.neutral'),
		},
	],
};
