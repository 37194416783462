import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import Fuse from 'fuse.js';
import debounce from 'lodash/debounce';

import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button/new';
import { Checkbox } from '@atlaskit/checkbox';
import DropdownMenu, {
	DropdownItemCheckbox,
	DropdownItemCheckboxGroup,
	DropdownItemRadio,
	DropdownItemRadioGroup,
} from '@atlaskit/dropdown-menu';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { Box, Inline, Pressable, Stack, Text, xcss } from '@atlaskit/primitives';
import TextField from '@atlaskit/textfield';
import ToolTip from '@atlaskit/tooltip';

import SectionHeadingLink from '../../section-link';
import TokenWizardModal from '../token-wizard';

import TokenGroups, { type TokenGroupsProps } from './components/token-groups';
import {
	type TokenNameSyntax,
	TokenNameSyntaxContext,
} from './components/token-name-syntax-context';
import TokenTable from './components/token-table';
import groupedTokens, { type TokenGroup } from './grouped-tokens';
import mergedTokens from './merged-tokens';
import type { TransformedTokenMerged } from './types';
import { filterGroups, filterTokens, getNumberOfTokensInGroups } from './utils';

const ALL_DESIGN_TOKENS_LIST_HEADING = {
	depth: 1,
	id: 'all-design-tokens-list',
	value: 'All tokens',
} as const;

const clearButtonStyles = xcss({
	display: 'flex',
	color: 'color.text.subtlest',
	padding: 'space.0',
	alignItems: 'center',
	appearance: 'none',
	background: 'none',
	border: 'none',
	marginInlineEnd: 'space.100',
	':hover': {
		color: 'color.text',
	},
	':active': {
		color: 'color.text.subtle',
	},
});

const searchIconStyles = xcss({
	marginInlineStart: 'space.100',
	display: 'flex',
	alignItems: 'center',
});

interface TokenExplorerProps {
	scrollOffset?: TokenGroupsProps['scrollOffset'];
	testId?: string;
}

// Filters state
const filtersInitialState = {
	state: {
		active: true,
		deprecated: false,
		deleted: false,
	},
};

type FilterState = typeof filtersInitialState;

type FilterAction = {
	type: 'state';
	payload: {
		[key in 'active' | 'deprecated' | 'deleted']?: boolean;
	};
};

const filterReducer = (state: FilterState, action: FilterAction) => ({
	...state,
	[action.type]: {
		...state[action.type],
		...action.payload,
	},
});

const filterItemStyles = xcss({
	flex: '0 0 auto',
});

const FilterItem = ({ children }: { children: React.ReactNode }) => (
	<Box xcss={filterItemStyles}>{children}</Box>
);

const fuseOptions: Fuse.IFuseOptions<TransformedTokenMerged> = {
	keys: [
		{
			name: 'name',
			weight: 1,
		},
		{
			name: 'cleanName',
			weight: 1,
		},
		{
			name: 'original.value',
			weight: 1,
		},
		{
			name: 'darkToken.original.value',
			weight: 1,
		},
		{
			name: 'path',
			weight: 1,
		},
		{
			name: 'attributes.description',
			weight: 2,
		},
	],
	useExtendedSearch: true,
	threshold: 0.05,
	ignoreLocation: true,
};

const TokenExplorer = ({ scrollOffset, testId }: TokenExplorerProps) => {
	/**
	 * Filters
	 */
	const [filterState, dispatchFilter] = useReducer(filterReducer, filtersInitialState);

	const handleFilterChange = (action: FilterAction) => {
		dispatchFilter(action);

		const newFilterState = {
			...filterState.state,
			...action.payload,
		};

		setFuseIndex(newFilterState);
		setFilteredTokenGroups(getFilteredTokenGroups(newFilterState));
	};

	/**
	 * Search
	 */
	// Re-indexes search
	const setFuseIndex = (state: FilterState['state']) => {
		const index = getFilteredTokenIndex(state);
		fuseIndex.current.setCollection(index);

		// Update search if active
		if (searchQuery !== '') {
			handleSearch(searchQuery);
		}
	};

	const getFilteredTokenIndex = (state: FilterState['state']): TransformedTokenMerged[] => {
		return filterTokens(mergedTokens, {
			showStates: Object.entries(state)
				.filter(([_, isSelected]) => isSelected)
				.map(([tokenState]) => tokenState),
		});
	};

	const fuseIndex = useRef<Fuse<TransformedTokenMerged>>(
		new Fuse(getFilteredTokenIndex(filterState.state), fuseOptions),
	);
	const searchField = useRef<HTMLInputElement>();
	const [searchQuery, setSearchQuery] = useState('');
	const [searchedTokens, setSearchedTokens] = useState<
		Fuse.FuseResult<TransformedTokenMerged>[] | undefined
	>();

	const search = (query: string) =>
		setSearchedTokens(fuseIndex.current.search<TransformedTokenMerged>(query));
	const debouncedSearch = useMemo(() => debounce(search, 300), []);

	const handleSearch = useCallback(
		(query: string, opts: { isDebounced?: boolean } = { isDebounced: false }) => {
			setSearchQuery(query);

			// Clear searched tokens to enter loading state
			setSearchedTokens(undefined);

			if (query !== '') {
				opts.isDebounced ? debouncedSearch(query) : search(query);
			}
		},
		[debouncedSearch],
	);

	/**
	 * Token groups
	 */

	const getFilteredTokenGroups = (state: FilterState['state']): TokenGroup[] => {
		return filterGroups(groupedTokens, {
			showStates: Object.entries(state)
				.filter(([_, isSelected]) => isSelected)
				.map(([tokenState]) => tokenState),
		});
	};

	const [filteredTokenGroups, setFilteredTokenGroups] = useState<TokenGroup[]>(
		getFilteredTokenGroups(filterState.state),
	);

	/**
	 * Exact search
	 */
	const onExactSearchChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const filteredTokens = getFilteredTokenIndex(filterState.state);

			if (e.target.checked) {
				fuseIndex.current = new Fuse(filteredTokens, {
					...fuseOptions,
					// Don't exact-match paths e.g. 'red', 'background'
					keys: fuseOptions.keys?.filter(
						(key) => typeof key === 'object' && !Array.isArray(key) && key.name !== 'path',
					),
					threshold: -1,
				});
				handleSearch(searchQuery);
			} else {
				fuseIndex.current = new Fuse(filteredTokens, fuseOptions);
				handleSearch(searchQuery);
			}
		},
		[filterState, handleSearch, searchQuery],
	);

	// Prevents re-renders in TokenList due to new array being created from map
	const searchedTokensMemo = useMemo(
		() => searchedTokens?.map((result) => result.item),
		[searchedTokens],
	);

	const numberOfTokens = useMemo(
		() =>
			searchQuery === ''
				? getNumberOfTokensInGroups(filteredTokenGroups)
				: searchedTokensMemo?.length,
		[searchQuery, searchedTokensMemo, filteredTokenGroups],
	);

	const [syntax, setSyntax] = useState<TokenNameSyntax>('default');

	// On mount, try to restore a saved syntax preference from localStorage.
	useEffect(() => {
		if (!Object.prototype.hasOwnProperty.call(window, 'localStorage')) {
			return;
		}

		try {
			const savedSyntax = localStorage.getItem('token-name-syntax') as TokenNameSyntax | null;
			if (savedSyntax && ['default', 'css-var'].includes(savedSyntax)) {
				setSyntax(savedSyntax as TokenNameSyntax);
			}
			// Ignore; restoring syntax from storage is a nice-to-have.
		} catch (e) {}
	}, []);

	const saveSyntax = useCallback((newSyntax: TokenNameSyntax = 'default') => {
		setSyntax(newSyntax);

		if (Object.prototype.hasOwnProperty.call(window, 'localStorage')) {
			try {
				localStorage.setItem('token-name-syntax', newSyntax);
			} catch (e) {}
		}
	}, []);

	return (
		<TokenNameSyntaxContext.Provider value={{ syntax }}>
			<Box testId={testId}>
				<Stack space="space.400">
					<Stack space="space.300">
						<Inline alignBlock="stretch" spread="space-between">
							<SectionHeadingLink id={ALL_DESIGN_TOKENS_LIST_HEADING.id} depth={2}>
								{ALL_DESIGN_TOKENS_LIST_HEADING.value}
							</SectionHeadingLink>
							<TokenWizardModal />
						</Inline>

						<Stack space="space.200">
							<TextField
								ref={searchField}
								value={searchQuery}
								name="token-search"
								aria-label="tokens search"
								placeholder="Search for tokens"
								autoComplete="off"
								testId={testId && `${testId}-search`}
								isCompact
								elemBeforeInput={
									<Box xcss={searchIconStyles}>
										<SearchIcon size="small" label="" />
									</Box>
								}
								elemAfterInput={
									searchQuery && (
										<ToolTip content="Clear search" position="top">
											<Pressable
												xcss={clearButtonStyles}
												onClick={() => {
													if (searchField?.current?.value) {
														searchField.current.value = '';
													}
													handleSearch('');
												}}
											>
												<CrossIcon size="small" label="Clear search" />
											</Pressable>
										</ToolTip>
									)
								}
								onChange={(e) =>
									handleSearch((e.target as HTMLInputElement).value, {
										isDebounced: true,
									})
								}
							/>
							<Inline space="space.200" alignBlock="center" shouldWrap={true}>
								<FilterItem>
									<DropdownMenu<HTMLButtonElement>
										testId={testId && `${testId}-filters`}
										trigger={({ triggerRef, ...props }) => (
											<Button {...props} ref={triggerRef} iconAfter={FilterIcon}>
												Filters{' '}
												<Badge appearance={props.isSelected ? 'primary' : undefined}>
													{Object.values(filterState.state).filter((v) => v).length}
												</Badge>
											</Button>
										)}
									>
										<DropdownItemCheckboxGroup id="state" title="State">
											<DropdownItemCheckbox
												testId={testId && `${testId}-filters-active`}
												id="active"
												isSelected={filterState.state.active}
												onClick={() =>
													handleFilterChange({
														type: 'state',
														payload: {
															active: !filterState.state.active,
														},
													})
												}
											>
												Active
											</DropdownItemCheckbox>
											<DropdownItemCheckbox
												testId={testId && `${testId}-filters-deprecated`}
												id="deprecated"
												isSelected={filterState.state.deprecated}
												onClick={() =>
													handleFilterChange({
														type: 'state',
														payload: {
															deprecated: !filterState.state.deprecated,
														},
													})
												}
											>
												Deprecated
											</DropdownItemCheckbox>
											<DropdownItemCheckbox
												testId={testId && `${testId}-filters-deleted`}
												id="deleted"
												isSelected={filterState.state.deleted}
												onClick={() =>
													handleFilterChange({
														type: 'state',
														payload: {
															deleted: !filterState.state.deleted,
														},
													})
												}
											>
												Deleted
											</DropdownItemCheckbox>
										</DropdownItemCheckboxGroup>
									</DropdownMenu>
								</FilterItem>

								<FilterItem>
									<DropdownMenu
										testId={testId && `${testId}-syntax`}
										trigger={syntax === 'default' ? 'JavaScript syntax' : 'CSS syntax'}
									>
										<DropdownItemRadioGroup id="syntax">
											<DropdownItemRadio
												id="default"
												isSelected={syntax === 'default'}
												onClick={() => saveSyntax('default')}
											>
												JavaScript syntax
											</DropdownItemRadio>
											<DropdownItemRadio
												id="css-var"
												isSelected={syntax === 'css-var'}
												onClick={() => saveSyntax('css-var')}
											>
												CSS syntax
											</DropdownItemRadio>
										</DropdownItemRadioGroup>
									</DropdownMenu>
								</FilterItem>

								<FilterItem>
									<Checkbox
										onChange={onExactSearchChange}
										label="Exact search"
										name="exact-search"
										testId={testId && `${testId}-exact-search`}
									/>
								</FilterItem>
							</Inline>

							{searchQuery !== '' && (
								<Text>
									{(searchQuery !== '' && searchedTokensMemo === undefined) ||
									numberOfTokens === undefined
										? 'Loading results...'
										: `${numberOfTokens} result${numberOfTokens === 1 ? '' : 's'} below`}
								</Text>
							)}
						</Stack>
					</Stack>

					{searchQuery === '' ? (
						<TokenGroups testId={testId} scrollOffset={scrollOffset} groups={filteredTokenGroups} />
					) : (
						<TokenTable
							testId={testId}
							isLoading={searchedTokensMemo === undefined}
							list={searchedTokensMemo}
							scrollOffset={scrollOffset}
							isInSearchResult
						/>
					)}
				</Stack>
			</Box>
		</TokenNameSyntaxContext.Provider>
	);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default TokenExplorer;
