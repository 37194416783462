import BadgeDefault from "../../../../../../packages/design-system/badge/examples/constellation/badge-default";
import BadgePrimary from "../../../../../../packages/design-system/badge/examples/constellation/badge-primary";
import BadgePrimaryInverted from "../../../../../../packages/design-system/badge/examples/constellation/badge-primary-inverted";
import BadgeImportant from "../../../../../../packages/design-system/badge/examples/constellation/badge-important";
import BadgeAdded from "../../../../../../packages/design-system/badge/examples/constellation/badge-added";
import BadgeRemoved from "../../../../../../packages/design-system/badge/examples/constellation/badge-removed";
import BadgeMaxValue from "../../../../../../packages/design-system/badge/examples/constellation/badge-max-value";
import BadgeMaxValueDisabled from "../../../../../../packages/design-system/badge/examples/constellation/badge-max-value-disabled";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_default from "!!raw-loader!../../../../../../packages/design-system/badge/examples/constellation/badge-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_primary from "!!raw-loader!../../../../../../packages/design-system/badge/examples/constellation/badge-primary";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_primary_inverted from "!!raw-loader!../../../../../../packages/design-system/badge/examples/constellation/badge-primary-inverted";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_important from "!!raw-loader!../../../../../../packages/design-system/badge/examples/constellation/badge-important";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_added from "!!raw-loader!../../../../../../packages/design-system/badge/examples/constellation/badge-added";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_removed from "!!raw-loader!../../../../../../packages/design-system/badge/examples/constellation/badge-removed";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_max_value from "!!raw-loader!../../../../../../packages/design-system/badge/examples/constellation/badge-max-value";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_max_value_disabled from "!!raw-loader!../../../../../../packages/design-system/badge/examples/constellation/badge-max-value-disabled";
import * as React from 'react';
export default {
  BadgeDefault,
  BadgePrimary,
  BadgePrimaryInverted,
  BadgeImportant,
  BadgeAdded,
  BadgeRemoved,
  BadgeMaxValue,
  BadgeMaxValueDisabled,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_primary,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_primary_inverted,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_important,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_added,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_removed,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_max_value,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbadge_max_value_disabled,
  React
};