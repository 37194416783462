import React from 'react';

import { Code } from '@atlaskit/code';
import { Stack, Text } from '@atlaskit/primitives';
import { Table, TBody, TD, TH, THead, TR } from '@atlaskit/table/primitives';

import HorizontalScrollContainer from '../../horizontal-scroll-container';
import CollapsingTopMargin from '../../utils/collapsing-top-margin';

type TextStyle = {
	textStyle: string;
	token: string;
	properties?: string;
	suitableFor?: string[];
	rowSpan?: number;
};

const headingData: TextStyle[] = [
	{
		textStyle: 'Heading / XXL (H900)',
		token: 'font.heading.xxlarge',
		suitableFor: ['Brand and marketing content.'],
		rowSpan: 2,
	},
	{
		textStyle: 'Heading / XL (H800) ',
		token: 'font.heading.xlarge',
	},
	{
		textStyle: 'Heading / L (H700)',
		token: 'font.heading.large',
		suitableFor: ['Product page titles such as forms.'],
	},
	{
		textStyle: 'Heading / M (H600)',
		token: 'font.heading.medium',
		suitableFor: ['Headers in large components, such as modal dialogs.'],
	},
	{
		textStyle: 'Heading / S (H500)',
		token: 'font.heading.small',
		suitableFor: ['Headers in small components where space is limited, such as flags.'],
		rowSpan: 2,
	},
	{
		textStyle: 'Heading / XS (H400)',
		token: 'font.heading.xsmall',
	},
	{
		textStyle: 'Heading / XXS (H200)',
		token: 'font.heading.xxsmall',
		suitableFor: ['Headers in fine print or tight spaces. Use sparingly.'],
	},
];

const bodyData: TextStyle[] = [
	{
		textStyle: 'Body / L / Regular ^',
		token: 'font.body.large',
		properties: 'font.weight.regular',
		suitableFor: [
			'Long-form content such as blogs.',
			'The default size for reading text in long paragraphs.',
		],
		rowSpan: 3,
	},
	{
		textStyle: 'Body / L / Medium ^',
		token: 'font.body.large',
		properties: 'font.weight.medium',
	},
	{
		textStyle: 'Body / L / Bold ^',
		token: 'font.body.large',
		properties: 'font.weight.bold',
	},
	{
		textStyle: 'Body / M (Default) / Regular',
		token: 'font.body',
		properties: 'font.weight.regular',
		suitableFor: [
			'Short text, such as descriptions in flags, or labels in buttons.',
			'The default size for text in components, and short content.',
		],
		rowSpan: 3,
	},
	{
		textStyle: 'Body / M (Default) / Medium ^',
		token: 'font.body',
		properties: 'font.weight.medium',
	},
	{
		textStyle: 'Body / M (Default) / Bold ^',
		token: 'font.body',
		properties: 'font.weight.bold',
	},
	{
		textStyle: 'Body / S / Regular',
		token: 'font.body.small',
		properties: 'font.weight.regular',
		suitableFor: [
			'Secondary level content such as fine print or semantic messaging.',
			'Use sparingly.',
		],
		rowSpan: 3,
	},
	{
		textStyle: 'Body / S / Medium ^',
		token: 'font.body.small',
		properties: 'font.weight.medium',
	},
	{
		textStyle: 'Body / S / Bold ^',
		token: 'font.body.small',
		properties: 'font.weight.bold',
	},
];

const codeData: TextStyle[] = [
	{
		textStyle: 'Code / Regular ',
		token: 'font.code',
		suitableFor: ['For use in code block only.'],
	},
];

/**
 * __Typography text style table__
 */
export const TypographyTextStyleTable = () => {
	return (
		<CollapsingTopMargin>
			<HorizontalScrollContainer>
				<Table>
					<THead>
						<TR isBodyRow={false}>
							<TH width="195px">Figma text style</TH>
							<TH width="180px">Token</TH>
							<TH width="160px">Properties</TH>
							<TH width="205px">Suitable for</TH>
						</TR>
					</THead>
					<TBody>
						<TableSubHeaderRow>Heading</TableSubHeaderRow>
						{headingData.map((token) => (
							<TableRow
								key={token.textStyle}
								textStyle={token.textStyle}
								token={token.token}
								properties={token.properties}
								rowSpan={token.rowSpan}
								suitableFor={token.suitableFor}
							/>
						))}
						<TableSubHeaderRow>Body</TableSubHeaderRow>
						{bodyData.map((token) => (
							<TableRow
								key={token.textStyle}
								textStyle={token.textStyle}
								token={token.token}
								properties={token.properties}
								rowSpan={token.rowSpan}
								suitableFor={token.suitableFor}
							/>
						))}
						<TableSubHeaderRow>Code</TableSubHeaderRow>
						{codeData.map((token) => (
							<TableRow
								key={token.textStyle}
								textStyle={token.textStyle}
								token={token.token}
								properties={token.properties}
								rowSpan={token.rowSpan}
								suitableFor={token.suitableFor}
							/>
						))}
					</TBody>
				</Table>
			</HorizontalScrollContainer>
		</CollapsingTopMargin>
	);
};

const TableSubHeaderRow = ({ children }: any) => {
	return (
		<TR>
			<TH backgroundColor="elevation.surface.sunken" colSpan={4}>
				<Text weight="medium" color="color.text.subtlest">
					{children}
				</Text>
			</TH>
		</TR>
	);
};

const TableRow = ({ textStyle, token, properties, rowSpan, suitableFor }: TextStyle) => {
	return (
		<TR>
			<TD>
				<Text>{textStyle}</Text>
			</TD>
			<TD>
				<Code>{token}</Code>
			</TD>
			<TD>
				{properties && (
					<Text>
						<Code>{properties}</Code>
					</Text>
				)}
			</TD>
			{suitableFor && (
				<TD rowSpan={rowSpan}>
					<Stack space="space.100">
						{suitableFor.map((sentence) => (
							<Text>{sentence}</Text>
						))}
					</Stack>
				</TD>
			)}
		</TR>
	);
};
