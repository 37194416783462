import BoardExample from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/board";
import ListExample from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/list";
import TreeExample from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/tree";
import ManualFocusRestorationExample from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/manual-focus-restoration";
import { ActionMenuVariants } from "../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/guidelines/action-menu-variants";
import SectionMessage from '@atlaskit/section-message';
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHboard from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/board";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHlist from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/list";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHtree from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/tree";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHmanual_focus_restoration from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/manual-focus-restoration";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHguidelinesSLASHaction_menu_variants from "!!raw-loader!../../../../../../packages/pragmatic-drag-and-drop/documentation/examples/guidelines/action-menu-variants";
import RAW_UNSAFE_atlaskitSLASHsection_message from "!!raw-loader!@atlaskit/section-message";
import * as React from 'react';
export default {
  BoardExample,
  ListExample,
  TreeExample,
  ManualFocusRestorationExample,
  ActionMenuVariants,
  SectionMessage,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHboard,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHlist,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHtree,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHmanual_focus_restoration,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHguidelinesSLASHaction_menu_variants,
  RAW_UNSAFE_atlaskitSLASHsection_message,
  React
};